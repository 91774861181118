import { Component, OnInit } from '@angular/core';
import { SearchService } from 'src/app/search.service';
import { UserService } from '../services/user.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  standalone: true,
  imports: [ IonicModule, ReactiveFormsModule, TranslateModule, MatFormFieldModule, MatInputModule ]
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm = new UntypedFormGroup({

    Username : new UntypedFormControl(''),
    
  });

  constructor(private location: Location, private search: SearchService,private userService: UserService, private translate: TranslateService) { }

  ngOnInit() {
    
  }

  forgotpassword(data){
    this.userService.forgotpassword(data).subscribe(res=>{
      console.log(res);
    })
  }

  backClicked() {
    this.location.back();
  }
}
