import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SearchService } from 'src/app/search.service';
import { environment } from 'src/environments/environment';
import { IProduct } from 'src/app/interfaces/IProduct';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-setings',
  templateUrl: './setings.component.html',
  styleUrls: ['./setings.component.css'],
  standalone: true,
  imports: [ IonicModule, TranslateModule, RouterModule ]
})
export class SetingsComponent implements OnInit {
  constructor(
    private lokacioni: Location) {}

  backClicked() {
    this.lokacioni.back();
  }

  data: IProduct;
  imgpath = environment.IMG_PATH;

  ngOnInit() {
    this.data = JSON.parse(localStorage.getItem('fav'));
  }
}
