<app-header></app-header>


  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p class="spinner-text">Loading...</p>
  </ngx-spinner>

  <div class="slider">
    <ion-range aria-label="Custom range" [min]="options.floor - 1" [max]="options.ceil" [step]="options.step" [pin]="true"
            [(ngModel)]="value" (ionChange)="detectchange($event.detail.value)"><ion-text slot="start">0</ion-text>
            <ion-text slot="end">200</ion-text>
        </ion-range>
    <div class="text">
      <p class="range-line-text">|</p>
      <p class="range-text">{{'RANGE_IN_KM' | translate}}</p>
    </div>


  <div class="ifilter" fxLayout="row" fxLayoutWrap>
    <div class="sliders-icon" (click)="onFilter()">
      <app-filter *ngIf="filter"></app-filter>
    </div>
      <button mat-stroked-button class="active"
        *ngFor="let cat of category; let i = index">
        <span class="category-name">{{ cat| translateName }}</span>
      </button>
  </div>
  <cdk-virtual-scroll-viewport [itemSize]="1" class="virtual-scroll-viewport">
    <div class="row">
      <div class="col-6 p-8" *ngFor="let product of allProducts">
        <mat-card [routerLink]="['/productdetail', product.productId, selectedCategoryId, 0]" class="product-card">
          <div fxLayoutAlign="center center" class="product-image-wrapper">
            <img mat-card-image class="product-image" src="{{ imgpath + product.image }}" />
          </div>
          <mat-card-title class="mat-card-title">{{ product | translateName }}</mat-card-title>
          <mat-card-content>
            <div class="card-content-wrapper">
                <p class="card-content-category">{{ 'CATEGORY' | translate }}</p>
                <p class="card-content-price">
                    {{product.sellingPriceWithVat % 1 != 0 ? product.sellingPriceWithVat.toFixed(1) : product.sellingPriceWithVat }}{{ 'CURRENCY' | translate }}
                </p>
            </div>
            <div class="d-flex justify-content-between px-2 pt-3">
                <span class="material-icons orange-color" (click)="addToCart($event, product, i)">add_shopping_cart</span>
                <!-- <span class="material-icons orange-color" (click)="buyProduct($event, product)">payment</span> -->
            </div>
        </mat-card-content>
        </mat-card>
      </div>
    </div>

  </cdk-virtual-scroll-viewport>
