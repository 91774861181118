import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/data.service';
import { IonicModule, AlertController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css'],
  standalone: true,
  imports: [ IonicModule, CommonModule, TranslateModule, RouterModule ]
})
export class ShoppingCartComponent {
  selectedLanguage: string = 'al';
  deleteConfirmationText = "";
  cartList = [];
  manage: boolean = false;
  checkedCards = [];
  imgpath = environment.IMG_PATH;
  deliveryFee = 120.00;
  productDetailIds: any[] = [];


  constructor(private location: Location, private translate:TranslateService, private dataService:DataService, private alert: AlertController) { }

  ionViewDidEnter() {
    this.selectedLanguage = localStorage.getItem('selectedLanguage');
    const shoppingCart = JSON.parse(localStorage.getItem('cart'));
    this.cartList = shoppingCart || [];
    // this.fetchShippingCost();

    this.productDetailIds = this.cartList.map(product => product.productDetails[0].productDetailId);
  }

  fetchShippingCost() {
    const companyId = 1;
    const productDetailIds = this.cartList.map(cart => cart.productId);
    const orderTotal = parseFloat(this.getSubtotal());
    this.dataService.getShippingForProduct(companyId, productDetailIds, orderTotal)
      .subscribe(response => {
        this.deliveryFee = response.shippingPrice || 0; 
      }, error => {
        console.error('Error fetching shipping cost:', error);
      });
  }
  

  getSubtotal() {
    return this.cartList.reduce((sum, prod) => sum + (prod.productDetails[0].productDetailSellingPriceWithVat * prod.quantity), 0).toFixed(2);
  }

  getGrandTotal() {
    const subtotal = parseFloat(this.getSubtotal());
    return (subtotal + this.deliveryFee).toFixed(2);
  }

  addQuantity(product) {
    this.cartList.find(prod => {
      if (product.category) {
        if (prod.productId == product.productId) {
          prod.quantity = prod.quantity + 1;
        }
      } else if (prod.id == product.id) {
        prod.quantity = prod.quantity + 1;
      }
    })
    localStorage.setItem('cart', JSON.stringify(this.cartList));
  }


  removeQuantity(product) {
    this.cartList.find(prod => {
      if (product.category) {
        if (prod.productId == product.productId && prod.quantity >= 2) {
          prod.quantity = prod.quantity - 1;
        } else if (prod.productId == product.productId && prod.quantity <= 1) {
          this.removeProduct(product);
        }
      } else {
        if (prod.id == product.id && prod.quantity >= 2) {
          prod.quantity = prod.quantity - 1;
        } else if (prod.id == product.id && prod.quantity <= 1) {
          this.removeProduct(product);
        }
      }
    })
    localStorage.setItem('cart', JSON.stringify(this.cartList));
  }

  async removeProduct(product) {
    const alert = await this.alert.create({
      header: `${this.translate.instant("DELETE-CONFIRMATION")} ${this.selectedLanguage == 'al' ? product?.nameSQ : product?.nameMK}`,
      buttons: [
        {
          text: this.translate.instant("NO"),
          role: 'cancel',
          handler: () => {}
        },
        {
          text: this.translate.instant("YES"),
          role: 'confirm',
          handler: () => {
            if(product.category){
              this.cartList = this.cartList.filter((prod) => prod.productId !== product.productId);
            } else {
              this.cartList = this.cartList.filter((prod) => prod.id !== product.id);
            }
            localStorage.setItem('cart', JSON.stringify(this.cartList));
            this.dataService.getCartListNumber(this.cartList.length);
          }
        }
      ]
    })

    await alert.present();
  }

  total() {
    // return this.cartList.reduce((sum, prod) => sum += prod.sellingPriceWithVat * prod.quantity, 0).toFixed(1);
    return this.getSubtotal();

  }

  backClicked() {
    this.location.back();
  }

  manageProducts() {
    this.manage = true;
  }

  checkedProducts(event, cart) {
    if (event.target.checked) {
      this.checkedCards.push(cart);
    } else {
      this.checkedCards = this.checkedCards.filter((prod) => prod.id !== cart.id);
    }
  }

  async removeProducts() {
    const alert = await this.alert.create({
      header: `${this.translate.instant("DELETE-CONFIRMATION-SELECTED")}`,
      buttons: [
        {
          text: this.translate.instant("NO"),
          role: 'cancel',
          handler: () => {}
        },
        {
          text: this.translate.instant("YES"),
          role: 'confirm',
          handler: () => {
            this.checkedCards.forEach(card => {
              this.cartList = this.cartList.filter((prod) => prod.id !== card.id);
            })
            localStorage.setItem('cart', JSON.stringify(this.cartList));
            this.dataService.getCartListNumber(this.cartList.length);
          }
        }
      ]
    })

    await alert.present();
  }  
}
