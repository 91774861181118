<!-- <app-header [showSearch]="false"></app-header> -->

<ion-content>
<div class="user-page">
  <form class="user-form" [formGroup]="forgotPasswordForm">
    <a (click)="backClicked()">
      <ion-icon size="large" name="arrow-back"></ion-icon>
    </a>
  <h1 class="w-700 mt-80">{{'FORGOT-YOUR-PASSWORD' | translate}}</h1>  
  <!-- <p class="forgot-password-text">{{"FORGOT-PASSWORD" | translate}}</p> -->
  <p class="forgot-password-text">{{"FORGOT-PASSWORD-PARAGRAPH" | translate}}</p>
  <mat-label>{{"EMAIL" | translate}}</mat-label>
  <mat-form-field appearance="outline" class="user-form-field">
    <input matInput id="email" placeholder="{{'EMAIL' | translate}}" value="" formControlName="Username">
  </mat-form-field>
</form>

<button mat-raised-button class="forgot-password-btn" appearance="fill" (click)="forgotpassword(forgotPasswordForm.value)">{{"SUBMIT" | translate}}</button>
  <br>

<!-- <a class="router-link" [routerLink]="['/signin']" routerLinkActive="router-link-active" >{{"ALREADY-HAVE-LOGIN-AND-PASSWORD" | translate}}</a> -->
<a class="router-link" (click)="forgotpassword(forgotPasswordForm.value)">{{"DID-NOT-RECEIVE-EMAIL" | translate}}</a>
</div>
</ion-content>