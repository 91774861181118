import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { DataService } from 'src/app/data.service';
import { FilterService } from 'src/app/filter.service';

@Component({
  selector: 'app-all-categories-modal',
  templateUrl: './all-categories-modal.component.html',
  styleUrls: ['./all-categories-modal.component.css'],
  standalone: true,
  imports: [ CommonModule, IonicModule ]
})
export class AllCategoriesModalComponent implements OnInit {
  productCategories = [];
  selectedCategoryName: string;

  constructor(private dataService: DataService, private modalCtrl: ModalController,
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.selectedCategoryName = this.filterService.getSelectedCategory();

    this.dataService.getCategories().subscribe((data: any[]) => {
      this.productCategories = data;
    })
  }

  selectCategory(categoryName: string) {
    this.selectedCategoryName = categoryName;
    this.filterService.setSelectedCategory(categoryName);
    // this.dismiss(); // Close the modal after selection
  }

  dismiss() {
    this.modalCtrl.dismiss({
      selectedCategory: this.selectedCategoryName,
      'dismissed': true
    });
  }
}
