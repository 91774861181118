<ion-content>
    <div class="flex-between-center">
        <div class="flex-no-end">
            <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
            <h6 class="font-16-550">Deactivate</h6>
        </div>
    </div>
    <hr class="custom-hr">
    <ion-row>
        <ion-col size="6" offset="3" class="mt-5">
            <ion-img class="img-size" src="assets/images/new/settings-deactivate.svg" alt="Discover"></ion-img>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col size="8" offset="2" class="text-center">
            <p class="font-18-dark">Are you sure you want to delete your account?</p>
        </ion-col>
    </ion-row>
    <ion-row class="mx-4">
        <ion-col>
            <ion-button routerLink="/profile-settings" size="large" expand="full" shape="round" color="warning">
              <span class="btn-text">Cancel</span>
            </ion-button>  
        </ion-col>
        <ion-col>
          <ion-button size="large" expand="full" shape="round" color="dark" fill="outline">
            <span class="btn-text">Delete</span>
          </ion-button>  
        </ion-col>
      </ion-row>
</ion-content>
