

<!-- <app-header [showSearch]="false"></app-header> -->

<ion-content>
  <div class="head-guest">
    <h6 routerLink="/products">{{"CONTINUE-GUEST" | translate}}</h6>
  </div>
<div class="user-page">

  <!-- <span class="text-info" *ngIf="isAccountActivated !== null">{{'ACCOUNT_ACTIVATED' | translate}}</span> -->
   <h1 class="font-25-w-700">{{"LOGIN" | translate}}</h1>

  <form class="user-form" [formGroup]="signInForm">
  <mat-label>{{"EMAIL" | translate}}</mat-label>  
  <mat-form-field appearance="outline" class="user-form-field">
    <input matInput id="email" placeholder="{{'EMAIL'| translate}}" value="" formControlName="Username"> 
  </mat-form-field>

  <div class="forget-pass-link">
    <a [routerLink]="['/forgot-password']">{{"FORGOT-YOUR-PASSWORD" | translate}}</a>
  </div>

  <div class="password">
    <mat-label>{{"PASSWORD" | translate}}</mat-label>
  <mat-form-field class="user-form-field" appearance="outline">
    <input type="password" matInput placeholder="{{'PASSWORD' | translate}}" value=""  [type]="viewPassword ? 'text' : 'password'"  formControlName="Password">
    <img class="eye-icon" src="../../assets/images/new/icons/eye.png" height="25" *ngIf="!viewPassword" (click)="passwordShowHide()" />
    <img class="eye-off-icon" src="../../assets/images/new/icons/eye-off.png" height="25" *ngIf="viewPassword" (click)="passwordShowHide()" />
  </mat-form-field>
</div>
  
</form>
<br>
<button mat-raised-button class="user-login-btn" appearance="outline" (click)="login(signInForm.value)">{{"LOGIN" | translate}}</button>

<br>
<a class="router-link" [routerLink]="['/signup']" >{{"DONT-HAVE-AN-ACCOUNT-YET" | translate}}</a>

</div>


<!-- <ion-input label="Outline input" label-placement="floating" fill="outline" placeholder="Enter text"></ion-input> -->
  
</ion-content>