<ion-content>
    <div class="flex-between-center">
        <div class="flex-no-end">
            <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
            <h6 class="font-16-550">Account Information</h6>
        </div>
    </div>
    <hr class="custom-hr">
    <div class="user-page">
        <form class="user-form" [formGroup]="accountForm">
            <!-- Name -->
            <mat-label>{{"Name" | translate}}</mat-label>
            <input matInput formControlName="name" placeholder="{{'John Doe' | translate}}"
              [style.--border-color]="accountForm.controls['name'].invalid && (accountForm.controls['name'].touched || accountForm.controls['name'].dirty || isFormSubmitted) ? '#F85E5E' : '#EAEAEA'" />
            
            <!-- Email -->
            <mat-label>{{"EMAIL" | translate}}</mat-label>
            <input matInput formControlName="email" placeholder="{{'SIGNUP.EMAIL' | translate}}" 
                [style.--border-color]="accountForm.controls['email'].invalid && (accountForm.controls['email'].touched || accountForm.controls['email'].dirty || isFormSubmitted) ? '#F85E5E' : '#EAEAEA'"/>
            <span *ngIf="accountForm.controls['email'].errors?.email" class="text-danger">{{'SIGNUP.NOT_VALID_EMAIL' | translate}}</span>
            <!-- Phone   -->
            <mat-label>{{"Phone Number" | translate}}</mat-label>
            <input matInput formControlName="phone" placeholder="{{'Phone Number' | translate}}"
                [style.--border-color]="accountForm.controls['phone'].invalid && (accountForm.controls['phone'].touched || accountForm.controls['phone'].dirty || isFormSubmitted) ? '#F85E5E' : '#EAEAEA'"/>
            <span *ngIf="accountForm.controls['phone'].invalid && (accountForm.controls['phone'].touched || isFormSubmitted)"
                class="text-danger">{{'Phone number required' | translate}}</span>

            <!-- Country -->
            <mat-label>{{"Country" | translate}}</mat-label>
            <input matInput formControlName="country" placeholder="{{'Macedonia' | translate}}"
              [style.--border-color]="accountForm.controls['country'].invalid && (accountForm.controls['country'].touched || accountForm.controls['country'].dirty || isFormSubmitted) ? '#F85E5E' : '#EAEAEA'" />
        </form>
    </div>
      <ion-row class="mx-2">
        <ion-col size="12">
          <ion-button size="large" expand="full" shape="round" color="warning">
            <span class="btn-text" (click)="saveUserChanges(accountForm.value)">Save Changes</span>
          </ion-button>  
        </ion-col>
      </ion-row>
</ion-content>
