<ion-content>
    <div class="flex-between-center">
        <div class="flex-no-end">
            <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
            <h6 class="font-16-550">Account Settings</h6>
        </div>
    </div>
    <ion-segment class="mt-20" color="warning" value="userinfo" (ionChange)="onSegmentChange($event)">
        <ion-segment-button value="userinfo">
          <ion-label [ngClass]="tabSelected == 'userinfo' ? 'selected-grey' : 'grey'">User Information</ion-label>
        </ion-segment-button>
        <ion-segment-button value="billinginfo">
          <ion-label [ngClass]="tabSelected == 'billinginfo' ? 'selected-grey' : 'grey'">Billing Information</ion-label>
        </ion-segment-button>
    </ion-segment>
    <ion-list *ngIf="tabSelected == 'userinfo'" lines="none">
        <ion-item>
            <ion-grid>
                <ion-row [routerLink]="['/account-info']">
                    <ion-col size="1" class="d-flex-align-center">
                        <ion-img class="img-size" src="assets/images/new/icons/person.svg" alt="person"></ion-img>
                        <!-- <ion-icon name="person" class="settings-icon"></ion-icon> -->
                    </ion-col>
                    <ion-col size="10">
                        <h5 class="title">Account Information</h5>
                        <p class="text">See your account information like your number and email address.</p>
                    </ion-col>
                    <ion-col size="1" class="d-flex-align-center">
                        <ion-icon name="arrow-forward" class="arrow-icon"></ion-icon>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-item>
        <ion-item>
            <ion-grid>
                <ion-row [routerLink]="['/password-change']">
                    <ion-col size="1" class="d-flex-align-center">
                        <ion-img class="img-size" src="assets/images/new/icons/lock.svg" alt="lock"></ion-img>
                        <!-- <ion-icon name="key" class="settings-icon"></ion-icon> -->
                    </ion-col>
                    <ion-col size="10">
                        <h5 class="title">Change your password</h5>
                        <p class="text">See your account information like your number and email address.</p>
                    </ion-col>
                    <ion-col size="1" class="d-flex-align-center">
                        <ion-icon name="arrow-forward" class="arrow-icon"></ion-icon>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-item>
        <ion-item>
            <ion-grid>
                <ion-row [routerLink]="['/deactivate']">
                    <ion-col size="1" class="d-flex-align-center">
                        <ion-img class="img-size" src="assets/images/new/icons/deactivate.svg" alt="deactivate"></ion-img>
                        <!-- <ion-icon name="heart-half" class="settings-icon"></ion-icon> -->
                    </ion-col>
                    <ion-col size="10">
                        <h5 class="title">Deactivate your account</h5>
                        <p class="text">See your account information like your number and email address.</p>
                    </ion-col>
                    <ion-col size="1" class="d-flex-align-center">
                        <ion-icon name="arrow-forward" class="arrow-icon"></ion-icon>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-item>
      </ion-list>
</ion-content>
