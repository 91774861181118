import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.css'],
  standalone: true,
  imports: [ CommonModule, IonicModule, RouterModule ]
})
export class ProfileSettingsComponent implements OnInit {
  tabSelected: string = 'userinfo';

  constructor(private location: Location) { }

  ngOnInit(): void {
  }

  onSegmentChange(event) {
    this.tabSelected = event.target.value;
  }

  backClicked() {
    this.location.back();
  }
}
