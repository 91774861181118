import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.css'],
  standalone: true,
  imports: [ CommonModule, IonicModule, ReactiveFormsModule, MatFormFieldModule, TranslateModule ]
})
export class AccountInfoComponent implements OnInit {
  isFormSubmitted: boolean = false;
  accountForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    phone: new UntypedFormControl('', Validators.required),
    country: new UntypedFormControl('', Validators.required)
  })

  constructor(private location: Location) { }

  ngOnInit(): void {
  }

  saveUserChanges(form) {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    console.log('form', form, token, userId);
  }

  backClicked() {
    this.location.back();
  }
}
