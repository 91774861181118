<ion-content>
    <div class="flex-between-center">
        <div class="flex-no-end">
            <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
            <h6 class="font-16-550">Profile</h6>
        </div>
        <ion-icon class="settings-icon" name="settings"></ion-icon>
    </div>
    <ion-grid>
        <ion-row *ngIf="isLogged">
          <ion-col size="3" offset="1">
            <div class="user-profile">
                <h5 class="initials">{{initials}}</h5>
            </div>
          </ion-col>
          <ion-col size="8">
            <h5 class="name-surname">{{name}} {{surname}}</h5>
            <p class="email">{{email}}</p>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="!isLogged">
            <ion-col size="3" offset="1">
              <div class="no-user-profile">
                <ion-icon name="person" class="no-user-icon"></ion-icon>
              </div>
            </ion-col>
            <ion-col size="3">
                <ion-button routerLink="/signin" size="large" shape="round" fill="clear" color="dark">
                    <span class="btn-text mt-2">Login</span>
                </ion-button>
            </ion-col>
            <ion-col size="5">
                <ion-button class="register-btn" routerLink="/signup" size="large" shape="round" color="warning">
                    <span class="btn-text">Register</span>
                </ion-button>  
            </ion-col>
          </ion-row>
        <ion-card [routerLink]="['/shopping-cart']">
            <ion-card-content>
              <ion-row>
                  <ion-col size="2" offset="1">
                    <ion-img class="img-size" src="assets/images/new/icons/buy.svg" alt="cart"></ion-img>
                      <!-- <ion-icon name="cart" class="menu-icon"></ion-icon> -->
                  </ion-col>
                  <ion-col size="9">
                      <ion-label class="menu-text">Cart</ion-label>
                  </ion-col>
              </ion-row>
            </ion-card-content>
         </ion-card>
        <ion-card>
            <ion-card-content>
              <ion-row [routerLink]="['/favorite']">
                  <ion-col size="2" offset="1">
                    <ion-img class="img-size" src="assets/images/new/icons/fav-products.svg" alt="add adress"></ion-img>  
                    <!-- <ion-icon name="flag" class="menu-icon"></ion-icon> -->
                  </ion-col>
                  <ion-col size="9">
                      <ion-label class="menu-text">Favorite Products</ion-label>
                  </ion-col>
              </ion-row>
            </ion-card-content>
        </ion-card>
        <ion-card>
            <ion-card-content>
              <ion-row [routerLink]="['/my-orders']">
                  <ion-col size="2" offset="1">
                    <ion-img class="img-size" src="assets/images/new/icons/my-orders.svg" alt="add adress"></ion-img>
                      <!-- <ion-icon name="cart" class="menu-icon"></ion-icon> -->
                  </ion-col>
                  <ion-col size="9">
                      <ion-label class="menu-text">My Orders</ion-label>
                  </ion-col>
              </ion-row>
            </ion-card-content>
         </ion-card>
        <ion-card>
            <ion-card-content>
              <ion-row>
                  <ion-col  size="2" offset="1">
                      <ion-icon name="notifications" class="menu-icon"></ion-icon>
                  </ion-col>
                  <ion-col size="9">
                      <ion-label class="menu-text">Notifications</ion-label>
                  </ion-col>
              </ion-row>
            </ion-card-content>
        </ion-card>
        <ion-card [routerLink]="['/profile-settings']">
            <ion-card-content>
              <ion-row>
                  <ion-col size="2" offset="1">
                      <ion-icon name="settings" class="menu-icon"></ion-icon>
                  </ion-col>
                  <ion-col size="9">
                      <ion-label class="menu-text">Account Settings</ion-label>
                  </ion-col>
              </ion-row>
            </ion-card-content>
         </ion-card>
        <ion-card [routerLink]="['/addresses']">
            <ion-card-content>
              <ion-row>
                  <ion-col size="2" offset="1">
                    <ion-img class="img-size" src="assets/images/new/icons/add-adress.svg" alt="add adress"></ion-img>
                    <!-- <ion-icon name="pin" class="menu-icon"></ion-icon> -->
                  </ion-col>
                  <ion-col size="9">
                      <ion-label class="menu-text">Adresat e mia</ion-label>
                  </ion-col>
              </ion-row>
            </ion-card-content>
        </ion-card>
        <ion-card *ngIf="isLogged" (click)="logOut()">
            <ion-card-content>
              <ion-row>
                  <ion-col size="2" offset="1">
                    <ion-img class="img-size" src="assets/images/new/icons/logout.svg" alt="logout"></ion-img>
                    <!-- <ion-icon name="log-out" class="logout-icon"></ion-icon> -->
                  </ion-col>
                  <ion-col size="9">
                      <ion-label class="logout-text">Logout</ion-label>
                  </ion-col>
              </ion-row>
            </ion-card-content>
        </ion-card>
    </ion-grid>
</ion-content>