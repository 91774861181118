<div class="header" fxLayout="row" fxLayoutWrap>

  <img class="logoName" style="font: poppins" [routerLink]="['/']" src="../../assets/images/logo.png">
  <i class="material-icons-outlined" (click)="backClicked()">close</i>
</div>

<ion-content>
<div>
  <p class="settingsTitle">{{'SETTINGS' | translate}}</p>
</div>

<div class="favorite-container" fxLayout="row" fxLayoutWrap>
  <i class="material-icons">favorite</i>
  <p class="favorite-products " routerLink="/favorite">
    {{'FAVORITE_PRODUCTS' | translate}}
  </p>
</div>

<div class="row">
  <div class="col-6 p-8" *ngFor="let favorites of data">
    <mat-card [routerLink]="['/productdetail', favorites.id]" class="cardd">
      <div fxLayoutAlign="center center" class="image-container">
        <img mat-card-image class="card-image" src="{{ imgpath + favorites.image }}" />
      </div>
      <mat-card-title class="mat-card-title">{{ favorites | translateName }}</mat-card-title>
      <mat-card-content>
        <div class="mat-card-content">
          <p class="product-category">{{'CATEGORY' | translate}}</p>
          <p class="product-price">
            {{ favorites.sellingPriceWithVat }}{{'CURRENCY' | translate}}
          </p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="settings-notifications">
  <p>{{'NOTIFICATIONS' | translate}}</p>
  <div>
    <input type="checkbox" id="all-products" checked>
    <label for="all-products" class="all-products"> {{'ALL_NEW_PRODUCTS' | translate}}</label>
  </div>

  <div>
    <input type="checkbox" id="products-sale">
    <label for="products-sale" class="products-sale"> {{'PRODUCTS_ON_SALE' | translate}}</label>
  </div>

  <div>
    <input type="checkbox" id="ending-offers">
    <label for="ending-offers" class="ending-offers"> {{'ENDING SOON OFFERS' | translate}}</label>
  </div>
</div>
</ion-content>
