import { Component, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/data.service';
import { SearchService } from 'src/app/search.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { IonicModule } from '@ionic/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-company-categories-list',
  templateUrl: './company-categories-list.component.html',
  styleUrls: ['./company-categories-list.component.scss'],
  standalone: true,
  imports: [ IonicModule, RouterModule, ScrollingModule ]
})
export class CompanyCategoriesListComponent implements OnInit {
  companyCategories: any[];
  searchText;
  selectedLanguage = "";
  imgpath = environment.IMG_PATH;
  categories: any = [];
  tabSelected: string = '';

  constructor(
    private dataService: DataService,
    private searchService: SearchService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private translate:TranslateService
    ) {}

  ngOnInit(): void {
    this.searchService.searchTextt.subscribe((val) => {
      this.searchText = val;

    });
    this.searchService.selectedLanguage.subscribe((val) => {
      this.selectedLanguage = val;
    });
    if(localStorage.getItem('selectedLanguage') === null) {
      this.selectedLanguage = 'al';
    }else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }
    this.getCategories();
  }

  getCategories(){
    this.dataService.getMobileCompanyCategories().subscribe(data => {
      this.companyCategories = this.getAllCategoriesWithImages(data);
    },
    (error) => {
      this.spinner.hide()
      Swal.fire({
        text: 'Not Found',
        icon: 'error',
        confirmButtonText: 'Close',
      }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/companycategories']);
        }
      });
    });
  }

  getAllCategoriesWithImages(data) {
    const categoriesWithImages = [];
    const uniqueCategoriesSet = new Set();
    data.forEach(element => {
      element.company.category.forEach((category) => {
        if (!uniqueCategoriesSet.has(category.id)) {
          uniqueCategoriesSet.add(category.id);
          this.categories.push(category);
        }
      });
    });
    return this.categories;
  }

  get currentLanguage(){
    return this.translate.currentLang;
  }

  onSegmentChange(event) {
    this.tabSelected = event.target.value;
  }
}