<ion-content>
<div class="menuDiv" fxLayout="row" fxLayoutWrap>
  <h3 class="menu">{{'MENU' | translate}}</h3>

  <i class="material-icons-outlined" (click)="backClicked()">close</i>
</div>
<div fxLayout="row" fxLayoutWrap>
  <button class="viewCompaniesBtn" mat-raised-button routerLink="/products">
    {{'VIEW_ALL_PRODUCTS' | translate}} <i class="material-icons">east</i>
  </button>
</div>
<div fxLayout="row" fxLayoutWrap>
  <button class="viewCompaniesBtni" mat-raised-button routerLink="/productcategories">
    {{'PRODUCT_CATEGORIES' | translate}} <i class="material-icons">east</i>
  </button>
</div>
<div fxLayout="row" fxLayoutWrap>
  <button class="viewCompaniesBtni" mat-raised-button [routerLink]="['/companies']">
    {{'VIEW_ALL_COMPANIES' | translate}} <i class="material-icons">east</i>
  </button>
</div>

<div fxLayout="row" fxLayoutWrap>
  <button class="viewCompaniesBtni" mat-raised-button routerLink="/companycategories">
    {{'COMPANY_CATEGORIES' | translate}} <i class="material-icons">east</i>
  </button>
</div>

<div fxLayout="row" fxLayoutWrap>
  <button class="viewCompaniesBtni" mat-raised-button [routerLink]="['/favorite']">
    {{'FAVORITES' | translate}} <i class="material-icons">favorite</i>
  </button>
</div>

<div fxLayout="row" fxLayoutWrap>
  <button class="viewCompaniesBtni" mat-raised-button [routerLink]="['/settings']">
    {{'SETTINGS' | translate}}<i class="material-icons">settings</i>
  </button>
</div>
<div fxLayout="row" fxLayoutWrap>
  <button class="viewCompaniesBtni" mat-raised-button [routerLink]="['/user']">
    {{'USER' | translate}}<i class="material-icons">person</i>
  </button>
</div>
</ion-content>