import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonModule, Location } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.css'],
  standalone: true,
  imports: [ RouterModule, TranslateModule, IonicModule, CommonModule ]
})
export class AddressesComponent implements OnInit {
  clientConomeId: number;  
  addresses: any[] = [];

  constructor(private http: HttpClient, private location: Location) { }

  ngOnInit(): void {
    this.clientConomeId = parseInt(localStorage.getItem('userId'), 10);
    this.getAddresses();
  }

  getAddresses() {
    const clientConomeId = this.clientConomeId;
  
    this.http.get(`${environment.API_URL}/api/AddressApi/GetAddressesByClient?clientConomeId=${clientConomeId}`)
      .subscribe(
        (response: any) => {
          this.addresses = response;

        },
        error => {
          console.error('Error fetching addresses', error);
        }
      );
  }

  backClicked() {
    this.location.back();
  }
  
}
