<ion-grid *ngIf="isSuccess" class="container">
    <ion-row>
      <ion-col>
        <div class="ion-text-center">
            <ion-img class="img-size" src="assets/images/new/payment-successful.png" alt="Discover"></ion-img>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
          <div class="ion-text-center mx-5">
            <h3 class="font-27-dark">{{cashOrCard == 'card' ? 'Payment was successful!' : 'Pagesa do te behet ne kesh'}}</h3>
            <p *ngIf="cashOrCard == 'card'" class="font-18-dark py-3">You will recieve an email with your order’s details</p>
            <a [routerLink]="['/products']" class="font-16-weight-700-dark-underline">Continue Shopping</a>
          </div>
        </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="!isSuccess" class="container">
    <ion-row>
      <ion-col>
        <div class="ion-text-center">
            <ion-img class="img-size" src="assets/images/new/payment-error.png" alt="Discover"></ion-img>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
          <div class="ion-text-center mx-5">
            <h3 class="font-27-dark">There was a problem with your order :(</h3>
            <a [routerLink]="['/products']" class="font-16-weight-700-dark-underline">Try Again</a>
          </div>
        </ion-col>
    </ion-row>
  </ion-grid>