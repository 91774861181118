import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { Options } from '@angular-slider/ngx-slider';
import { SearchService } from '../../search.service';
import * as geolib from 'geolib';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { Subject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { CompanyCategory, CompanyData, CompanyWithLocation } from 'src/app/interfaces/ICompany';
import { FilterService } from 'src/app/filter.service';
import { Plugins,  } from '@capacitor/core';
import {GeolocationPosition} from '@capacitor/geolocation'
import { debounceTime } from 'rxjs/operators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from '../header/header.component';
import { CommonModule } from '@angular/common';
import { CustomFilterPipe } from '../Pipes/filter.pipe';
import { CaseInsensitiveSortPipe } from '../Pipes/case-insensitive-sort.pipe';

// slider range km with step 1
const OPTIONS: Options = {floor:1, ceil:200, step:1   };
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css'],
  standalone: true,
  imports: [ IonicModule, CommonModule, FormsModule, TranslateModule, NgxSpinnerModule, ScrollingModule, HeaderComponent, CustomFilterPipe, CaseInsensitiveSortPipe ]
})
export class CompaniesComponent implements OnInit,OnDestroy{
  searchTextt: String;
  selectedLanguage: String;
  categoriesSet = new Set();
  categories!: CompanyCategory[]
  selectedCategory: boolean = false;
  selectedCategoryid: number;
  subscription: Subscription;
  order: string = 'asc';
  sortingBasedOnLanguage: string = 'nameSQ'
  loc: GeolocationPosition;
  private sliderValueSubject = new Subject<number>();
  private sliderValueSubscription : Subscription

  constructor(
    private dataService: DataService,
    private searchService: SearchService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private filterService: FilterService,
    private translate:TranslateService
  ) {}

  compani: CompanyData[] = [];
  imgpath: string = environment.API_URL + '/';
  companies: CompanyData[] = [];
  value = JSON.parse(localStorage.getItem('value'));

  get options(){
    return OPTIONS;
  }

  get currentLanguage(){
    return this.translate.currentLang;
  }

  sortProductsBasedOnSelectedLanguage(): void {
    let lang: string | undefined = (localStorage.getItem("selectedLanguage"))
    lang === 'mk' ? this.sortingBasedOnLanguage = 'nameMK' : this.sortingBasedOnLanguage = 'nameSQ'
  }

  setToArrayAndSetCategories(set: Set<any>): void {        
    this.categories = Array.from(set)        
  }

  detectchange(value) {
    this.sliderValueSubject.next(value);
  }

  async ngOnInit() {
    this.searchService.searchTextt.subscribe((val) => {
      this.searchTextt = val;
    });

    this.searchService.selectedLanguage.subscribe((val) => {
      this.selectedLanguage = val;
    });

    if(localStorage.getItem('selectedLanguage') == null) {
      this.selectedLanguage = 'al';
    }
    //check if has language on localStorage
    else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }

    this.spinner.show();
    this.order = this.filterService.getValue();
    this.sortProductsBasedOnSelectedLanguage()

    this.detectchange(this.value);
    this.findMe();
    
    this.sliderValueSubscription = this.sliderValueSubject
    .pipe(debounceTime(300))
    .subscribe(value => {
      console.log('inner');
      localStorage.setItem('value', JSON.stringify(value));
      this.findMe();
    });
  }


  async findMe() {    
    try {
      const { Geolocation } = Plugins;
      this.loc = await Geolocation.getCurrentPosition();
      var latitude = this.loc.coords.latitude;
      var longitude = this.loc.coords.longitude
    }
    catch (err) {
      this.spinner.hide();
      // Popup message
      Swal.fire({
        text: 'Please enable location',
        icon: 'warning',
        confirmButtonText: 'Enable Location'
      }).then((result) => {
        if (result.isConfirmed) window.location.reload();
      });
    }

    this.dataService.getMobileCompanyCategories().subscribe(formated => {
      if(formated.length == 0){
        this.spinner.hide()
        Swal.fire({
          text: 'Not Found',
          icon: 'error',
          confirmButtonText: 'Close',
        }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/companies']);
          }
        });
      }
     
      this.companies = formated;
      this.compani = [];
      this.companies.forEach(formate => {
        
        const latitudee: string = formate.company.lat;
        const longitudee: string = formate.company.lon;
        const radius: number = this.value * 1000;
        var geolibi = geolib.isPointWithinRadius(
          { latitude: latitude, longitude: longitude },
          { latitude: latitudee || 0, longitude: longitudee || 0 },
          radius //meters
        );
        
        if(!geolibi) return
        this.compani.push(formate);
        
        const existingCompany = Array.from(this.categoriesSet).find((company: any) => company.id === formate.company.id);
  
        if (!existingCompany) {
          this.categoriesSet.add(formate.company);
        }
        
        this.spinner.hide();
      })
      
      this.setToArrayAndSetCategories(this.categoriesSet)
      this.spinner.hide()

    })
  }

  onSelectedCategory(id:number | null){
    this.selectedCategoryid = id;
    
    if (!id) {
      this.companies = this.compani
      return
    }
    
    this.companies = this.compani.filter((company) => company.company.id === id)    
  }

  ngOnDestroy(){
    this.subscription && this.subscription.unsubscribe();
  }
  
}
