<ion-grid *ngIf="isSuccess" class="container">
    <ion-row>
      <ion-col>
        <div class="ion-text-center">
            <ion-img src="assets/images/Group 7320.png" alt="Discover"></ion-img>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
          <div class="ion-text-center mx-5">
            <h3 class="font-27-dark">{{cashOrCard == 'card' ? 'Payment was successful!' : 'Porosia u be. Pagesa do te behet ne kesh.'}}</h3>
            <p  class="font-18-dark py-3">{{"ORDER-SUCCESS-MAIL" | translate}}</p>
            <a [routerLink]="['/home']" class="font-16-weight-700-dark-underline">{{"FAVORITES-PAGE.CONTINUE-SHOPPING" | translate}}</a>
          </div>
        </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="!isSuccess" class="container">
    <ion-row>
      <ion-col>
        <div class="ion-text-center">
            <ion-img src="assets/images/Group 7325.png" alt="Discover"></ion-img>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
          <div class="ion-text-center mx-5">
            <h3 class="font-27-dark">There was a problem with your order </h3>
            <a [routerLink]="['/home']" class="font-16-weight-700-dark-underline">Try Again</a>
          </div>
        </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="orderCashSuccess" class="container">
    <ion-row>
      <ion-col>
        <div class="ion-text-center">
            <ion-img src="assets/images/favorite.png" alt="Discover"></ion-img>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
          <div class="ion-text-center mx-5">
            <h3 class="font-27-dark">There was a problem with your order: </h3>
            <a [routerLink]="['/home']" class="font-16-weight-700-dark-underline">Try Again</a>
          </div>
        </ion-col>
    </ion-row>
  </ion-grid>