import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { IonicModule, Platform } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/data.service';
import { FilterService } from 'src/app/filter.service';
import { CompanyData } from 'src/app/interfaces/ICompany';
import { SearchService } from 'src/app/search.service';
import { FilterDataService } from '../user/services/filter-data.service';
import { Position, Geolocation } from '@capacitor/geolocation';
import * as geolib from 'geolib';
import { IProductWithLatAndLon } from 'src/app/interfaces/IProduct';
import { Category, CategoryWithImage } from 'src/app/interfaces/IProductCategories';
import { Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Options } from '@angular-slider/ngx-slider';
import { debounceTime } from 'rxjs/operators';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import Swal from 'sweetalert2';
import { SearchComponent } from '../search/search.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HeaderComponent } from '../header/header.component';
import { CustomFilterPipe } from '../Pipes/filter.pipe';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperDirective } from '../../../lib/swiper.directive';

const OPTIONS: Options = { floor: 1, ceil: 200, step: 1 };

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  standalone: true,
  imports: [SearchComponent, IonicModule, NgxSpinnerModule, CommonModule, FormsModule, NgxSpinnerModule, TranslateModule, ScrollingModule, CustomFilterPipe, RouterModule, SwiperDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})

export class HomeComponent implements OnInit {

    companies: CompanyData[] = [];
  
    constructor(
      private dataService: DataService,
      private searchService: SearchService,
      private spinner: NgxSpinnerService,
      private router: Router,
      private platform: Platform,
      private filterService: FilterService,
      private translate:TranslateService,
      private filterDataService: FilterDataService,
    ) { }
  
    location:any;
    loc: Position;
    waitingForCurrentPosition: boolean = false;
    searchTextt: string;
  
    tabSelected: string = 'products';
    compani: CompanyData[] = [];
    clear: string = '';
  
    allproducts: IProductWithLatAndLon[] = [];
    products: IProductWithLatAndLon[] = [];
  
    categories: Category[] = [];
    categoriesSet = new Set();
  
    selectedCategory: boolean = false;
    selectedCategoryid:number;
    selectedCategoryName: string;

    
    productCategories: CategoryWithImage[];
    orderedProducts = [];
  
    private sliderValueSubject = new Subject<number>();
    private sliderValueSubscription : Subscription
  
    subscription: Subscription
    imgpath: string = environment.IMG_PATH;
    imagepath: string = environment.API_URL + '/';
    value = JSON.parse(localStorage.getItem('value'));
    //value = 110;//test
    filter = false;
    order: string = 'asc';
  
    sortingBasedOnLanguage: string = 'nameSQ'
    selectedCompanies: number[] = [];
    refetchSubscription: Subscription;

    loadingCounter = 0;
  
    get options(){
      return OPTIONS;
    }
  
    get currentLanguage(){
      return this.translate.currentLang;
    }  
    
    private sortSubscription: Subscription;
    currentSort: 'asc' | 'desc' | null = null;
    // bannerSlideOpts = {
    //   initialSlide: 0,
    //   speed: 400,
    //   autoplay: {
    //     delay: 3000,
    //   },
    //   pagination: {
    //     el: '.swiper-pagination',
    //     type: 'bullets',
    //   }
    // };

    @ViewChild('swiperContainer', { static: true }) swiperContainer: any;
    public config: SwiperOptions = {};

    ngOnInit(): void {
      this.sortSubscription = this.filterService.sortChanged$.subscribe(sort => {
        this.currentSort = sort;
      });

      this.refetchSubscription = this.filterDataService.refetchTrigger.subscribe(() => {
        // this.refetchData();
        const selectedCompanies = this.filterDataService.getSelectedCompanies();
        this.selectedCompanies = selectedCompanies.map(company => company.company.crmId);
      });

      this.searchService.searchTextt.subscribe((text) => {
        this.searchTextt = text;
      });

      // Swiper Configuration
      this.config = {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        slidesPerView: 1,
        spaceBetween: 50,
        autoplay: {
          delay: 3000,
        },
      };
    }

    onSlideChange() {
      const activeIndex = this.swiperContainer.nativeElement.swiper.activeIndex;
    }

    nextSlide() {
      this.swiperContainer.nativeElement.swiper.slideNext();
    }
  
    detectchange(value: number) {
      this.sliderValueSubject.next(JSON.parse(localStorage.getItem('value')));
      //this.sliderValueSubject.next(value);
    }
  
    ionViewDidEnter() {
      this.getCategories();
  
      if(!localStorage.getItem('value')) {
        localStorage.setItem('value', JSON.stringify(25));
      }
      this.searchService.searchTextt.subscribe((val) => {
        this.searchTextt = val;
      });
      this.spinner.show();
      this.order = this.filterService.getValue();
      this.sortProductsBasedOnSelectedLanguage()
      this.detectchange(this.value);
      this.findMe();
      this.findMeCompanies();
  
      this.sliderValueSubscription = this.sliderValueSubject
      .pipe(debounceTime(300))
      .subscribe(value => {
        localStorage.setItem('value', JSON.stringify(value));
        this.findMe();
        this.findMeCompanies();
      });
  
      this.updateOrderedProductsFromLocalStorage();
    }
  
    updateOrderedProductsFromLocalStorage() {
      this.orderedProducts = JSON.parse(localStorage.getItem('cart')) || [];
    }
  
   async findMe() {
    try {
      const hasOpened = localStorage.getItem('hasOpened');
      if(hasOpened) {
        var cordinates = await Geolocation.getCurrentPosition();
      /* const { Geolocation } = Plugins; */
         this.location = cordinates.coords;
         var latitude = this.location.latitude;
         var longitude = this.location.longitude;
        const companiesToFilter = this.selectedCompanies;
  
         if(cordinates) {
          this.showSpinner();
          // this.selectedCompanies.length > 0 || this.currentSort
          //   ? this.dataService.getAllProductsFiltered({ companyIds: companiesToFilter }, this.currentSort)
          //   :
          const productObservable =  this.dataService.getAllProducts();
  
  
          this.subscription = productObservable.subscribe(
            (formated: IProductWithLatAndLon[]) => {
  
              this.products = formated;
              this.allproducts = [];
  
              this.products.forEach(formate => {
                  const companyLatitude: number = parseFloat(formate.companyLatitude);
                  const companyLongitude: number = parseFloat(formate.companyLongitude);
                  const radius: number = JSON.parse(localStorage.getItem('value')) * 1000;
  
                var geolibi = geolib.isPointWithinRadius(
                    { latitude: latitude, longitude: longitude },
                    { latitude: companyLatitude, longitude: companyLongitude },
                    radius 
                );
    
  
                if (geolibi) {
                  if (this.filterService.getMinValue() == undefined && this.filterService.getMaxValue() == undefined) {
                    this.allproducts.push(formate);
                  } else {
                    this.allproducts = this.allproducts.filter(x => x.productDetails[0].productDetailSellingPriceWithVat >= this.filterService.getMinValue() && x.productDetails[0].productDetailSellingPriceWithVat <= this.filterService.getMaxValue())
                    this.allproducts.push(formate);
                  }
                } else {
                }
              });
            },
            (error) => {}
          );
          // this.spinner.hide();
          this.hideSpinner();

         }
        }
        }
        catch (err) {
          console.error("Geolocation error", err);
          this.hideSpinner();
         Swal.fire({
                text: 'Please enable location test',
                icon: 'warning',
                confirmButtonText: 'Enable Location',
              }).then((result) => {
                  if (result.isConfirmed) {
                    this.platform.ready().then( async () => {
                      if (this.platform.is('android')) {
                        let option = NativeSettings.openAndroid({
                          option: AndroidSettings.Location,
                        });
                      } else if (this.platform.is('ios')) {
                        NativeSettings.openIOS({
                          option: IOSSettings.App,
                        });
                      } else {
                        console.log('you are not in mobile app')
                      }
                    })                 
  
                    this.platform.resume.subscribe(() => {
                      window.location.reload();
                      this.subscription = this.dataService.getAllProducts().subscribe(
                        (formated: IProductWithLatAndLon[]) => {
                          this.products = formated;
                          this.allproducts = formated;
                        },
                        (error) => {}
                      );
                      //this.findMeCompanies();
                      this.spinner.hide();
                    })
                }
              });
        } finally {
          this.waitingForCurrentPosition = false;
        }
    }
  
    sortProductsBasedOnSelectedLanguage(): void {
      let lang: string | undefined = (localStorage.getItem("selectedLanguage"))
      lang === 'mk' ? this.sortingBasedOnLanguage = 'productNameMK' : this.sortingBasedOnLanguage = 'productNameSQ'
    }
  
    setToArrayAndSetCategories(set: Set<any>): void {
      this.categories = Array.from(set)
    }
  
    onFilter(){
      this.filter = true;
      this.router.navigate(["/filter"]);
    }

    onSelectedCategory(name: string | null) {
      this.selectedCategory = true;
      this.selectedCategoryName = name;

      this.filterService.setSelectedCategory(this.selectedCategoryName);
    
      let selectedCategoryName: string | undefined = '';
      if (name != null) {
        const selectedCategory = this.productCategories.find(category => category.nameSQ === name);
        selectedCategoryName = selectedCategory ? selectedCategory.nameSQ : '';
      }
    
      const filterRequest = {
        companyIds: this.selectedCompanies,
        categoryName: selectedCategoryName,
        searchText: this.searchTextt
      };
      this.showSpinner();
    
      this.dataService.getAllProductsFiltered(filterRequest, this.currentSort).subscribe(
        (filteredProducts: IProductWithLatAndLon[]) => {
          this.hideSpinner();
          this.allproducts = []; 
          const radius = JSON.parse(localStorage.getItem('value')) * 1000;
    
          // Get current location for filtering
          Geolocation.getCurrentPosition().then((cordinates) => {
            const latitude = cordinates.coords.latitude;
            const longitude = cordinates.coords.longitude;
    
            filteredProducts.forEach((product) => {
              const companyLatitude: number = parseFloat(product.companyLatitude);
              const companyLongitude: number = parseFloat(product.companyLongitude);
    
              const withinRadius = geolib.isPointWithinRadius(
                { latitude: latitude, longitude: longitude },
                { latitude: companyLatitude, longitude: companyLongitude },
                radius
              );
    
              if (withinRadius) {
                const minVal = this.filterService.getMinValue();
                const maxVal = this.filterService.getMaxValue();
                const productPrice = product.productDetails[0].productDetailSellingPriceWithVat;
    
                if (
                  (minVal === undefined && maxVal === undefined) ||
                  (minVal === 0 && maxVal === 0) ||
                  (minVal !== undefined && minVal !== 0 && (maxVal === 0 || maxVal === undefined) && productPrice >= minVal) ||
                  (productPrice >= minVal && productPrice <= maxVal)
                ) {
                  this.allproducts.push(product);
                }
              }
            });
          });
        },
        (error) => {
          console.error("Error fetching filtered products:", error);
          this.hideSpinner()
        }
      );
    }
  
    onSegmentChange(event) {
      // this.tabSelected = event.target.value;
    }
      
    async findMeCompanies() {    
      try {
        this.loc = await Geolocation.getCurrentPosition();
        var latitude = this.loc.coords.latitude;
        var longitude = this.loc.coords.longitude
      }
      catch (err) {
        this.spinner.hide();
      }
      this.showSpinner();
      this.dataService.getMobileCompanyCategories().subscribe(formated => {
        if(formated.length == 0){
          this.hideSpinner();
          Swal.fire({
            text: 'Not Found',
            icon: 'error',
            confirmButtonText: 'Close',
          }).then((result) => {
              if (result.isConfirmed) {
                // this.router.navigate(['/companies']);
            }
          });
        }
        this.companies = formated;
        this.compani = [];
        this.companies.forEach(formate => {
          
          const latitudee: string = formate.company.lat;
          const longitudee: string = formate.company.lon;
          const radius: number = this.value * 1000;
          var geolibi = geolib.isPointWithinRadius(
            { latitude: latitude, longitude: longitude },
            { latitude: latitudee || 0, longitude: longitudee || 0 },
            radius
          );
          
          if(!geolibi) return
          this.compani.push(formate);
          
          const existingCompany = Array.from(this.categoriesSet).find((company: any) => company.id === formate.company.id);
    
          if (!existingCompany) {
            this.categoriesSet.add(formate.company);
          }
          
          this.hideSpinner();
        })
        
        this.setToArrayAndSetCategories(this.categoriesSet)
        this.hideSpinner();
  
      })
    }
  
    getCategories(){
      this.showSpinner();
      this.dataService.getCategories().subscribe((data: CategoryWithImage[]) => {
        this.hideSpinner();

        this.productCategories = data.slice(0, 3);
      },
      (error) => {
        this.hideSpinner();
        Swal.fire({
          text: 'Not Found',
          icon: 'error',
          confirmButtonText: 'Close',
        }).then((result) => {
            if (result.isConfirmed) {
              // this.router.navigate(['/productcategories']);
          }
        });
      })
    }
  
    clearSearch() {
      this.clear = 'clear';
    }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.sortSubscription) {
      this.sortSubscription.unsubscribe();
    }
  }

    showSpinner() {
      this.loadingCounter++;
      this.spinner.show();
    }
    
    hideSpinner() {
      this.loadingCounter--;
      if (this.loadingCounter <= 0) {
        this.loadingCounter = 0;
        this.spinner.hide();
      }
    }
    
  }