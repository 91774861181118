<ion-toolbar>
  <h4 class="px-4 font-16-w-600">All categories</h4>
  <ion-buttons slot="end">
    <ion-button (click)="dismiss()">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
<ion-content>
    <ion-row class="row px-5">
        <ion-col 
        class="col-4 p-8 text-center ion-col-container"
        [class.selected]="selectedCategoryName === category.nameSQ"
        (click)="selectCategory(category.nameSQ)"
        *ngFor="let category of productCategories">
            <div class="card logo-circle">
              <img mat-card-image class="card-img" src="{{ imgpath + category.image }}" />
            </div>
            <ion-label class="font-15-w-600">{{ currentLanguage === 'al' ? category.nameSQ : category.nameMK }}</ion-label>
        </ion-col>
    </ion-row>
  </ion-content>
