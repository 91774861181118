import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EndpointsEnum } from 'src/app/constants/endpoints.enum';
import { RouteEnum } from 'src/app/constants/route.enum';
import { ForgotPassword, Login, Register, changePassword, getPlaces, getUser } from 'src/app/interfaces/IUsers';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) {}

  getUser(userId?:any):Observable<getUser>{
    return this.http.get<getUser>(`${environment.API_URL}/api/${RouteEnum.clientConomeApi}/${EndpointsEnum.getUser}`, {params:{userId}});
  }


  register(data): Observable<Register[]> {
    return this.http.post<Register[]>(`${environment.API_URL}/api/${RouteEnum.clientConomeApi}/${EndpointsEnum.register}`, data);
  }

  login(data): Observable<Login> {
    return this.http.post<Login>(`${environment.API_URL}/api/${RouteEnum.clientConomeApi}/${EndpointsEnum.login}`, data);
  }

  forgotpassword(data): Observable<ForgotPassword[]> {
    return this.http.post<ForgotPassword[]>(`${environment.API_URL}/api/${RouteEnum.clientConomeApi}/${EndpointsEnum.forgotpassword}`, data);
  }

  changepassword(data): Observable<changePassword[]> {
    return this.http.post<changePassword[]>(`${environment.API_URL}/api/${RouteEnum.clientConomeApi}/${EndpointsEnum.changepassword}`, data);
  }


  getAllPlaces(): Observable<getPlaces[]> {
    return this.http.get<getPlaces[]>(`${environment.API_URL}/api/${RouteEnum.clientConomeApi}/${EndpointsEnum.getPlaces}`);
  }



}
