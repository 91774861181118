<ion-grid>
    <ion-row>
      <ion-col class="email-verify-container">
        <ion-img class="img-size" src="assets/images/new/email-verify.png" alt="Discover"></ion-img>
            <h1 class="font-25-w-700 mt-80">{{"EMAIL-VERIFY" | translate}}</h1>  
            <p class="check-email">{{"ACTIVATION-LINK" | translate}}.</p>
        <ion-button [routerLink]="'/signin'" size="large" expand="full" shape="round" color="warning" class="btn-m">
            <span class="btn-text">OK</span>
        </ion-button>
        <a class="router-link">{{"FORGET-PASS.SEND-AGAIN" | translate}}</a>
      </ion-col>
    </ion-row>
</ion-grid>