import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true
})
export class CustomFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText.toLowerCase();
    
    return items.filter(item => {
       return (item.categoryID && item.categoryID.toString().includes(searchText)) ||
              (item.sellingPriceWithVat && item.sellingPriceWithVat.toString().includes(searchText)) ||
              item.nameSQ.toLowerCase().includes(searchText) || 
              item.nameMK.toLowerCase().includes(searchText);
      
      
    });
  }
}
