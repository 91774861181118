<ion-content>
  <div *ngIf="cartList.length > 0">
    <div class="flex-between-center">
      <div class="flex-no-end">
          <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
          <h6>{{"CART-PAGE.CART" | translate}}</h6>
      </div>
      <h6 *ngIf="!manage" class="title-blue" (click)="manageProducts()">{{"CART-PAGE.MANAGE-ITEMS" | translate}}</h6>
      <h6 *ngIf="manage" class="title-blue" (click)="manage = false">{{"CART-PAGE.DONE" | translate}}</h6>
  </div>

  <ion-card>
    <ion-card-header>
        <h6 class="font-17">{{"Porosia 1" | titlecase}}</h6>
    </ion-card-header>
    <ion-card-content>
      <ion-list *ngIf="!manage">
        <ion-grid *ngFor="let cart of cartList; let i = index">
            <ion-row>
              <ion-col size="3">
                <div class="img-bg">
                  <img mat-card-image class="product-image" src="{{ imgpath + cart.productImage  }}"/>
                </div>
              </ion-col>
              <ion-col size="8">
                <div class="flex-col-between-h-100">
                    <div>
                    <div>
                        <p class="font-9">{{selectedLanguage == 'al' ? cart.productNameSQ : cart.productNameMK}}</p></div>
                        <!-- <p class="font-9">{{selectedLanguage == 'al' ? cart.nameSQ : cart.nameMK}}</p> -->
                    </div>
                    <p class="font-15">{{cart.productDetails[0].productDetailSellingPriceWithVat % 1 != 0 ? cart.productDetails[0].productDetailSellingPriceWithVat.toFixed(1) : cart.productDetails[0].productDetailSellingPriceWithVat}}{{'CURRENCY' | translate | uppercase}}</p>
                
                  </div>
              </ion-col>
              <ion-col size="1">
                <span class="d-block my-auto"><img src="../../assets/images/new/icons/bin.svg" (click)="removeProduct(cart)"></span>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="auto" class="flex-row-center">
                <div class="radius-50 light-grey-col" (click)="removeQuantity(cart)"><i class="bi bi-dash-lg font-15"></i></div>
                <span class="mx-3 font-20">{{cart.quantity}}</span>
                <div class="radius-50 grey-col" (click)="addQuantity(cart)"><i class="bi bi-plus-lg font-15"></i></div>
              </ion-col>
            </ion-row>
        </ion-grid>
      </ion-list>

      <ion-list *ngIf="manage">
        <ion-grid *ngFor="let cart of cartList; let i = index">
            <ion-row>
              <ion-col size="5">
                <div class="flex-no-center">
                    <ion-checkbox (ionChange)="checkedProducts($event, cart)"></ion-checkbox>
                    <div class="img-bg">
                      <img mat-card-image class="product-image" src="{{ imgpath + cart.productImage  }}"/>
                    </div>
                </div>
              </ion-col>
              <ion-col>
                <div class="flex-col-between-h-100-checkbox">
                    <div><p class="font-9">{{selectedLanguage == 'al' ? cart.productNameSQ : cart.productNameMK}}</p></div>
                    <p class="font-15">{{cart.productDetails[0].productDetailSellingPriceWithVat % 1 != 0 ? cart.productDetails[0].productDetailSellingPriceWithVat.toFixed(1) : cart.productDetails[0].productDetailSellingPriceWithVat}}{{'CURRENCY' | translate | uppercase}}</p>
                </div>
              </ion-col>
              <ion-col size="1">
                <span class="d-block my-auto"><img src="../../assets/images/new/icons/bin.svg" (click)="removeProduct(cart)"></span>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="auto" class="flex-row-center">
                <div class="radius-50 light-grey-col" (click)="removeQuantity(cart)"><i class="bi bi-dash-lg font-15"></i></div>
                <span class="mx-3 font-20">{{cart.quantity}}</span>
                <div class="radius-50 grey-col" (click)="addQuantity(cart)"><i class="bi bi-plus-lg font-15"></i></div>
              </ion-col>
            </ion-row>
        </ion-grid>
      </ion-list>
    </ion-card-content>
  </ion-card>
  <ion-list>
    <ion-item-group>
      <ion-item>
        <div class="flex-between-w-96">
            <p class="subtotal">{{"CART-PAGE.SUBTOTAL" | translate}}</p>
            <p class="subtotal-price">{{getSubtotal()}} <span class="font-weight-light">{{'CURRENCY' | translate | uppercase}}</span></p>
        </div>
      </ion-item>
      <ion-item>
        <div class="flex-between-w-96">
            <p class="subtotal">{{"CART-PAGE.DELIVERY" | translate}}</p>
            <p class="subtotal-price">{{deliveryFee.toFixed(2)}} <span class="font-weight-light">{{'CURRENCY' | translate | uppercase}}</span></p>

        </div>
      </ion-item>
      <ion-item lines="none">
        <div class="flex-between-w-96">
            <p class="grandtotal">{{"CART-PAGE.GRANDTOTAL" | translate}}</p>
            <p class="grandtotal-price">{{getGrandTotal()}}<span class="font-weight-bold">{{'CURRENCY' | translate | uppercase}}</span></p>
        </div>
      </ion-item>
    </ion-item-group>
  </ion-list>
  <ion-row *ngIf="!manage">
    <ion-col>
      <ion-button routerLink="/payment" size="large" expand="full" shape="round" color="warning" class="btn-m">
        <span class="btn-text">{{"CART-PAGE.CONTINUE" | translate}} test</span>
      </ion-button>  
    </ion-col>
  </ion-row>
  <ion-row *ngIf="manage" class="mx-4">
    <ion-col>
      <ion-button size="large" expand="full" shape="round" color="dark" fill="outline">
        <span class="btn-text" (click)="removeProducts()">{{"CART-PAGE.DELETE" | translate}}</span>
      </ion-button>  
    </ion-col>
    <ion-col>
      <ion-button routerLink="/payment" size="large" expand="full" shape="round" color="warning">
        <span class="btn-text">{{"CART-PAGE.CONTINUE" | translate}}</span>
      </ion-button>  
    </ion-col>
  </ion-row>
  </div>

  <ion-grid *ngIf="cartList.length == 0" class="container">
    <ion-row>
      <ion-col>
        <div class="ion-text-center">
            <ion-img src="assets/images/you dont have messages.png" alt="Shopping"></ion-img>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
          <div class="ion-text-center mx-5">
            <h3 class="d-flex justify-content-center font-27">{{"CART-PAGE.EMPTY-CART" | translate}}</h3>
            <a [routerLink]="['/home']" class="font-16-weight-700-dark-underline">{{"CART-PAGE.BACK-HOME" | translate}}</a>
          </div>
        </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-alert></ion-alert>