<!-- <ion-grid>
  <ion-row>
      <ion-slides #slidesBoard (ionSlideDidChange)="slideChanged()" [options]="slideOpts" pager>
        <ion-slide size="12">
          <div class="d-flex-column">
            <ion-img src="assets/images/slider-1.png" alt="Discover"></ion-img>
            <h2 class="font-22 w-700">Discover</h2>
          </div>
        </ion-slide>
        <ion-slide size="12">
          <div class="d-flex-column">
            <ion-img src="assets/images/slider-2.png" alt="Click"></ion-img>
            <h2 class="font-22 w-700">Click</h2>
          </div>
        </ion-slide>
        <ion-slide size="12">
          <div class="d-flex-column">
            <ion-img src="assets/images/slider-3.png" alt="Shop"></ion-img>
            <h2 class="font-22 w-700">Shop</h2>
          </div>
        </ion-slide>
      </ion-slides>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-button *ngIf="nextOrStarted" size="large" expand="full" shape="round" color="warning" class="btn-m"  (click)="nextSlide()">
        <span class="btn-text">Next</span>
      </ion-button>
      <ion-button *ngIf="!nextOrStarted" size="large" expand="full" shape="round" color="warning" class="btn-m"  (click)="goTologin()">
        <span class="btn-text">Get Started</span>
        <ion-icon name="arrow-forward"></ion-icon>
      </ion-button>  
    </ion-col>
  </ion-row>
</ion-grid> -->
<swiper-container pagination="true" slides-per-view="1" #swiperCon class="swiper" (swiperslidechange)="onSlideChange()">
  <swiper-slide>
    <div class="d-flex-column">
      <ion-img src="assets/images/new/board-1.png" alt="Discover"></ion-img>
      <h2 class="font-22 w-700">Discover</h2>
    </div>
  </swiper-slide>
  <swiper-slide>
    <div class="d-flex-column">
      <ion-img src="assets/images/new/board-2.png" alt="Click"></ion-img>
      <h2 class="font-22 w-700">Click</h2>
    </div>
  </swiper-slide>
  <swiper-slide>
    <div class="d-flex-column">
      <ion-img src="assets/images/new/board-3.png" alt="Shop"></ion-img>
      <h2 class="font-22 w-700">Shop</h2>
    </div>
  </swiper-slide>
</swiper-container>
<ion-button *ngIf="nextOrStarted" size="large" expand="full" shape="round" color="warning" class="btn-m next-btn" (click)="nextSlide()">
  <span class="btn-text">Next</span>
</ion-button>
<ion-button *ngIf="!nextOrStarted" size="large" expand="full" shape="round" color="warning" class="btn-m" (click)="goTologin()">
  <span class="btn-text">Get Started</span>
  <ion-img class="img-size" src="assets/images/new/icons/arrow.png" alt="settings"></ion-img>
</ion-button>
