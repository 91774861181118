import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SearchService } from 'src/app/search.service';
import { UserService } from '../services/user.service';
import { RouterModule, ActivatedRoute, Router } from '@angular/router';
import { Login} from 'src/app/interfaces/IUsers';
import { IonicModule } from '@ionic/angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
  standalone: true,
  imports: [ IonicModule, RouterModule, TranslateModule, MatFormFieldModule, MatInputModule, CommonModule, ReactiveFormsModule ]
})
export class SignInComponent implements OnInit {
  selectedLanguage: string;
  viewPassword: boolean = false;

  signInForm = new UntypedFormGroup({

    Username : new UntypedFormControl(''),
    Password : new UntypedFormControl(''),
  });
  isAccountActivated: number|null;


  constructor(private search: SearchService,private userService: UserService, private translate: TranslateService,private router: Router,
    private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    if(localStorage.getItem('selectedLanguage') == null) {
      this.selectedLanguage = 'al';
    }else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }

    // isAccountActivated checks if comes a number as a parameter, it means it's a redirect after a successfull user activation.
    this.isAccountActivated = Number(this.activatedRoute.snapshot.paramMap.get('isAccountActivated'));
  }


  login(data){
      this.userService.login(data).subscribe((res:Login)=>{
        localStorage.setItem("token",res.token)
        localStorage.setItem("displayName",res.user.displayName)
        localStorage.setItem("userId", res.id.toString())
        this.router.navigate(['/home']);
      })
  }


  changeLanguage(event) {
    this.search.selectedLanguage.next(event.target.value);
    localStorage.setItem('selectedLanguage', event.target.value);
    this.selectedLanguage = event.target.value;
    this.translate.use(this.selectedLanguage);
    this.translate.setDefaultLang(this.selectedLanguage)

  }

  passwordShowHide(){
    this.viewPassword = !this.viewPassword;
    
  }
}
