import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
//import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/data.service';
import { SearchService } from 'src/app/search.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  standalone: true,
  imports: [ RouterModule, MatFormFieldModule, MatSelectModule, CommonModule, FormsModule, TranslateModule ]
})
export class HeaderComponent implements OnInit {

  selectedLanguage: string;
  isLogged:boolean = false;
  displayName:string = "";
  itemsNr: number = 0;

  constructor(private search: SearchService,private translate: TranslateService, private dataService: DataService, private router: Router ) {

  }

  @Input() showSearch : boolean= true;

  ngOnInit(): void {
    if(localStorage.getItem('selectedLanguage') == null) {
      this.selectedLanguage = 'al';
    }else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }

    this.isLogged = !!localStorage.getItem("token");
    this.displayName = localStorage.getItem("displayName") || "";

    this.dataService.shoppingCartSize$.subscribe(listNr => {
      this.itemsNr = listNr;
    })
    const cartItems = JSON.parse(localStorage.getItem('cart'));
    this.itemsNr = Array.isArray(cartItems) ? cartItems.length : 0;
  }

  changeLanguage(event) {
    this.search.selectedLanguage.next(event.value);
    localStorage.setItem('selectedLanguage', event.value);
    this.selectedLanguage = event.value;
    this.translate.use(this.selectedLanguage);
    this.translate.setDefaultLang(this.selectedLanguage);
    this.translate.onLangChange.emit(event.value)


  }

  signOut(){
    localStorage.removeItem("token");
    localStorage.removeItem("displayName");
    localStorage.removeItem("userId");
    this.isLogged = false;
    this.displayName = "";
    this.router.navigate(["/home"]);
  }

}
