import {
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { DataService } from '../../data.service';
import { Options } from '@angular-slider/ngx-slider';
import { SearchService } from '../../search.service';
import * as geolib from 'geolib';
import { Plugins } from '@capacitor/core';
import {GeolocationPosition} from '@capacitor/geolocation'
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { FilterService } from 'src/app/filter.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category, IProductCategories, Product } from 'src/app/interfaces/IProductCategories';
import { FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxSpinnerModule } from "ngx-spinner";
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from '../header/header.component';
import { TranslateModule } from '@ngx-translate/core';

const OPTIONS: Options = {floor:1, ceil:200, step:1   };

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.css'],
  standalone: true,
  imports: [ FormsModule, NgxSpinnerModule, ScrollingModule, TranslateModule, IonicModule, HeaderComponent ]
})
export class ProductsListComponent implements OnInit {
  filter = false;
  order: string = 'asc';
  constructor(
    private dataService: DataService,
    private searchService: SearchService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private filterService: FilterService,
    private activateRoute: ActivatedRoute
  ) {}
  getLocation: GeolocationPosition;
  waitingForCurrentPosition: boolean = false;
  searchText: string;
  allProducts: Product[] = [];
  selectedCategoryId: number;
  getProduct: Product[];
  productCategories: Product[];
  category: Category[] = [];
  subscription: Subscription;
  geolibi: boolean;
  imgpath = environment.IMG_PATH;
  value = JSON.parse(localStorage.getItem('value'));
  get options(){
    return OPTIONS
  }
  /**
   * save our prefered radius
   * @param value gets value for radius from 'distanca'
   */
  detectchange(value) {
    this.findMe();
    if (localStorage.getItem('value') === null) {
      this.value = 35;
      localStorage.setItem('value', JSON.stringify(this.value));
    }
    else {
      localStorage.setItem('value', JSON.stringify(value));
    }
  }
  ngOnInit() {
    this.activateRoute.paramMap.subscribe((obs) => {
      this.selectedCategoryId = JSON.parse(obs.get('id'));
    });
    this.searchService.searchTextt.subscribe((val) => {
      this.searchText = val;
    });

    this.spinner.show();
    this.detectchange(this.value);
    this.order = this.filterService.getValue();
  }
  async findMe() {
    try {
      const { Geolocation } = Plugins;
      this.getLocation = await Geolocation.getCurrentPosition();
      var latitude = this.getLocation.coords.latitude;
      var longitude = this.getLocation.coords.longitude;
    } catch (err) {
      this.spinner.hide();
      Swal.fire({
        text: 'Please enable location',
        icon: 'warning',
        confirmButtonText: 'Enable Location',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } finally {
      this.waitingForCurrentPosition = false;
    }


    this.subscription = this.dataService.getProductCategories().subscribe(
      (data: IProductCategories[]) => {
        this.getProduct = [];
        const filteredData= data.filter(d=>d.mobileCategoryId === this.selectedCategoryId);
       this.category= filteredData.map(d=>d.product.category)[0];

            this.allProducts = [];
            this.getProduct=filteredData.map(d=> d.product);
            this.getProduct.forEach((formate) => {
              const companyLatitude: string = formate.lat;
              const companyLongitude: string = formate.lon;
              const radius: number = this.value * 1000;

              this.geolibi = geolib.isPointWithinRadius(
                { latitude: latitude, longitude: longitude },
                { latitude: companyLatitude, longitude: companyLongitude },
                radius //meters
              );
              if (this.geolibi) {
                if (
                  this.filterService.getMinValue() == undefined &&
                  this.filterService.getMaxValue() == undefined
                ) {
                  this.allProducts.push(formate);
                }
                else {
                  this.allProducts = this.allProducts.filter(
                    (x) =>
                      x.sellingPriceWithVat >=
                        this.filterService.getMinValue() &&
                      x.sellingPriceWithVat <= this.filterService.getMaxValue()
                  );
                  this.allProducts.push(formate);
                }
              }
            });
      },
      (error) => {}
    );
    this.spinner.hide();
  }
  onFilter() {
    this.filter = true;
    this.router.navigate(['/filter']);
  }

  addToCart(e, product, index) {
    e.stopPropagation(); 
    const existingCartItems = JSON.parse(localStorage.getItem('cart')) || []; 
    const productExistInCart = existingCartItems.find(prod => prod.id === product.id);    
    if (!productExistInCart) {
      product["quantity"] = 1;
      existingCartItems.push(product);      
      localStorage.setItem('cart', JSON.stringify(existingCartItems));      
      this.dataService.getCartListNumber(existingCartItems.length);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
