import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RouterModule, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/data.service';
import { CategoryWithImage } from 'src/app/interfaces/IProductCategories';
import { SearchService } from 'src/app/search.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AllCategoriesModalComponent } from '../all-categories-modal/all-categories-modal.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { IProductCategories } from 'src/app/interfaces/IProductCategories';

@Component({
  selector: 'app-product-categories-list',
  templateUrl: './product-categories-list.component.html',
  styleUrls: ['./product-categories-list.component.css'],
  standalone: true,
  imports: [ CommonModule, IonicModule, RouterModule, ScrollingModule ]
})
export class ProductCategoriesListComponent implements OnInit {
  productCategories: IProductCategories[];
  searchText;
  imgpath = environment.IMG_PATH;
  tabSelected = '';

  constructor(
    private dataService: DataService,
    private searchService: SearchService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private modalCtrl: ModalController,
    private translate: TranslateService
    ) {}

  ngOnInit(): void {
    this.searchService.searchTextt.subscribe((val) => {
      this.searchText = val;
    });
    this.getCategories();
  }

  get currentLanguage(){
    return this.translate.currentLang;
  
  }

  getCategories(){
    this.dataService.getProductCategories().subscribe((data: IProductCategories[]) => {
      this.productCategories = data.slice(0, 3);
      console.log('prodCat', this.productCategories);
    },
    (error) => {
      this.spinner.hide()
      Swal.fire({
        text: 'Not Found',
        icon: 'error',
        confirmButtonText: 'Close',
      }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/productcategories']);
        }
      });
    })
  }

  onSegmentChange(event) {
    const selectedTab = event.target.value;
  }

  async openAllCategoreisModal() {
    const modal = await this.modalCtrl.create({
      component: AllCategoriesModalComponent,
      //breakpoints: [0, 0.2],
      //initialBreakpoint: 0,
    });
    await modal.present();
  }
}
