<ion-content>
  <div class="flex-between-center">
    <div class="flex-no-end">
      <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
      <h6 class="nav-back">Back</h6>
    </div>
  </div>

  <div class="input-container">
    <div class="input-wrapper">
      <img src="../../../../assets/images/add-address.svg" alt="Add Address Icon" class="input-icon">
      <ion-input placeholder="Add an Address here" class="custom-input" [(ngModel)]="address"></ion-input>
    </div>

    <div class="input-wrapper">
      <img src="../../../../assets/images/add-details.svg" alt="Add Details Icon" class="input-icon">
      <ion-input placeholder="Add Details" class="custom-input" [(ngModel)]="details"></ion-input>
    </div>

     <div *ngIf="successMessage" class="success-message">
      {{ successMessage }}
    </div>

    <button mat-raised-button class="save-btn" appearance="fill" (click)="saveAddress()"> 
      {{"SAVE-CHANGES" | translate}}
    </button>
  </div>

  <!-- Map Container -->
  <div id="map" class="map-container"></div>
</ion-content>
