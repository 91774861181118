import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './utils/material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
//import { DragScrollModule } from 'ngx-drag-scroll';
//import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { SortPipe } from './components/Pipes/sort.pipe';
import { IonicModule } from '@ionic/angular';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    // AppComponent,
    // BodyComponent,
    // ProductsComponent,
    // HeaderComponent,
    // SearchComponent,
    // SetingsComponent,
    // MenuComponent,
    // CompaniesComponent,
    // FilterComponent,
    // ProductCategoriesListComponent,
    // ProductdetailsComponent,
    // FavoritesComponent,
    SortPipe,
    // CompanydetailsComponent,
    // CompanyCategoriesListComponent,
    // UserComponent,
    // SignInComponent,
    // SignUpComponent,
    // ForgotPasswordComponent,
    // ChangePasswordComponent,
    // ProductsListComponent,
    // ShoppingCartComponent,
    // CaseInsensitiveSortPipe,
    // CompaniesListComponent,
    // CustomFilterPipe,
    // OnboardComponent,
    // EmailVerifyComponent,
    // AllCategoriesModalComponent,
    // PaymentComponent,
    // PaymentModalComponent,
    // ChangeAddressModalComponent,
    // PaymentResultComponent,
    // ProfileComponent,
    // ProfileSettingsComponent,
    // AccountInfoComponent,
    // PasswordChangeComponent,
    // DeactivateComponent,
    // MyOrdersComponent,
    // MyOrdersDetailsComponent,
    // MessagesComponent,
    // AddressesComponent,
    // AddAddressComponent,
    // FilterByPriceComponent,
    // FilterByBrandComponent,
    // FilterDeliveryComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    NgbModule,
    //DragScrollModule,
    NgxSliderModule,
    HttpClientModule,
    FlexLayoutModule,
    MaterialModule,
    //Ng2SearchPipeModule,
    BrowserAnimationsModule,
    NgxWebstorageModule.forRoot(),
    NgxSpinnerModule,
    TranslateModule.forRoot({
      defaultLanguage: 'al',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
    }),
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: 'infoMopbileAppDb',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}