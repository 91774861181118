import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { Options } from '@angular-slider/ngx-slider';
import { Router, RoutesRecognized } from '@angular/router';
import { FilterService } from 'src/app/filter.service';
import { LastUrlService } from 'src/app/last-url.service';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

const OPTIONS: Options = { floor:1, ceil:10, step:1 };

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
  standalone: true,
  imports: [ RouterModule, FormsModule, IonicModule, CommonModule ]
})
export class FilterComponent implements OnInit{
  priceFilter: string = 'normal';
  deliveryFilter: string = 'deliveryone';
  //brandFilter: string = 'brandone';

  constructor(
    private lokacioni: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private filterService: FilterService,
    private lastUrl: LastUrlService
  ) { }

  ngOnInit() {
    this.previousUrl = this.lastUrl.getPreviousUrl();
    
    this.getMinValue();
    this.getMaxValue();
    this.getOrder()
  }
  order!: 'asc' | 'desc'

  min:number;
  max:number;
  previousUrl:string;

  value = 5;
  private sliderValueSubject = new Subject<number>();

  get options(){
    return OPTIONS;
  }

  getOrder() {
    this.order = this.filterService.getValue();
  }

  backClicked() {
    this.lokacioni.back();
    this.filterService.resetValues();
  }

  resetFilters() {
    this.filterService.resetValues();
  }

  filterProducts() {
    this.router.navigate(['/products']);
  }

  async onSave(){    
    await this.router.navigateByUrl(this.previousUrl);
  }

  minValue(min:number){
    this.filterService.setMinValue(min);
  }

  maxValue(max:number){    
    this.filterService.setMaxValue(max);
  }

  getMinValue() {
    this.min = this.filterService.getMinValue()
  }

  getMaxValue() {
    this.max = this.filterService.getMaxValue()
  }

  onChange(event){    
    this.filterService.setValue(event);
  }
  
  detectchange(value: number) {
    this.sliderValueSubject.next(value);
    localStorage.setItem('value', JSON.stringify(value));
  }
}
