import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './utils/material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
//import { DragScrollModule } from 'ngx-drag-scroll';
//import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
//components
import { BodyComponent } from './components/body/body.component';
import { ProductsComponent } from './components/products/products.component';
import { HeaderComponent } from './components/header/header.component';
import { SearchComponent } from './components/search/search.component';
import { SetingsComponent } from './components/setings/setings.component';
import { MenuComponent } from './components/menu/menu.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { FilterComponent } from './components/filter/filter.component';
import { ProductCategoriesListComponent } from './components/product-categories-list/product-categories-list.component';
import { ProductdetailsComponent } from './components/productdetails/productdetails.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { SortPipe } from './components/Pipes/sort.pipe';
import { CompanydetailsComponent } from './components/companydetails/companydetails.component';
import { CompanyCategoriesListComponent } from './components/company-categories-list/company-categories-list.component';
import { UserComponent } from './components/user/user.component';
import { SignInComponent } from './components/user/sign-in/sign-in.component';
import { SignUpComponent } from './components/user/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/user/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/user/change-password/change-password.component';
import { TranslateNamePipe } from './components/Pipes/translate-name.pipe';
import { ProductsListComponent } from './components/products-list/products-list.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { CaseInsensitiveSortPipe } from './components/Pipes/case-insensitive-sort.pipe';
import { CompaniesListComponent } from './components/companies-list/companies-list.component';
import { CustomFilterPipe } from './components/Pipes/filter.pipe';
import { IonicModule } from '@ionic/angular';
import { OnboardComponent } from './components/onboard/onboard.component';
import { EmailVerifyComponent } from './components/email-verify/email-verify.component';
import { AllCategoriesModalComponent } from './components/all-categories-modal/all-categories-modal.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PaymentModalComponent } from './components/payment-modal/payment-modal.component';
import { ChangeAddressModalComponent } from './components/change-address-modal/change-address-modal.component';
import { PaymentResultComponent } from './components/payment-result/payment-result.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileSettingsComponent } from './components/profile-settings/profile-settings.component';
import { AccountInfoComponent } from './components/account/account-info/account-info.component';
import { PasswordChangeComponent } from './components/account/password-change/password-change.component';
import { DeactivateComponent } from './components/account/deactivate/deactivate.component';
import { MyOrdersComponent } from './components/account/my-orders/my-orders.component';
import { MyOrdersDetailsComponent } from './components/account/my-orders-details/my-orders-details.component';
import { MessagesComponent } from './components/messages/messages.component';
import { AddressesComponent } from './components/maps/addresses/addresses.component';
import { AddAddressComponent } from './components/maps/add-address/add-address.component';
import { FilterByPriceComponent } from './components/filter-by-price/filter-by-price.component';
import { FilterByBrandComponent } from './components/filter-by-brand/filter-by-brand.component';
import { FilterDeliveryComponent } from './components/filter-delivery/filter-delivery.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    // AppComponent,
    // BodyComponent,
    // ProductsComponent,
    // HeaderComponent,
    // SearchComponent,
    // SetingsComponent,
    // MenuComponent,
    // CompaniesComponent,
    // FilterComponent,
    // ProductCategoriesListComponent,
    // ProductdetailsComponent,
    // FavoritesComponent,
    SortPipe,
    // CompanydetailsComponent,
    // CompanyCategoriesListComponent,
    // UserComponent,
    // SignInComponent,
    // SignUpComponent,
    // ForgotPasswordComponent,
    // ChangePasswordComponent,
    // ProductsListComponent,
    // ShoppingCartComponent,
    // CaseInsensitiveSortPipe,
    // CompaniesListComponent,
    // CustomFilterPipe,
    // OnboardComponent,
    // EmailVerifyComponent,
    // AllCategoriesModalComponent,
    // PaymentComponent,
    // PaymentModalComponent,
    // ChangeAddressModalComponent,
    // PaymentResultComponent,
    // ProfileComponent,
    // ProfileSettingsComponent,
    // AccountInfoComponent,
    // PasswordChangeComponent,
    // DeactivateComponent,
    // MyOrdersComponent,
    // MyOrdersDetailsComponent,
    // MessagesComponent,
    // AddressesComponent,
    // AddAddressComponent,
    // FilterByPriceComponent,
    // FilterByBrandComponent,
    // FilterDeliveryComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    NgbModule,
    //DragScrollModule,
    NgxSliderModule,
    HttpClientModule,
    FlexLayoutModule,
    MaterialModule,
    //Ng2SearchPipeModule,
    BrowserAnimationsModule,
    NgxWebstorageModule.forRoot(),
    NgxSpinnerModule,
    TranslateModule.forRoot({
      defaultLanguage: 'al',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
    }),
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: 'infoMopbileAppDb',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
