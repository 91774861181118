import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-my-orders-details',
  templateUrl: './my-orders-details.component.html',
  styleUrls: ['./my-orders-details.component.css'],
  standalone: true,
  imports: [ CommonModule, TranslateModule, IonicModule, TitleCasePipe, UpperCasePipe ]
})
export class MyOrdersDetailsComponent implements OnInit {
  cartList = []
  constructor(private router: Router, private location: Location) { }

  ngOnInit(): void {
    const data = this.router.getCurrentNavigation();
    if(data?.extras) {}

    const shoppingCart = JSON.parse(localStorage.getItem('cart'));
    this.cartList = shoppingCart;
  }

  backClicked() {
    this.location.back();
  }

}
