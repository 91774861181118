import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  searchTextt = new BehaviorSubject('');
  selectedLanguage = new BehaviorSubject('');
  constructor() {}

  updateSearchText(text: string) {
    this.searchTextt.next(text);
  }
  
}
