// filter-data.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterDataService {
  private selectedCompanies: { company: { crmId: number } }[] = [];
  filterChanged = new Subject<{ company: { crmId: number } }[]>();
  refetchTrigger = new Subject<void>();

  setSelectedCompanies(companies: { company: { crmId: number } }[]) {
    this.selectedCompanies = companies;
    // this.filterChanged.next(this.selectedCompanies);
    // this.refetchTrigger.next();
  }

  getSelectedCompanies(): { company: { crmId: number } }[] {
    return this.selectedCompanies;
  }

  triggerRefetch() {
    this.refetchTrigger.next();
  }

  resetFilters() {
    this.selectedCompanies = [];

    this.filterChanged.next(this.selectedCompanies);
  }
  
}