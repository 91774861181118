import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SearchService } from 'src/app/search.service';
import { UserService } from '../services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { getPlaces } from 'src/app/interfaces/IUsers';
import { IonicModule } from '@ionic/angular';
import { RouterModule, Router } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';

const PASSWORD_MIN_LENGTH = 8;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
  standalone: true,
  imports: [ CommonModule, IonicModule, ReactiveFormsModule, TranslateModule, RouterModule, MatFormFieldModule, MatSelectModule ]
})
export class SignUpComponent implements OnInit {

  selectedLanguage: string;
  places:getPlaces[] = [];
  contactDataForm: UntypedFormGroup;
  mandatoryFields = [
    "firstName",
    "lastName",
    // "Address",
    // "addressNumber",
    // "bankName",
    // "IBAN",
    // "SWIFT",
    // "accountNumber",
  ];
  isFormSubmitted: boolean = false;
  registerSuccess: string = "";
  registerFail: string = "";
  viewPassword: boolean = false;
  viewConfirmPassword: boolean = false;
  
  signUpForm = new UntypedFormGroup({
    Name:new UntypedFormControl('',[Validators.required]),
    //Surname: new FormControl('',[Validators.required]),
    Email : new UntypedFormControl('', [Validators.required, Validators.email]),
    Phone: new UntypedFormControl('', [Validators.required]),    
    PlaceId : new UntypedFormControl('', Validators.required),
    Password : new UntypedFormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(PASSWORD_MIN_LENGTH),
      this.patternValidator(new RegExp("(?=.*[0-9])"), {
        requiresDigit: true
      }),
      this.patternValidator(new RegExp("(?=.*[A-Z])"), {
        requiresUppercase: true
      }),
      this.patternValidator(new RegExp("(?=.*[a-z])"), {
        requiresLowercase: true
      }),
      this.patternValidator(new RegExp("(?=.*[$#@^!%*?&])"), {
        requiresSpecialChars: true
      })
    ])
    ),
    ConfirmPassword : new UntypedFormControl('', Validators.required),
    // Address : new FormControl(''),
    // AddressNumber : new FormControl('',[Validators.required]),
    // BankName : new FormControl(''),
    // IBAN : new FormControl(''),
    // SWIFT : new FormControl(''),
    // BankAccount : new FormControl('')
    
  },
  {
    updateOn: 'blur',
  });


  constructor(private searchService: SearchService,private userService: UserService,private translate: TranslateService, private router: Router) { }

  ngOnInit(): void {
    if(localStorage.getItem('selectedLanguage') == null) {
      this.selectedLanguage = 'al';
    }else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }
    this.translate.use(this.selectedLanguage);
    this.translate.onLangChange.subscribe(x => {
      this.selectedLanguage = x.lang;
    });

    this.contactDataForm = new UntypedFormGroup({})
        this.mandatoryFields.forEach(value => this.contactDataForm.addControl(value, new UntypedFormControl("", Validators.required)));
        this.getAllPlaces(); 
        
  }

  getAllPlaces(){
    this.userService.getAllPlaces().subscribe((data)=>{
      this.places = data;
    })
  }  

  get currentLanguage(){
    return this.translate.currentLang;
}

  changeLanguage(event) {
    this.searchService.selectedLanguage.next(event.target.value);
    localStorage.setItem('selectedLanguage', event.target.value);
    this.selectedLanguage = event.target.value;
    this.translate.use(this.selectedLanguage);
    this.translate.setDefaultLang(this.selectedLanguage)    
  }

  register(data){
    this.isFormSubmitted = true;
    if(this.signUpForm.invalid) return;
    
    this.registerSuccess = "";
    this.registerFail = "";

    this.userService.register(data).subscribe(res =>{
      this.registerSuccess = "success"; //Gives the registerSuccess a text in order to make it visible in the UI
      this.router.navigate(['/email-verify']);
      this.signUpForm.reset() //Resets all the inputs

      Object.keys(this.signUpForm.controls).forEach(controlName => {
        this.signUpForm.get(controlName).markAsUntouched();
      });
      this.isFormSubmitted = false;
    },
    error=>{
      const theError = error as HttpErrorResponse;
      this.registerFail = theError.error.messa;
    })
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if the control value is empty return no error.
        return null;
      }

      // test the value of the control against the regexp supplied.
      const valid = regex.test(control.value);

      // if true, return no error, otherwise return the error object passed in the second parameter.
      return valid ? null : error;
    };
  }

  /**
   * Makes the first character to be uppercase
   * @param control //the controlName of the form to set the updatedText
   * @param currentValue // is the vslue that need to be made uppercase
   */
  makeFirstLetterUpperCase(control:string, currentValue){
    const formValue = currentValue.target.value; //retrieves the value
    const updatedText = formValue.charAt(0).toUpperCase() + formValue.slice(1); //makes the first character uppercase
    this.signUpForm.controls[control].setValue(updatedText) // sets the control value as the new updated task
  }

  passwordShowHide(){
    this.viewPassword = !this.viewPassword;
    
  }

  confirmPasswordShowHide(){
    this.viewConfirmPassword = !this.viewConfirmPassword;
  }


}
