<ion-content>
    <div class="flex-between-center">
        <div class="flex-no-end">
            <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
            <h6 class="font-16-550">Payment</h6>
        </div>
    </div>
    <div class="user-page">
        <form class="user-form" [formGroup]="paymentForm">
            <!-- Comment -->
            <mat-label>{{"Add Comment" | translate}}</mat-label>
            <input matInput formControlName="comment" placeholder="{{'Order Details' | translate}}"
              [style.--border-color]="paymentForm.controls['comment'].invalid && (paymentForm.controls['comment'].touched || paymentForm.controls['comment'].dirty || isFormSubmitted) ? '#F85E5E' : '#EAEAEA'" />
            <!-- Address -->
            <mat-label>{{"Address" | translate}}</mat-label>
            <input matInput formControlName="address" placeholder="{{'Tetovo, NMK, 1200' | translate}}"
              [style.--border-color]="paymentForm.controls['address'].invalid && (paymentForm.controls['address'].touched || paymentForm.controls['comment'].dirty || isFormSubmitted) ? '#F85E5E' : '#EAEAEA'" />
            <div class="address-container">
                <p class="font-16-blue" (click)="changeAddress()">change address</p>
            </div>
        </form>
        <ion-list class="w-100 pl-2">
            <ion-item-group>
              <ion-item>
                <div class="flex-between-w-100">
                    <p class="subtotal">{{"CART-PAGE.SUBTOTAL" | translate}}</p>
                    <p class="subtotal-price">{{getSubtotal()}} <span class="font-weight-light">{{'CURRENCY' | translate | uppercase}}</span></p>
                </div>
              </ion-item>
              <ion-item>
                <div class="flex-between-w-100">
                    <p class="subtotal">{{"CART-PAGE.DELIVERY" | translate}}</p>
                    <p class="subtotal-price">{{deliveryFee.toFixed(2)}} <span class="font-weight-light">{{'CURRENCY' | translate | uppercase}}</span></p>
                </div>
              </ion-item>
              <ion-item lines="none">
                <div class="flex-between-w-100">
                    <p class="grandtotal">{{"CART-PAGE.GRANDTOTAL" | translate}}</p>
                    <p class="grandtotal-price">{{getGrandTotal()}}<span class="font-weight-bold">{{'CURRENCY' | translate | uppercase}}</span></p>
                </div>
              </ion-item>
            </ion-item-group>
        </ion-list>
    </div>
      <ion-row class="mx-2">
        <ion-col size="12">
          <ion-button size="large" expand="full" shape="round" color="warning" (click)="openPaymentModal(paymentForm.value)">
            <span class="btn-text">Confirm</span>
          </ion-button>  
        </ion-col>
      </ion-row>
</ion-content>    
