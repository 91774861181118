import { Component, CUSTOM_ELEMENTS_SCHEMA, Injectable, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RouterModule, ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';
import { switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProductCategories } from 'src/app/interfaces/IProductCategories';
import { IProduct } from 'src/app/interfaces/IProduct';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateNamePipe } from '../Pipes/translate-name.pipe';

import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperDirective } from '../../../lib/swiper.directive';

@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.css'],
  standalone: true,
  //encapsulation: ViewEncapsulation.None,
  imports: [ RouterModule, TranslateModule, IonicModule, CommonModule, TranslateNamePipe, SwiperDirective ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
@Injectable()
export class ProductdetailsComponent implements OnInit,OnDestroy {
  @ViewChild('swiperCon', { static: true }) swiperContainer: any;

  products: IProduct[] = [];
  imgpath = environment.IMG_PATH;
  conomePath = environment.API_URL;
  id: number;
  isFavorite: boolean = false;
  categoryId:number;
  relatedProducts: IProductCategories[] = [];
  subscription: Subscription;
  mobileCategories: IProductCategories;
  allproducts: IProduct[] = [];
  filteredCategory;
  itemsNr: number = 1;
  cartNr: number = 0;
  orderedProducts = [];
  cartListOne = [];
  readMore: boolean = true;

  constructor(
    public dataservice: DataService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
  companyId:number;
  async ngOnInit() {
    this.spinner.show();
    //get productId and categoryId from URL
    await this.route.paramMap
      .pipe(
        switchMap((params) => {
          this.id = +params.get('id');
          this.categoryId=+params.get('categoryId');
          this.companyId=+params.get('companyId')
          return this.getProduct(+params.get('id'));
        })
      )
      .subscribe(
        (data: IProduct[]) => {
          this.products = data;
          this.isprodfav();
          this.onSelectedProduct();
        },
        (error) => {
          console.log(error);
        }
      );
    //get all products
    this.dataservice.getAllProducts().subscribe((formated: IProduct[]) => {
      this.allproducts = formated;
      const index = this.allproducts.findIndex(obj => obj.id == this.id);
      this.goToSlide(index);
    });

    this.dataservice.shoppingCartSize$.subscribe(listNr => {
      this.cartNr = listNr;
    })
    const cartItems = JSON.parse(localStorage.getItem('cart'));
    this.cartNr = Array.isArray(cartItems) ? cartItems.length : 0;
    this.spinner.hide();
  }

  addToCart(e, product, index) {
    e.stopPropagation();
    const productExistInCart = this.orderedProducts.find(prod => prod.id === product.id);
    if(!productExistInCart) {
      product["quantity"] = this.itemsNr;
      this.orderedProducts.push(product);
     }
    localStorage.setItem('cart', JSON.stringify(this.orderedProducts));
    this.dataservice.getCartListNumber(this.orderedProducts.length);
    this.cartNr = this.orderedProducts.length;
  }


  getProduct(id): Observable<IProduct[]> {
    return this.dataservice.getProductsbyID(id);
  }
  //get products which have category and shows related products
  onSelectedProduct(){
    this.subscription = this.dataservice.getProductCategories().subscribe((data: IProductCategories[]) =>{
      if (this.categoryId === 0 && this.companyId === 0){
        this.filteredCategory=this.allproducts;

      }
      else if(this.categoryId !== 0 && this.companyId === 0)
      {
        this.filteredCategory=data.filter(x=> x.mobileCategoryId === this.categoryId)

      }
      else if(this.categoryId === 0 && this.companyId !== 0)
      {
        this.filteredCategory=data.filter(x=> x.product.companyId === this.companyId)
      }
      else if(this.categoryId !== 0 && this.companyId!==0)
      {
        this.filteredCategory=data.filter(x=> x.product.companyId === this.companyId && x.mobileCategoryId === this.categoryId)
      }
        this.mobileCategories = data.find(x=> x.product.productId == this.products[0].id);
        //if selceted mobile category has another products shows on related products
        if (this.mobileCategories){
          this.relatedProducts = data.filter(x=> x.mobileCategoryId == this.mobileCategories.mobileCategoryId && x.id != this.mobileCategories.id);
        }
      });
  }
  //go to the next product
  goNext() {
    //checks if categoryId which we get from URL is equal 0 and show all products
    if(this.categoryId === 0 && this.companyId === 0){
      let item: IProduct =this.filteredCategory.find((x) => x.id === this.products[0].id);

      let index: number = this.filteredCategory.indexOf(item);
      //checks latest product and show that
      if (index === this.filteredCategory.length - 1) {
        this.router.navigate(['/productdetail', this.filteredCategory[0].id, this.categoryId, this.companyId]);
      }
      //checks for next product
      else {
        let newindex: number = index + 1;
        let newprod = this.filteredCategory[newindex];
        this.router.navigate(['/productdetail', newprod.id, this.categoryId, this.companyId]);
      }
    }
    //checks if categoryId which we get from URL has value, show products for current categoryID
    else{
      let item: IProduct =this.filteredCategory.find((x) => x.product.productId === this.products[0].id);
      let index: number = this.filteredCategory.indexOf(item);
      //checks latest product and show that
      if (index === this.filteredCategory.length - 1) {
        this.router.navigate(['/productdetail', this.filteredCategory[0].product.productId, this.categoryId, this.companyId]);
      } else {
        //checks for next product
        let newindex: number = index + 1;
        let newprod = this.filteredCategory[newindex];
        this.router.navigate(['/productdetail', newprod.product.productId, this.categoryId, this.companyId]);
      }
    }
  }
 //go to the previous product
  goBack() {
    //checks if categoryId which we get from URL is equal 0 and show all products
    if(this.categoryId === 0 && this.companyId === 0){
      let item: IProduct = this.filteredCategory.find((x) => x.id === this.products[0].id);
      let index: number = this.filteredCategory.indexOf(item);
      //checks latest product and show that
      if (index === 0) {
        let lastitem = this.filteredCategory[this.filteredCategory.length - 1];
        this.router.navigate(['/productdetail', lastitem.id, this.categoryId, this.companyId]);

      }
      //checks for previous product
      else {
        let newindex: number = index - 1;
        let newprod = this.filteredCategory[newindex];
        this.router.navigate(['/productdetail', newprod.id, this.categoryId, this.companyId]);
      }
    }
    //checks if categoryId which we get from URL has value, show products for current categoryID
    else{
      let item: IProduct = this.filteredCategory.find((x) => x.product.productId === this.products[0].id);
      let index: number = this.filteredCategory.indexOf(item);
      //checks if is first product from DB and show the last product like loop
      if (index === 0) {
        let lastitem = this.filteredCategory[this.filteredCategory.length - 1];
        this.router.navigate(['/productdetail', lastitem.product.productId, this.categoryId,  this.companyId]);
      }
      //checks for previous product
      else {
        let newindex: number = index - 1;
        let newprod = this.filteredCategory[newindex];
        this.router.navigate(['/productdetail', newprod.product.productId, this.categoryId,  this.companyId]);
      }
    }
  }
  //add product to favorite save the product on localStorage
  async AddToFav() {
    let products: IProduct[] = JSON.parse(localStorage.getItem('fav'));
    if (products != null) {
      let item: IProduct = products.find((x) => x.id === this.products[0].id);
      if (item != null) {
        this.isFavorite = false;
        const index: number = products.indexOf(item);
        products.splice(index, 1);
        localStorage.setItem('fav', JSON.stringify(products));
      } else {
        products.push(this.products[0]);
        this.isFavorite = true;
        localStorage.setItem('fav', JSON.stringify(products));
      }
    } else {
      this.isFavorite = true;
      localStorage.setItem('fav', JSON.stringify(this.products));
    }
  }

  //cheks if product is on favorite list
  isprodfav() {
    try {
      const product: IProduct = JSON.parse(localStorage.getItem('fav')).find(
        (x) => x.id === this.products[0].id
      );
      if (product) {
        if (product.id === this.products[0].id) {
          this.isFavorite = true;
        }
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  onSlideChanged() {
    this.readMore = true;
    this.itemsNr = 1;
  }

  goToSlide(id: number) {
    //this.slides.slideTo(id);
    this.swiperContainer.nativeElement.swiper.activeIndex = id;
  }

  backClicked() {
    this.location.back();
  }

  addQuantity() {
    this.itemsNr = this.itemsNr + 1;
  }

  removeQuantity() {
    if(this.itemsNr != 1) {
      this.itemsNr =  this.itemsNr -1;
    }
  }

  showMore() {
    this.readMore = true;
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
}
