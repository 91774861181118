import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/data.service';
import { CategoryWithImage } from 'src/app/interfaces/IProductCategories';
import { SearchService } from 'src/app/search.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AllCategoriesModalComponent } from '../all-categories-modal/all-categories-modal.component';
import { IProductWithLatAndLon } from 'src/app/interfaces/IProduct';
import { Position, Geolocation } from '@capacitor/geolocation';
import * as geolib from 'geolib';
import { FilterService } from 'src/app/filter.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IonicModule, IonSegment, ModalController } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-product-categories-list',
  templateUrl: './product-categories-list.component.html',
  styleUrls: ['./product-categories-list.component.css'],
  standalone: true,
  imports: [ CommonModule, IonicModule, RouterModule, ScrollingModule, NgxSpinnerModule ]
})
export class ProductCategoriesListComponent implements OnInit {

  @ViewChild('segment', { static: false }) segment: IonSegment;

  productCategories: CategoryWithImage[];
  productCategoriesAll: CategoryWithImage[];

  // productCategories: IProductCategories[];
  searchText;
  imgpath = environment.IMG_PATH;
  tabSelected: string = '';
  allproducts: IProductWithLatAndLon[] = [];
  loadingCounter = 0;


  constructor(
    private dataService: DataService,
    private searchService: SearchService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private filterService: FilterService,

    ) {}

    async ngOnInit(): Promise<void> {

    this.searchService.searchTextt.subscribe((val) => {
      this.searchText = val;
    });
    await this.getCategories();
    this.checkSelectedCategory(); 
  }

  checkSelectedCategory() {
    const selectedCategory = this.filterService.getSelectedCategory(); 
  
    if (selectedCategory) {
      const categoryExists = this.productCategories.some(category => category.nameSQ === selectedCategory);
      
      if (categoryExists) {
        this.tabSelected = selectedCategory;
        // this.fetchProductsByCategory(this.tabSelected);

      this.segment.value = this.tabSelected;

      this.onSegmentChange({ detail: { value: this.tabSelected } });
      } else {
        this.filterService.setSelectedCategory(null); 
        this.selectDefaultCategory();
      }
    } else {
      this.selectDefaultCategory(); 
    }
  }

  selectDefaultCategory() {
    if (this.productCategories.length > 0) {
      this.tabSelected = this.productCategories[0].nameSQ;
      this.filterService.setSelectedCategory(this.tabSelected);
  
      this.segment.value = this.tabSelected; 
  
      this.onSegmentChange({ detail: { value: this.tabSelected } });
    }
  }
  
  
  fetchProductsByCategory(categoryName: string) {
    this.showSpinner();

    const selectedCategory = this.productCategories.find(category => category.nameSQ === categoryName);
    const selectedCategoryName = selectedCategory ? selectedCategory.nameSQ : '';
  
    const filterRequest = {
      companyIds: null,
      categoryName: categoryName,
      searchText: null
    };
  
    this.dataService.getAllProductsFiltered(filterRequest, null).subscribe(
      (filteredProducts: IProductWithLatAndLon[]) => {
        this.allproducts = [];
        const radius = JSON.parse(localStorage.getItem('value')) * 1000;
  
        Geolocation.getCurrentPosition().then((cordinates) => {
          const latitude = cordinates.coords.latitude;
          const longitude = cordinates.coords.longitude;
  
          filteredProducts.forEach((product) => {
            const companyLatitude: number = parseFloat(product.companyLatitude);
            const companyLongitude: number = parseFloat(product.companyLongitude);
  
            const withinRadius = geolib.isPointWithinRadius(
              { latitude: latitude, longitude: longitude },
              { latitude: companyLatitude, longitude: companyLongitude },
              radius
            );
  
            if (withinRadius) {
              const minVal = this.filterService.getMinValue();
              const maxVal = this.filterService.getMaxValue();
              const productPrice = product.productDetails[0].productDetailSellingPriceWithVat;
  
              if (
                (minVal === undefined && maxVal === undefined) ||
                (minVal === 0 && maxVal === 0) ||
                (minVal !== undefined && minVal !== 0 && (maxVal === 0 || maxVal === undefined) && productPrice >= minVal) ||
                (productPrice >= minVal && productPrice <= maxVal)
              ) {
                this.allproducts.push(product);
              }
            }
          });
        });
      },
      (error) => {
        console.error("Error fetching filtered products:", error);
      },
      () => {
        this.hideSpinner();
      }
    );
  }
  
  showSpinner() {
    this.loadingCounter++;
    this.spinner.show();
  }
  
  hideSpinner() {
    this.loadingCounter--;
    if (this.loadingCounter <= 0) {
      this.loadingCounter = 0;
      this.spinner.hide();
    }
  }
  

  get currentLanguage(){
    return this.translate.currentLang;
  
  }

  async getCategories(): Promise<void> {
    this.showSpinner();
    try {
      const data: CategoryWithImage[] = await this.dataService.getCategories().toPromise();
      this.productCategories = data.slice(0, 3);
    } catch (error) {
      this.spinner.hide();
      Swal.fire({
        text: 'Not Found',
        icon: 'error',
        confirmButtonText: 'Close',
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/productcategories']);
        }
      });
    } finally {
      this.hideSpinner();
    }
  }

  onSegmentChange(event): void {
    this.tabSelected = event.detail.value;
  
    this.filterService.setSelectedCategory(this.tabSelected);
    this.fetchProductsByCategory(this.tabSelected);
  }

  async openAllCategoreisModal() {
    const modal = await this.modalCtrl.create({
      component: AllCategoriesModalComponent,
    });
    await modal.present();
  
    const { data } = await modal.onDidDismiss();
  
    if (data && data.selectedCategory) {
      this.tabSelected = data.selectedCategory; 
  
      this.segment.value = this.tabSelected; 
  
      this.filterService.setSelectedCategory(this.tabSelected); 
  
      this.fetchProductsByCategory(this.tabSelected); 
    } else {
      // console.log("No change in category selection");
    }
  }

}
