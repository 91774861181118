import { Component, Inject, OnInit } from '@angular/core';
//import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SearchService } from 'src/app/search.service';
import { getUser } from 'src/app/interfaces/IUsers';
import { UserService } from './services/user.service';
import { ActivatedRoute } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  standalone: true,
  imports: [ IonicModule, ReactiveFormsModule, TranslateModule, RouterModule, MatFormFieldModule, HeaderComponent ]
})
export class UserComponent implements OnInit {
  selectedLanguage: string;
  userId: number = parseInt(localStorage.getItem("userId"), 10);
  name:string;
  surname:string;
  email:string;
  address:string;

  

  constructor(
    private search: SearchService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private userService:UserService
    ) { }

  ngOnInit(): void {


    if (localStorage.getItem('selectedLanguage') == null) {
      this.selectedLanguage = 'al';
    } else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }
this.getUser();
  
    
  }

  getUser(): void {
    if (this.userId !== 0) {
      this.userService.getUser(this.userId).subscribe(
        (data: getUser) => {
          const user = data[0];
  
          this.name = user.name;
          this.surname = user.surname;
          this.email = user.email;
          this.address = user.address;
        },
        (error) => {
          console.error('Error fetching user data', error);
        }
      );
    } else {
      
      this.resetUserData();
    }
  }
  
   resetUserData(): void {
    this.name = '';
    this.surname = '';
    this.email = '';
    this.address = '';
  }
  

  
  


  changeLanguage(event) {
    this.search.selectedLanguage.next(event.value);
    localStorage.setItem('selectedLanguage', event.value);
    this.selectedLanguage = event.value;
    this.translate.use(this.selectedLanguage);
    this.translate.setDefaultLang(this.selectedLanguage)
  }
}
