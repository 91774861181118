import { Component, OnInit } from '@angular/core';
import { RouterModule, Router} from '@angular/router';
import { CommonModule } from '@angular/common';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.css'],
  standalone: true,
  imports: [ CommonModule, RouterModule ]
})
export class PaymentResultComponent {
  isSuccess: boolean = true;
  cashOrCard: string = 'card';
  orderCashSuccess: boolean = false;

  constructor(private router: Router, private dataservice: DataService) { }

  ionViewDidEnter() {
    this.dataservice.cashCard$.subscribe(result => this.cashOrCard = result);
    localStorage.setItem('cart', JSON.stringify([]));
  }
}