import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-filter-delivery',
  templateUrl: './filter-delivery.component.html',
  styleUrls: ['./filter-delivery.component.css'],
  standalone: true,
  imports: [ IonicModule ]
})
export class FilterDeliveryComponent implements OnInit {
  options: string = '';

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  deliveryOptions(event) {
    this.options = event.detail.value;
    console.log('options', this.options);
  }

  backClicked() {
    this.router.navigate(['/filter', { delivery: this.options }]);
  }
}
