<app-header></app-header>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p class="spinner-text">Loading...</p>
</ngx-spinner>
<!--slider km-->
<div>
  <div class="slider">
    <ion-range aria-label="Custom range" [min]="options.floor - 1" [max]="options.ceil" [step]="options.step" [pin]="true"
            [(ngModel)]="value" (ionChange)="detectchange($event.detail.value)"><ion-text slot="start">0</ion-text>
            <ion-text slot="end">200</ion-text>
        </ion-range>
    <div class="text">
      <p class="range-line-text">|</p>
      <p class="range-text">{{'RANGE_IN_KM' | translate}}</p>
    </div>
  </div>

  <!--search-->
  <div class="ifilter" fxLayout="row" fxLayoutWrap>
    <div class="sliders-icon" routerLink="/filter"></div>
      <button class="scroll-button active"
        *ngFor="let cat of category">
        <span class="sp">{{cat | translateName}}</span>
      </button>
  </div>

  <!-- Company -->
  <cdk-virtual-scroll-viewport [itemSize]="1" class="example-viewport">
    <div class="row selected-company">
      <div class="col-6 p-8" *ngFor="let comp of company">
        <mat-card [routerLink]="['/companydetail', comp.id]" class="cardd">
          <div fxLayoutAlign="center center" class="company-detail">
            <img mat-card-image  class="company-image" src="{{ imgpath + comp.image }}" />
          </div>
          <mat-card-title class="TITLE">
            {{ comp | translateName }}
          </mat-card-title>
          <mat-card-content>
            <span class="company-content">
              <p class="content-p">
                {{'CATEGORY' | translate}}
              </p>
            </span>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>

</div>
