import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-all-categories-modal',
  templateUrl: './all-categories-modal.component.html',
  styleUrls: ['./all-categories-modal.component.css'],
  standalone: true,
  imports: [ CommonModule, IonicModule ]
})
export class AllCategoriesModalComponent implements OnInit {
  productCategories = [];
  constructor(private dataService: DataService, private modalCtrl: ModalController) { }

  ngOnInit(): void {
    this.dataService.getCategories().subscribe((data: any[]) => {
      this.productCategories = data;
    })
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
}
