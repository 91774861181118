import { Component, Input, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { Router} from '@angular/router';
import { DataService } from 'src/app/data.service';
import { OrderClientConome, OrderConome, OrderDetailConome } from 'src/app/interfaces/IOrder';
import { UserService } from '../user/services/user.service';
import { getUser } from 'src/app/interfaces/IUsers';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.css'],
  standalone: true,
  imports: [ IonicModule ]
})
export class PaymentModalComponent implements OnInit {
  @Input() data: any;
  selectedMethod: string = 'card';
  cartList = [];
  userId: number = parseInt(localStorage.getItem("userId"), 10);


  paymentTypes = {
    cash: { id: 0, name: 'Cash' },
    card: { id: 1, name: 'Card' },
  };

  currentLanguage: string;
  name: any;
  surname: any;
  email: any;
  address: any;

  totalPrice = 0;
  paymentWithCard = false;
  userID: number;

  constructor(
    private modalCtrl: ModalController, 
    private router: Router, 
    private dataService: DataService, 
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.currentLanguage = localStorage.getItem('selectedLanguage');
    const shoppingCart = JSON.parse(localStorage.getItem('cart'));
    this.cartList = shoppingCart || [];
  }

  cashOrCard(event) {
    this.selectedMethod = event.detail.value;
  }

  paymentMethod() {
    if(this.selectedMethod == 'card') {
      this.data['method'] = this.selectedMethod;
      this.createOrder(this.paymentTypes.card.id);
      this.router.navigate(['/payment-result'], this.data);
    } else {
      this.data['method'] = this.selectedMethod;
      this.createOrder(this.paymentTypes.cash.id);
      this.router.navigate(['/payment-result'], this.data);
    }
    // this.dataService.paymentMethod(this.selectedMethod);
    // this.router.navigate(['/payment-result']);
    this.dismiss();
  }
  
  getUser(): void {
    if (this.userId !== 0) {
      this.userService.getUser(this.userId).subscribe(
        (data: getUser) => {
          const user = data[0];
          this.userId = user.id;
          this.name = user.name;
          this.surname = user.surname;
          this.email = user.email;
          this.address = user.address;
        },
        (error) => {
          console.error('Error fetching user data', error);
        }
      );
    } else {
      
      // this.resetUserData();
    }
  }
  

  createOrder(paymentID: number) {
    // const orderDetails: OrderDetailConome[] = this.cartList.map((product) => ({
    //   ModUser: product.modUser,
    //   ProductID: product.id,
    //   ProductDetailID: product.productDetails[0].id,
    //   OrderID: 1,
    //   Qt: product.quantity,
    //   SellingPriceWithVat: product.productDetails[0].sellingPriceWithVat,
    //   SellingPriceNoVat: product.productDetails[0].sellingPriceNoVat,
    //   buyingPriceWithVat: product.productDetails[0].buyingPriceWithVat,
    //   VatID: product.productDetails[0].vatId,
    //   Total: product.productDetails[0].sellingPriceWithVat * product.quantity,
    //   ProductName: this.currentLanguage === 'sq' ? product.nameSQ : product.nameMK
    // }));
    // let orderClientConome: OrderClientConome | null = null;
    // orderClientConome = {
    //   Name: this.name,
    //   LastName: this.surname,
    //   Email: this.email,
    //   Phone: "777460",
    //   Address: this.address
    // }

    // const companyID = parseInt(this.cookieService.get("companyID"))
    // const order: OrderConome = {
    //   CompanyID: companyID,
    //   ClientConomeID: this.userID,
    //   DateOrder: new Date(),
    //   Total: Number(this.totalPrice),
    //   OrderStatus: 1,
    //   PaymentStatus: 1,
    //   PaymentType: paymentID,
    //   OrderDetails: orderDetails,
    //   isDelivery: false,
    //   OrderClient: orderClientConome,
    //   SaleID: 1
    // }

    // this.dataService.createOrder(order).subscribe({
    //   next: (res) => {
    //     const orderId = res.orderId;

    //     if (paymentID === 1) {
    //       this.paymentWithCard = true;
    //       const url = window.location.href;
    //       const baseUrl = url.replace('/cart', '');
    //       this.dataService.checkOut(this.totalPrice, res.orderId, baseUrl).subscribe((res: any) => {
    //         // this.PortalUrl = res.portalUrl;
    //         // this.Clientid = res.clientid;
    //         // this.Storetype = res.storetype;
    //         // this.Trantype = res.trantype;
    //         // this.Instalment = res.instalment;
    //         // this.amount = res.amount.toFixed(2);
    //         // this.Currency = res.currency;
    //         // this.Oid = res.oid;
    //         // this.OkUrl = res.okUrl;
    //         // this.FailUrl = res.failUrl;
    //         // this.CallbackUrl = res.callbackUrl;
    //         // this.Lang = res.lang;
    //         // this.Encoding = res.encoding;
    //         // this.Refreshtime = res.refreshtime;
    //         // this.Rnd = res.rnd;
    //         // this.Hash = res.hash;
    //         requestAnimationFrame(() => {
    //           // const paymentFormView = this.paymentFormView?.nativeElement;
    //           // this.submitForm(paymentFormView);
    //         });
    //       });
    //     }
    //     // this.sendEmail();
    //     // if(!this.isFromBuyNow){
    //     //   if (this.userId === '') {
    //     //     this.cookieService.delete(LOCAL_STORAGE.PRODUCTS);
    //     //     this.cartService.updateCartProducts([])
    //     //   } else {
    //     //     this.cmsService.removeAllProductsFromCartByUserId(this.userId).subscribe({
    //     //       next: (res) => {
    //     //         this.cartService.updateCartProducts([])
    //     //       }
    //     //     });
    //     //   }
    //     // }
    //     this.router.navigate(['/products'])

    //   },
    //   error: (err) => {
    //     console.error(err);
    //   }
    // })
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
}
