import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '../../search.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  standalone: true,
  imports: [ TranslateModule, FormsModule, IonicModule, CommonModule ]
})
export class SearchComponent implements OnInit {
  searchText;
  showCancel: boolean = false;

  constructor(private searchService: SearchService, private router: Router) {}
  @ViewChild('searchInput') searchInput!: ElementRef;

  ngOnInit(): void {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.showCancel = this.router.url === '/products';
       
      if (this.showCancel) {
        this.initializeSearchText();
        this.focusOnSearchInput();
      }
      
    });
  }

  initializeSearchText() {
    this.searchService.searchTextt.subscribe((text) => {
      this.searchText = text;
    });
  }

  focusOnSearchInput() {
   if (this.searchInput) {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 100);
  }
}

  changeFilter(event) {
    this.searchText = event.target.value;
    this.searchService.searchTextt.next(this.searchText);

  }

  onFocus() {
    if (this.router.url !== '/products') {
      this.router.navigate(['/products']);
    }
  }

  cancelPresed() {
    this.searchText = '';
    this.searchService.searchTextt.next('');
    this.router.navigate(['/']);
  }
}
