<ion-content>
    <div class="flex-between-center">
        <div class="flex-no-end">
            <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
            <h6 class="font-16-550">Password</h6>
        </div>
    </div>
    <hr class="custom-hr">
    <div class="user-page">
        <form class="user-form" [formGroup]="changePasswordForm">
            <!-- Current Password -->
            <div class="d-flex-between-center">
              <mat-label>{{"Current Password" | translate}}</mat-label>
              <a class="forget-text" [routerLink]="['/forgot-password']">{{"FORGOT-YOUR-PASSWORD" | translate}}</a>
            </div>
            <div class="password">
              <input matInput [type]="currentPassword ? 'text' : 'password'" formControlName="currentPassword" placeholder="{{'**********'}}"
                [style.--border-color]="changePasswordForm.controls['currentPassword'].invalid && (changePasswordForm.controls['currentPassword'].touched || changePasswordForm.controls['currentPassword'].dirty || isFormSubmitted) ? '#F85E5E' : '#EAEAEA'" />
              <img class="eye-icon" src="../../assets/images/new/icons/eye.png" height="25" *ngIf="!currentPassword" (click)="currentPasswordShowHide()" />
              <img class="eye-off-icon" src="../../assets/images/new/icons/eye-off.png" height="25" *ngIf="currentPassword" (click)="currentPasswordShowHide()" />
            </div>
            <!-- New Password -->
            <mat-label>{{"New Pasword" | translate}}</mat-label>
            <div class="password">
              <input matInput  [type]="viewPassword ? 'text' : 'password'" formControlName="newPassword" placeholder="{{'Create Password' | translate}}" 
                [style.--border-color]="changePasswordForm.controls['newPassword'].invalid && (changePasswordForm.controls['newPassword'].touched || changePasswordForm.controls['newPassword'].dirty || isFormSubmitted) ? '#F85E5E' : '#EAEAEA'"/>
                <img class="eye-icon" src="../../assets/images/new/icons/eye.png" height="25" *ngIf="!viewPassword" (click)="passwordShowHide()" />
                <img class="eye-off-icon" src="../../assets/images/new/icons/eye-off.png" height="25" *ngIf="viewPassword" (click)="passwordShowHide()" />  
            </div>
            <!-- Confirm Password -->
            <mat-label>{{"Confirm Password" | translate}}</mat-label>
            <div class="password">
              <input matInput [type]="viewConfirmPassword ? 'text' : 'password'" formControlName="confirmNewPassword" placeholder="{{'Create Password' | translate}}"
                [style.--border-color]="changePasswordForm.controls['confirmNewPassword'].invalid && (changePasswordForm.controls['confirmNewPassword'].touched || changePasswordForm.controls['confirmNewPassword'].dirty || isFormSubmitted) ? '#F85E5E' : '#EAEAEA'"/>
                <img class="eye-icon" src="../../assets/images/new/icons/eye.png" height="25" *ngIf="!viewConfirmPassword" (click)="confirmPasswordShowHide()" />
                <img class="eye-off-icon" src="../../assets/images/new/icons/eye-off.png" height="25" *ngIf="viewConfirmPassword" (click)="confirmPasswordShowHide()" />
              </div>
            <span *ngIf="changePasswordForm.controls['confirmNewPassword'].invalid && (changePasswordForm.controls['confirmNewPassword'].touched || isFormSubmitted)"
              class="text-danger">{{'SIGNUP.NOT_MATCH_PASSWORDS' | translate}}</span>
        </form>
    </div>
      <ion-row class="mx-2">
        <ion-col size="12">
          <ion-button size="large" expand="full" shape="round" color="warning" (click)="changePassword(changePasswordForm.value)">
            <span class="btn-text">Change Password</span>
          </ion-button>  
        </ion-col>
      </ion-row>
</ion-content>
