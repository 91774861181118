import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caseInsensitiveSort',
  standalone: true
})
export class CaseInsensitiveSortPipe implements PipeTransform {
  transform(array: any[], field: string, order: string): any[] {
    if (!array || array.length <= 1) {
      return array;
    }
    const isAscending = order === 'asc';
    
    return array.sort((a: any, b: any) => {
      const aValue = this.getPropertyValue(a, field);
      const bValue = this.getPropertyValue(b, field);

      // Check if the values are not undefined or null before calling toLowerCase()
      const aValueLower = aValue ? aValue.toLowerCase() : '';
      const bValueLower = bValue ? bValue.toLowerCase() : '';

      if (aValueLower < bValueLower) return isAscending ? -1 : 1;
      if (aValueLower > bValueLower) return isAscending ? 1 : -1;

      return 0;
    });
  }

  private getPropertyValue(obj: any, field: string): any {
    // Ensure we're accessing the right property from the correct object
    return obj[field] || obj?.company?.[field]; // Safe navigation operator
  }
}
