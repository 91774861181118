<ion-content>
    <div class="flex-between-center">
        <div class="flex-no-end">
            <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
            <h6 class="font-16-550">My Orders</h6>
        </div>
    </div>
    <ion-list>
        <h6 class="font-16">{{"Shipped" | titlecase}}</h6>
        <ion-item *ngFor="let cart of cartList; let i = index">
            <ion-grid class="mt-10">
                <ion-row (click)="goToDetails(cart)">
                    <ion-col size="4">
                        <div class="img-bg">
                            <img class="p-2" src="../../../../assets/images/favorite.png"/>
                        </div>
                    </ion-col>
                    <ion-col class="pb-0">
                        <ion-row class="mt-3">
                            <ion-col size="10">
                                <p class="delivered-text">Estimated delivery date:</p>
                            </ion-col>
                            <ion-col size="2">
                                <ion-icon name="arrow-forward" class="arrow-icon"></ion-icon>
                            </ion-col>
                        </ion-row>
                        <ion-row class="l-height-02">
                            <ion-col>
                                <p class="delivered-text">04.05.2024</p>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col size="7">
                                <p class="price">1023.00 DEN</p>
                            </ion-col>
                            <ion-col>
                                <p class="font-16-blue">track order</p>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-item>
    </ion-list>
</ion-content>
