<ion-toolbar>
    <ion-buttons slot="end">
      <ion-button class="close-position" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-content>
    <div class="user-page">
        <form class="user-form" [formGroup]="addressForm">
            <!-- Country -->
            <mat-label>{{"Country" | translate}}</mat-label>
            <input matInput formControlName="country" placeholder="{{'Order Details' | translate}}"
              [style.--border-color]="addressForm.controls['country'].invalid && (addressForm.controls['country'].touched || addressForm.controls['country'].dirty || isFormSubmitted) ? '#F85E5E' : '#EAEAEA'" />
            <!-- City -->
            <mat-label>{{"City" | translate}}</mat-label>
            <input matInput formControlName="city" placeholder="{{'Tetovo, NMK, 1200' | translate}}"
              [style.--border-color]="addressForm.controls['city'].invalid && (addressForm.controls['city'].touched || addressForm.controls['city'].dirty || isFormSubmitted) ? '#F85E5E' : '#EAEAEA'" />
            <!-- ZIP -->
            <mat-label>{{"ZIP" | translate}}</mat-label>
            <input matInput formControlName="zip" placeholder="{{'Tetovo, NMK, 1200' | translate}}"
              [style.--border-color]="addressForm.controls['zip'].invalid && (addressForm.controls['zip'].touched || addressForm.controls['zip'].dirty || isFormSubmitted) ? '#F85E5E' : '#EAEAEA'" />
            <!-- City -->
            <mat-label>{{"Street" | translate}}</mat-label>
            <input matInput formControlName="street" placeholder="{{'Tetovo, NMK, 1200' | translate}}"
              [style.--border-color]="addressForm.controls['street'].invalid && (addressForm.controls['street'].touched || addressForm.controls['street'].dirty || isFormSubmitted) ? '#F85E5E' : '#EAEAEA'" />    
        </form>
    </div>
      <ion-row class="mx-2">
        <ion-col size="12">
          <ion-button size="large" expand="full" shape="round" color="warning" (click)="changeAddress(addressForm.value)">
            <span class="btn-text">Confirm Changes</span>
          </ion-button>  
        </ion-col>
      </ion-row>
</ion-content>
