import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-change-address-modal',
  templateUrl: './change-address-modal.component.html',
  styleUrls: ['./change-address-modal.component.css'],
  standalone: true,
  imports: [ IonicModule, ReactiveFormsModule, TranslateModule, MatFormFieldModule ]
})
export class ChangeAddressModalComponent implements OnInit {

  isFormSubmitted: boolean = false;
  addressForm = new UntypedFormGroup({
    country: new UntypedFormControl(''),
    city: new UntypedFormControl('', Validators.required),
    zip: new UntypedFormControl(''),
    street: new UntypedFormControl('', Validators.required)
  })

  constructor(private modalCtrl: ModalController) { }

  ngOnInit(): void {
  }

  changeAddress(data) {
    this.modalCtrl.dismiss(data);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
