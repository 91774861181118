import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { ProductsComponent } from '../products/products.component';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css'],
  standalone: true,
  imports: [ HeaderComponent, ProductsComponent ]
})
export class BodyComponent implements OnInit {
  constructor() {}

  ngOnInit() {
  
  }
}
