
<app-header></app-header>
<ion-content>
<div class="user-page">

  <img class="user-icon" src="../../../assets/images/person.svg" />
  <form class="user-form">
    <mat-form-field appearance="outline" class="user-form-field">
      <mat-label>{{"NAME" | translate}}</mat-label>
      <input matInput placeholder="{{'NAME' | translate}}" value="{{name}}" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline" class="user-form-field">
      <mat-label>{{"SURNAME" | translate}}</mat-label>
      <input matInput placeholder="Surname" value="{{surname}}" readonly>
    </mat-form-field>

    <mat-form-field class="user-form-field" appearance="outline">
      <mat-label>{{"EMAIL" | translate}}</mat-label>
      <input matInput placeholder="Email" value="{{email}}" readonly>
    </mat-form-field>

    <mat-form-field class="user-form-field" appearance="outline">
      <mat-label>{{"ADDRESS" | translate}}</mat-label>
      <input matInput placeholder="Address" value="{{address}}" readonly>
    </mat-form-field>
  </form>

</div>
</ion-content>