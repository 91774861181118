import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-filter-by-price',
  templateUrl: './filter-by-price.component.html',
  styleUrls: ['./filter-by-price.component.css'],
  standalone: true,
  imports: [ IonicModule ]
})
export class FilterByPriceComponent implements OnInit {
  filterPrice: string = 'normal';

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  lowestOrHighest(event) {
    this.filterPrice = event.detail.value;
    localStorage.setItem('filterPrice', this.filterPrice);
  }

  backClicked() {
    this.router.navigate(['/filter']);
  }
}
