<ion-content>
    <div class="flex-between-center">
        <div class="flex-no-end">
            <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
            <h6 class="font-16-550">My Orders</h6>
        </div>
    </div>

    <!-- <h2 class="d-flex justify-content-center">{{"CART_EMPTY" | translate}}</h2> -->
<ion-card>
    <ion-card-header>
        <h6 class="order-nr">{{"Order Number" | titlecase}}</h6>
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <ion-grid *ngFor="let cart of cartList; let i = index">
            <ion-row>
              <ion-col size="4">
                <div class="img-bg">
                </div>
              </ion-col>
              <ion-col size="8">
                <div class="flex-col-between-h-100">
                    <div>
                        <p class="font-9">{{selectedLanguage == 'al' ? cart.nameSQ : cart.nameMK}}</p>
                    </div>
                    <p class="font-15">{{cart.sellingPriceWithVat % 1 != 0 ? cart.sellingPriceWithVat.toFixed(1) : cart.sellingPriceWithVat}}{{'CURRENCY' | translate | uppercase}}</p>
                </div>
              </ion-col>
            </ion-row>
        </ion-grid>
      </ion-list>
      <ion-list>
        <ion-item-group>
            <ion-item lines="none">
                <div class="flex-between-w-96">
                    <p class="subtotal">Purchase Date:</p>
                    <p class="subtotal-price">24 Jan 2024</p>
                </div>
            </ion-item>
          <ion-item lines="none">
            <div class="flex-between-w-96">
                <p class="subtotal">Delivered On:</p>
                <p class="subtotal-price">31 Jan 2024</p>
            </div>
          </ion-item>
        </ion-item-group>
      </ion-list>
      <hr class="custom-hr">
      <ion-list>
        <ion-item-group>
            <ion-item lines="none">
                <div class="flex-between-w-96">
                    <p class="subtotal">2 items</p>
                    <p class="subtotal-price">684.00 <span class="font-weight-light">{{'CURRENCY' | translate | uppercase}}</span></p>
                </div>
            </ion-item>
          <ion-item lines="none">
            <div class="flex-between-w-96">
                <p class="subtotal">Shipping price</p>
                <p class="subtotal-price">150.00 <span class="font-weight-light">{{'CURRENCY' | translate | uppercase}}</span></p>
            </div>
          </ion-item>
          <ion-item lines="none">
            <div class="flex-between-w-96">
                <p class="subtotal">Subtotal</p>
                <p class="subtotal-price">150.00 <span class="font-weight-light">{{'CURRENCY' | translate | uppercase}}</span></p>
            </div>
          </ion-item>
          <ion-item lines="none">
            <div class="flex-between-w-96">
                <p class="grandtotal">Grandtotal</p>
                <p class="grandtotal-price">{{'834.00'}}<span class="font-weight-bold">{{'CURRENCY' | translate | uppercase}}</span></p>
            </div>
          </ion-item>
        </ion-item-group>
      </ion-list>
    </ion-card-content>
  </ion-card>
  <div class="delivery-card">
    <ion-card>
        <ion-card-header>
          <ion-card-title>Delivery Options</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <p class="pt-4 pb-2">Name Surname</p>
          <p class="py-2">Address, City</p>
          <p class="py-2">Zip Code,</p>
          <p class="py-2">North Macedonia</p>
        </ion-card-content>
      </ion-card>
  </div>
</ion-content>
