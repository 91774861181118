import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../user/services/user.service';
import { getUser } from 'src/app/interfaces/IUsers';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  standalone: true,
  imports: [ RouterModule, TranslateModule, IonicModule, CommonModule ]
})
export class ProfileComponent implements OnInit {
  
  selectedLanguage: string;
  isLogged:boolean = false;
  displayName:string = "";
  userId: number = parseInt(localStorage.getItem("userId"), 10);
  name:string;
  surname:string;
  email:string;
  initials:string;

  constructor(private location: Location, 
              private router: Router,
              private translate: TranslateService,
              private route: ActivatedRoute,
              private userService:UserService) { }

  ngOnInit(): void {
    this.isLogged = !!localStorage.getItem("token");
    if (localStorage.getItem('selectedLanguage') == null) {
      this.selectedLanguage = 'al';
    } else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }
    this.getUser();
  }

  ngAfterContentChecked(): void {
    this.isLogged = !!localStorage.getItem("token");
  }

  getUser(): void {
    if (this.userId !== 0) {
      this.userService.getUser(this.userId).subscribe(
        (data: getUser) => {
          const user = data[0];
  
          this.name = user?.name;
          this.surname = user?.surname;
          this.email = user?.email;

          if(!!this.name?.split(' ')[1]) {
            let firstLetterSecondWord = this.name?.split(' ')[1].substring(0, 1);
            this.initials = this.name?.substring(0, 1) + firstLetterSecondWord;
          } else {
            this.initials = this.name?.substring(0, 1);
          }
        },
        (error) => {
          console.error('Error fetching user data', error);
        }
      );
    } else {   
      this.resetUserData();
    }
  }
  
   resetUserData(): void {
    this.name = '';
    this.surname = '';
    this.email = '';
  }

  backClicked() {
    this.location.back();
  }

  logOut(){
    localStorage.removeItem("token");
    localStorage.removeItem("displayName");
    localStorage.removeItem("userId");
    this.isLogged = false;
    this.displayName = "";
    this.router.navigate(["/products"]);
  }
}
