<ion-content>
<!-- <div class="filtersDiv" fxLayout="row" fxLayoutWrap>
  <h3 class="filters">{{'FILTERS' | translate}}</h3>
  <i class="material-icons-outlined" (click)="backClicked()">close</i>
</div> -->

<!-- <div class="sort-by">
  <label class="sort-label">{{'SORT_BY' | translate}}</label>
  <select [(ngModel)]="order" name="sort" id="sort" (click)="onChange($event.target.value)">
    <option value="asc">{{'A_TO_Z' | translate}}</option>
    <option value="desc">{{'Z_TO_A' | translate}}</option>
  </select>
</div>

<div *ngIf="previousUrl !== '/companies'" class="price-range">
  <label class="price-label">{{'PRICE_RANGE' | translate}}</label> <br>
  <input class="price-input-min" value="{{min}}" [(ngModel)]="min" (ngModelChange)="minValue(min)" type="number" placeholder="Min">
  <input class="price-input-max" value="{{max}}" [(ngModel)]="max" (ngModelChange)="maxValue(max)" type="number" placeholder="Max">
</div>

<div class="type">
  <label for="type" class="type-label">{{'TYPE' | translate}}</label><br>
  <select name="type" id="type">
    <option value="service" class="service-type">{{'SERVICE' | translate}}</option>
    <option value="product" class="product-type">{{'PRODUCT' | translate}}</option>
  </select>
</div>

<div class="brands">
  <label id="brands">{{'BRANDS' | translate}}</label>
  <div>
    <input type="checkbox" value="iSolve" id="iSolve">
    <label for="iSolve" class="brands-label">{{'ISOLVE' | translate}}</label>
  </div>
  <div>
    <input type="checkbox" value="Conome" id="Conome">
    <label for="Conome" class="brands-label">{{'CONOME' | translate}}</label>
  </div>
  <div>
    <input type="checkbox" value="Related" id="Related">
    <label for="Related" class="brands-label">{{'BRANDS_COMPANIES' | translate}}</label>
  </div>
</div>

<div class="cancel-save">
  <h3 class="cancel-button" (click)="backClicked()">{{'CANCEL' | translate}}</h3>
  <button class="save-button" (click)="onSave()">{{'SAVE' | translate}}</button>
</div> -->

<div class="flex-between-center">
  <div class="flex-no-end">
      <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
      <h6 class="font-16-550">Filter</h6>
  </div>
  <h6 class="title-red" (click)="resetFilters()">Reset All Filters</h6>
</div>

<div class="bg-grey">
  <div class="bg-white">
    <ion-list>
      <ion-item [routerLink]="['/filter-brand']">
        <ion-label>By Company 
            <div class="selected-company-list">
              <span *ngFor="let company of selectedCompanies; let i = index" class="selected-company">
                {{ company.company?.nameSQ || company.company?.nameMK }}<span *ngIf="i < selectedCompanies.length - 1">, </span>
              </span>
            </div>
        </ion-label>
          <!-- <ion-icon name="arrow-forward" class="arrow-font"></ion-icon> -->
    
      </ion-item>
      <ion-item [routerLink]="['/filter-price']">
        <ion-label>Sort by Price</ion-label>
        <ion-icon name="arrow-forward" class="arrow-font"></ion-icon>
      </ion-item>
      <ion-item lines="none">
        <ion-range aria-label="Custom range" [min]="options.floor - 1" [max]="options.ceil" [step]="options.step" [pin]="false"
            [(ngModel)]="value" (ionChange)="detectchange($event.detail.value)">
        </ion-range>
      </ion-item>
      <ion-item lines="none">
        <ion-text slot="start" class="font-14">{{value}} km</ion-text>
        <ion-text slot="end" class="font-14">Max range</ion-text>
      </ion-item>
      <ion-item lines="none">
        <ion-label>Free Shipping</ion-label>
        <ion-toggle><span class="d-none">Default Toggle</span></ion-toggle>
      </ion-item>
    </ion-list>
  </div>
  <ion-row class="w-85">
    <ion-col size="12">
      <ion-button size="large" expand="full" shape="round" color="warning" (click)="filterProducts()">
        <span class="btn-text">Continue</span>
      </ion-button>  
    </ion-col>
  </ion-row>
</div>
</ion-content>