export enum EndpointsEnum {
  register = 'register',
  login = 'login',
  forgotpassword = 'forgotpassword',
  changepassword = 'changepassword',
  getPlaces = 'getPlaces',
  index = 'index',
  compdetails = 'compdetails',
  allproducts = 'allproducts',
  details = 'details',
  product = 'product',
  AllMobileCategories = 'AllMobileCategories',
  AllProductMobileCategories = 'AllProductMobileCategories',
  AllCompanyMobileCategories = 'AllCompanyMobileCategories',
  getUser = "getUser",
  getClientOrders = "GetClientOrders"
}
