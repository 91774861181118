import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { IonicModule, isPlatform, Platform } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-deactivate',
  templateUrl: './deactivate.component.html',
  styleUrls: ['./deactivate.component.css'],
  standalone: true,
  imports: [ CommonModule, IonicModule ]
})
export class DeactivateComponent {

  constructor(private platform: Platform, private location: Location) { }

  backClicked() {
    this.location.back();
  }

}
