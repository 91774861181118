import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Options } from '@angular-slider/ngx-slider';
import { SearchService } from '../../search.service';
import * as geolib from 'geolib';
import {  Plugins, } from '@capacitor/core';
import { Position } from '@capacitor/geolocation';
import { Geolocation } from '@capacitor/geolocation';
import { AlertController, Platform } from '@ionic/angular';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { FilterService } from 'src/app/filter.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProductWithLatAndLon } from 'src/app/interfaces/IProduct';
import { Category, Product } from 'src/app/interfaces/IProductCategories';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CompanyCategory, CompanyData, CompanyWithLocation } from 'src/app/interfaces/ICompany';
import { App } from '@capacitor/app';
import { FilterDataService } from '../user/services/filter-data.service';
import { IonicModule } from '@ionic/angular';
import { NgxSpinnerModule } from "ngx-spinner";
import { SearchComponent } from '../search/search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CaseInsensitiveSortPipe } from '../Pipes/case-insensitive-sort.pipe';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CustomFilterPipe } from '../Pipes/filter.pipe';

const OPTIONS: Options = { floor:1, ceil:50, step:1 };

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  standalone: true,
  imports: [ IonicModule, NgxSpinnerModule, SearchComponent, CommonModule, RouterModule, CaseInsensitiveSortPipe, CustomFilterPipe, TranslateModule, ScrollingModule ]
})
export class ProductsComponent implements OnDestroy {
  companies: CompanyData[] = [];

  constructor(
    private dataService: DataService,
    private searchService: SearchService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private platform: Platform,
    private filterService: FilterService,
    private translate:TranslateService,
    private filterDataService: FilterDataService,
    private alertController: AlertController
  ) { }

  location:any;
  loc: Position;
  waitingForCurrentPosition: boolean = false;
  searchTextt: string;

  tabSelected: string = 'products';
  compani: CompanyData[] = [];
  clear: string = '';

  allproducts: IProductWithLatAndLon[] = [];
  products: IProductWithLatAndLon[] = [];

  categories: Category[] = [];
  categoriesSet = new Set();

  selectedCategory: boolean = false;
  selectedCategoryid:number;
  
  productCategories: Product[];
  orderedProducts = [];

  private sliderValueSubject = new Subject<number>();
  private sliderValueSubscription : Subscription

  subscription: Subscription
  imgpath: string = environment.IMG_PATH;
  imagepath: string = environment.API_URL + '/';
  value = JSON.parse(localStorage.getItem('value'));
  filter = false;
  order: string = 'asc';

  sortingBasedOnLanguage: string = 'nameSQ'
  selectedCompanies: number[] = [];
  refetchSubscription: Subscription;

  get options(){
    return OPTIONS;
  }

  get currentLanguage(){
    return this.translate.currentLang;
  }  
  
  private sortSubscription: Subscription;
  currentSort: 'asc' | 'desc' | null = null;


  ngOnInit(): void {
    this.sortSubscription = this.filterService.sortChanged$.subscribe(sort => {
      this.currentSort = sort;
    });

    this.refetchSubscription = this.filterDataService.refetchTrigger.subscribe(() => {
      // this.refetchData();
      const selectedCompanies = this.filterDataService.getSelectedCompanies();
      this.selectedCompanies = selectedCompanies.map(company => company.company.crmId); 
    });
 
    this.searchService.searchTextt.pipe(debounceTime(300)).subscribe((text) => {
      this.searchTextt = text;
      this.findMe();
      this.findMeCompanies();

    });
  
  }

  detectchange(value: number) {
    this.sliderValueSubject.next(JSON.parse(localStorage.getItem('value')));
    //this.sliderValueSubject.next(value);
  }

  ionViewDidEnter() {
    if(!localStorage.getItem('value')) {
      localStorage.setItem('value', JSON.stringify(5));
    }
    this.searchService.searchTextt.subscribe((val) => {
      this.searchTextt = val;
    });
    this.spinner.show();
    this.order = this.filterService.getValue();
    this.sortProductsBasedOnSelectedLanguage()

    this.findMe();
    this.findMeCompanies();

    this.updateOrderedProductsFromLocalStorage();
  }

  updateOrderedProductsFromLocalStorage() {
    this.orderedProducts = JSON.parse(localStorage.getItem('cart')) || [];
  }

  async findMe() {
    try {
      const hasOpened = localStorage.getItem('hasOpened');
      if(hasOpened) {
        var cordinates = await Geolocation.getCurrentPosition();
      /* const { Geolocation } = Plugins; */
         this.location = cordinates.coords;
         var latitude = this.location.latitude;
         var longitude = this.location.longitude;
        // Determine which companies to use for filtering
        const companiesToFilter = this.selectedCompanies;
  
         if(cordinates) {
  
          const productObservable = this.selectedCompanies.length > 0 || this.currentSort || this.searchTextt
            ? this.dataService.getAllProductsFiltered({ companyIds: companiesToFilter, searchText: this.searchTextt }, this.currentSort)
            : this.dataService.getAllProducts();
  
  
          this.subscription = productObservable.subscribe(
            (formated: IProductWithLatAndLon[]) => {
              this.products = formated;
              this.allproducts = [];
              this.products.forEach(formate => {
                  const companyLatitude: number = parseFloat(formate.companyLatitude);
                  const companyLongitude: number = parseFloat(formate.companyLongitude);
                  const radius: number = JSON.parse(localStorage.getItem('value')) * 1000;
  
                var geolibi = geolib.isPointWithinRadius(
                    { latitude: latitude, longitude: longitude },
                    { latitude: companyLatitude, longitude: companyLongitude },
                    radius 
                );
  
                if (geolibi) {
                  if (this.filterService.getMinValue() == undefined && this.filterService.getMaxValue() == undefined) {
                    this.allproducts.push(formate);
                  } else {
                    this.allproducts = this.allproducts.filter(x => x.productDetails[0].productDetailSellingPriceWithVat >= this.filterService.getMinValue() && x.productDetails[0].productDetailSellingPriceWithVat <= this.filterService.getMaxValue())
                    this.allproducts.push(formate);
                  }
                } else {
                }
              });
            },
            (error) => {}
          );
          this.spinner.hide();
         }
        }
        }
        catch (err) {
          console.error("Geolocation error", err);
          this.spinner.hide();
         Swal.fire({
                text: 'Please enable location test',
                icon: 'warning',
                confirmButtonText: 'Enable Location',
              }).then((result) => {
                  if (result.isConfirmed) {
                    this.platform.ready().then( async () => {
                      if (this.platform.is('android')) {
                        let option = NativeSettings.openAndroid({
                          option: AndroidSettings.Location,
                        });
                      } else if (this.platform.is('ios')) {
                        NativeSettings.openIOS({
                          option: IOSSettings.App,
                        });
                      } else {
                        console.log('you are not in mobile app')
                      }
                    })                 
  
                    this.platform.resume.subscribe(() => {
                      window.location.reload();
                      this.subscription = this.dataService.getAllProducts().subscribe(
                        (formated: IProductWithLatAndLon[]) => {
                          this.products = formated;
                          this.allproducts = formated;
                        },
                        (error) => {}
                      );
                      //this.findMeCompanies();
                      this.spinner.hide();
                    })
                }
              });
        } finally {
          this.waitingForCurrentPosition = false;
        }
  
    }

  // This function will change sortingBasedOnLanguage variable which sorts products based on language on initialization
  sortProductsBasedOnSelectedLanguage(): void {
    let lang: string | undefined = (localStorage.getItem("selectedLanguage"))
    lang === 'mk' ? this.sortingBasedOnLanguage = 'productNameMK' : this.sortingBasedOnLanguage = 'productNameSQ'
  }

  setToArrayAndSetCategories(set: Set<any>): void {
    this.categories = Array.from(set)
  }

  onFilter(){
    this.filter = true;
    this.router.navigate(["/filter"]);
  }

  onSelectedCategory(id: number | null){
    this.selectedCategory = true;
    this.selectedCategoryid = id;
    let lang = this.sortingBasedOnLanguage

    if(id == null) {
      this.allproducts = this.products
      return
    }

    let minVal = this.filterService.getMinValue()
    let maxVal = this.filterService.getMaxValue()

    this.allproducts = this.products.filter((data: any) => {
      if (id !== data.categoryID) {
        return false; // Filter out products not matching the categoryID
      }

      if ((minVal === undefined && maxVal === undefined) || (minVal === 0 && maxVal === 0)) {
        return true;
      } else if (
        (minVal !== undefined && minVal !== 0 && (maxVal === 0 || maxVal === undefined)) &&
        data.productDetails[0].productDetailSellingPriceWithVat >= minVal
      ) {
        return true;
      } else if (data.productDetails[0].productDetailSellingPriceWithVat >= minVal && data.productDetails[0].productDetailSellingPriceWithVat <= maxVal) {
        return true;
      }

      return false; // Default case: product doesn't match any condition
    });
  }

  onAllCategories(){
    this.selectedCategory = false;
    this.selectedCategoryid = null;
  }

  addToCart(e, product, index) {
    e.stopPropagation(); 
    const existingCartItems = JSON.parse(localStorage.getItem('cart')) || []; 
    const productExistInCart = existingCartItems.find(prod => prod.id === product.id);    
    if (!productExistInCart) {
      product["quantity"] = 1;
      existingCartItems.push(product);      
      localStorage.setItem('cart', JSON.stringify(existingCartItems));      
      this.dataService.getCartListNumber(existingCartItems.length);
    }
  }

  addToCartCategory(e, product, index) {
    e.stopPropagation();
    const productExistInCart = this.orderedProducts.find(prod => prod.productId === product.productId);
    if(!productExistInCart) {
      product["quantity"] = 1;
      this.orderedProducts.push(product);
     }
    localStorage.setItem('cart', JSON.stringify(this.orderedProducts));
    this.dataService.getCartListNumber(this.orderedProducts.length);
  }

  onSegmentChange(event) {
    this.tabSelected = event.target.value;
  }
    
  async findMeCompanies() {    
    try {
      this.loc = await Geolocation.getCurrentPosition();
      var latitude = this.loc.coords.latitude;
      var longitude = this.loc.coords.longitude;
    }
    catch (err) {
      this.spinner.hide();
    }

    this.dataService.getMobileCompanyCategories().subscribe(formated => {
      if(formated.length == 0){
        this.spinner.hide()
        Swal.fire({
          text: 'Not Found',
          icon: 'error',
          confirmButtonText: 'Close',
        }).then((result) => {
            if (result.isConfirmed) {
              // this.router.navigate(['/companies']);
          }
        });
      }
      this.companies = formated;
      this.compani = [];
      this.companies.forEach(formate => {
        
        const latitudee: string = formate.company.lat;
        const longitudee: string = formate.company.lon;
        const radius: number = this.value * 1000;
        var geolibi = geolib.isPointWithinRadius(
          { latitude: latitude, longitude: longitude },
          { latitude: latitudee || 0, longitude: longitudee || 0 },
          radius //meters
        );
        
        if(!geolibi) return
        this.compani.push(formate);
        
        const existingCompany = Array.from(this.categoriesSet).find((company: any) => company.id === formate.company.id);
  
        if (!existingCompany) {
          this.categoriesSet.add(formate.company);
        }        
        this.spinner.hide();
      })
      
      this.setToArrayAndSetCategories(this.categoriesSet)
      this.spinner.hide()
    })
  }

  onSelectedCompanyCategory(id:number | null){
    this.selectedCategoryid = id;
    
    if (!id) {
      this.companies = this.compani
      return
    } 
    this.companies = this.compani.filter((company) => company.company.id === id)    
  } 

  clearSearch() {
    this.clear = 'clear';
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
        if (this.sortSubscription) {
          this.sortSubscription.unsubscribe();
        }
  }
}