<!-- <app-header></app-header> -->

<ion-content>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p class="spinner-text">Loading...</p>
</ngx-spinner>
  <!-- <div style="display: flex;margin-top: 15px;"> -->
  <div class="d-flex-mt-15">
    <a [routerLink]="['/']">
      <ion-icon class="back-icon" size="large" name="arrow-back"></ion-icon>
    </a>
    <h4 class="font-16-w-600">All categories</h4>
  </div>
<cdk-virtual-scroll-viewport [itemSize]="1" class="example-viewport">
  <div class="fullpage">
    <div class="row content">
      <ion-segment #segment color="warning" (ionChange)="onSegmentChange($event)">
        <ion-segment-button value="{{category.nameSQ}}" *ngFor="let category of productCategories">
          <div fxLayoutAlign="center center card">
            <!-- <img mat-card-image class="card-img" src="{{ imgpath + category.image }}" /> -->
            <img mat-card-image class="card-img" src="../../../assets/images/register-success.png" />

          </div>
          <ion-label class="font-15-w-600">{{ currentLanguage === 'al' ? category.nameSQ : category.nameMK }}</ion-label>
        </ion-segment-button>
      </ion-segment>     
      <div class="flex-end">
        <h4 class="font-12-blue" (click)="openAllCategoreisModal()">View All</h4>
      </div>
    </div>
    <!-- Products List -->
    <div class="items-container">
      <div class="row">
        <div class="col-6 p-8" *ngFor="let product of allproducts">
          <mat-card [routerLink]="['/productdetail', product.productId]" class="product-card">
            <div fxLayoutAlign="center center" class="product-image-wrapper">
              <img mat-card-image class="product-image" [src]="imgpath + product.productImage" />
            </div>
            <mat-card-title class="mat-card-title">
              {{ currentLanguage === 'al' ? product.productNameSQ : product.productNameMK }}
            </mat-card-title>
            <mat-card-content>
              <div class="card-content-wrapper">
                <p class="card-content-price">
                  {{ product.productDetails[0].productDetailSellingPriceWithVat | currency }}
                </p>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</cdk-virtual-scroll-viewport>
</ion-content>
