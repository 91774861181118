import { Component, Input, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { Router} from '@angular/router';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.css'],
  standalone: true,
  imports: [ IonicModule ]
})
export class PaymentModalComponent implements OnInit {
  @Input() data: any;
  selectedMethod: string = 'card';
  constructor(private modalCtrl: ModalController, private router: Router, private dataService: DataService) { }

  ngOnInit(): void {}

  cashOrCard(event) {
    this.selectedMethod = event.detail.value;
  }

  paymentMethod() {
    this.dataService.paymentMethod(this.selectedMethod);
    this.router.navigate(['/payment-result']);
    this.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
}
