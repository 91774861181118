<ion-content>
    <ion-grid *ngIf="!isMessage" class="mt-50">
        <ion-row>
          <ion-col size="9" offset="1">
            <div class="msg-bg">
                <p class="msg-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
            </div>
          </ion-col>
          <ion-col size="1">
            <div class="profile-bg">
                <ion-icon name="person" class="user-icon"></ion-icon>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="1" offset="1">
                <div class="profile-bg">
                    <ion-icon name="person" class="user-icon"></ion-icon>
                </div>
            </ion-col>
            <ion-col size="9">
              <div class="msg-reply-bg">
                  <p class="msg-reply-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
              </div>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col class="text-center">
                <p class="font-11">Monday, 10:40 am</p>
            </ion-col>
        </ion-row>
    
        <ion-row>
            <ion-col size="9" offset="1">
              <div class="msg-bg">
                  <p class="msg-text">Sed do eiusmod tempor.</p>
              </div>
            </ion-col>
            <ion-col size="1">
              <div class="profile-bg">
                  <ion-icon name="person" class="user-icon"></ion-icon>
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
              <ion-col size="1" offset="1">
                  <div class="profile-bg">
                      <ion-icon name="person" class="user-icon"></ion-icon>
                  </div>
              </ion-col>
              <ion-col size="9">
                <div class="msg-reply-bg">
                    <p class="msg-reply-text">Excepteur sint occaecat cupidatat non proident.</p>
                </div>
              </ion-col>
          </ion-row>
    </ion-grid>
    <ion-grid *ngIf="!isMessage" class="grid-bottom">
        <ion-row>
            <ion-col size="9" offset="1">
                <ion-input label="Say something..." label-placement="floating" fill="outline" placeholder="Say something..."></ion-input>
            </ion-col>
            <ion-col>
                <ion-icon name="arrow-forward" class="icon-size"></ion-icon>
            </ion-col>
        </ion-row>
    </ion-grid>
    
    <ion-grid *ngIf="isMessage" class="container">
        <ion-row>
          <ion-col>
            <div class="ion-text-center">
                <ion-img src="assets/images/new/no-messages.png" alt="Messages"></ion-img>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
              <div class="ion-text-center mx-5">
                <h3 class="d-flex justify-content-center font-27">{{"You dont have any messages." | translate}}</h3>
                <a [routerLink]="['/products']" class="font-16-weight-700-dark-underline">Go Back to Home Page</a>
              </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>