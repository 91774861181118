import { Component, OnInit } from '@angular/core';
import { SearchService } from 'src/app/search.service';
import { UserService } from '../services/user.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RouterModule, ActivatedRoute } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  standalone: true,
  imports: [ IonicModule, ReactiveFormsModule, RouterModule, TranslateModule, MatFormFieldModule, MatInputModule ]
})
export class ChangePasswordComponent implements OnInit {
  viewPassword: boolean = false;
  viewConfirmPassword: boolean = false;
  changePassword = new UntypedFormGroup({
    NewPassword: new UntypedFormControl(''),
    ConfirmNewPassword: new UntypedFormControl(''),
    token: new UntypedFormControl(''),
    userId: new UntypedFormControl(''),
  });
  Token: string;
  constructor(
    private search: SearchService,
    private userService: UserService,
    private translate: TranslateService,
    protected activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getParams();
  }

  changepassword(data) {
    this.userService.changepassword(data).subscribe((res) => {
      console.log(res);
    });
    this.changePassword.reset();
  }

  getParams() {
    this.activatedRoute.queryParamMap.subscribe((param: any) => {
      let userId;
      this.changePassword.controls['token'].setValue(param.params.token);
      this.changePassword.controls['userId'].setValue(param.params.userId);
      return userId;
    });
  }

  passwordShowHide(){
    this.viewPassword = !this.viewPassword;    
  }

  confirmPasswordShowHide(){
    this.viewConfirmPassword = !this.viewConfirmPassword;
  }

}
