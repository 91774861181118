<div class="w-100" fxLayout="row" fxLayoutWrap>
    <!-- <div class="menu-button">
      <i class="material-icons icons" routerLink="/menu">east</i>
    </div> -->
    <div class="flex-header">
        <app-search></app-search>
    </div>
</div>

<div class="fullpage">

    <swiper-container
      pagination="true"
      slides-per-view="1"
      space-between="50"
      #swiperContainer
      class="swiper-ctn home-banner"
      (swiperslidechange)="onSlideChange()"
    >
      <swiper-slide class="swiper-slide">
        <div class="banner-content">
          <div class="banner-text">
            <h2>Welcome to Our Store</h2>
            <p>Discover the best products at amazing prices!</p>
            <ion-button color="light" class="view-button">View</ion-button>
          </div>
          <div class="banner-image">
            <img src="https://via.placeholder.com/200x200" alt="Banner Image 1" />
          </div>
        </div>
      </swiper-slide>
  
      <swiper-slide class="swiper-slide">
        <div class="banner-content">
          <div class="banner-text">
            <h2>Exclusive Deals</h2>
            <p>Don’t miss out on limited-time offers!</p>
            <ion-button color="light" class="view-button">View</ion-button>
          </div>
          <div class="banner-image">
            <img src="https://via.placeholder.com/200x200" alt="Banner Image 2" />
          </div>
        </div>
      </swiper-slide>
  
      <swiper-slide class="swiper-slide">
        <div class="banner-content">
          <div class="banner-text">
            <h2>New Arrivals</h2>
            <p>Check out the latest products in our collection.</p>
            <ion-button color="light" class="view-button">View</ion-button>
          </div>
          <div class="banner-image">
            <img src="https://via.placeholder.com/200x200" alt="Banner Image 3" />
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
      
    <div>
        <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
            <p class="spinner-text">Loading...</p>
        </ngx-spinner>
        <div class="btn-container">
            <ion-button class="m-20-10" color="warning" routerLink="/filter">
                <span class="btn-text">Filter</span>
                <ion-icon name="options"></ion-icon>
            </ion-button>
        </div>

        <div class="categories-container">
            <div class="categories-header">
                <h2 class="categories-title">Categories</h2>
                <a class="see-all" routerLink="/productcategories">See All</a>
            </div>
        
            <div class="categories-grid">
                <div 
                *ngFor="let category of productCategories"
                [class.selected]="selectedCategoryName === category.nameSQ"
                (click)="onSelectedCategory(category.nameSQ)" 
                class="category-item">
                    <div class="category-circle">
                        <img class="category-img" [src]="imgpath + category.image" alt="{{ category.nameSQ }}" />
                    </div>
                    <div class="category-name">
                        {{ currentLanguage === 'al' ? category.nameSQ : category.nameMK }}
                    </div>
                </div>
            </div>
        </div>
          
        <div class="items-container">
            <cdk-virtual-scroll-viewport [itemSize]="1" class="virtual-scroll-viewport">

                <div class="row">
                    <div class="col-6 p-8"
                        *ngFor="let product of allproducts | filter: searchTextt ">
                        
                        <mat-card [routerLink]="['/productdetail', product.productId, selectedCategoryid || 0, 0]" class="product-card">
                            <div fxLayoutAlign="center center" class="product-image-wrapper">
                                <img mat-card-image class="product-image" src="{{ imgpath + product.productImage  }}"/>
                            </div>
                            <mat-card-title class="mat-card-title">
                                {{currentLanguage === 'al' ? product.productNameSQ : product.productNameMK}}
                            </mat-card-title>
                            <mat-card-content>
                                <div class="card-content-wrapper">
                                    <p class="card-content-price">
                                        {{product.productDetails[0].productDetailSellingPriceWithVat % 1 != 0 
                                        ? product.productDetails[0].productDetailSellingPriceWithVat.toFixed(1) 
                                        : product.productDetails[0].productDetailSellingPriceWithVat }}
                                        {{ 'CURRENCY' | translate }}
                                    </p>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>

    </div>
</div>