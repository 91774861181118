<ion-content>
    <div class="flex-between-center">
        <div class="flex-no-end">
            <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
            <h6 class="font-16-550">Back</h6>
        </div>
    </div>
    <div class="bg-grey">
        <div class="bg-white">
            <ion-row class="row px-5">
                <ion-col class="col">
                    <ion-radio-group value="1" (ionChange)="lowestOrHighest($event)">
                        <div class="check-container">
                            <label class="check-label">Normal</label>
                            <ion-radio value="0"></ion-radio>
                        </div>
                        <div class="check-container">
                            <label class="check-label">From lowest to highest</label>
                            <ion-radio value="1"></ion-radio>
                        </div>
                        <div class="check-container">
                            <label class="check-label">From highest to lowest</label>
                            <ion-radio value="2"></ion-radio>
                        </div>
                      </ion-radio-group>
                </ion-col>
            </ion-row>
        </div>
    </div>
</ion-content>