<!-- <app-header></app-header> -->
<div class="flex-row w-100">
    <!-- <div class="menu-button">
      <i class="material-icons icons" routerLink="/menu">east</i>
    </div> -->
    <div class="flex-header">
        <app-search></app-search>
    </div>
    <ion-button class="m-20-10" color="warning" [routerLink]="'/filter'">
        <span class="btn-text">Filter</span>
        <ion-icon name="options"></ion-icon>
    </ion-button>
</div>
<ion-segment color="warning" value="products" (ionChange)="onSegmentChange($event)">
    <ion-segment-button value="products">
      <ion-label [ngClass]="tabSelected == 'products' ? 'selected-grey' : 'grey'">Products</ion-label>
    </ion-segment-button>
    <ion-segment-button value="business">
      <ion-label [ngClass]="tabSelected == 'business' ? 'selected-grey' : 'grey'">Business</ion-label>
    </ion-segment-button>
</ion-segment>
<div class="fullpage">
    <div>
        <!--slider km-->
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
        <p class="spinner-text">Loading...</p>
    </ngx-spinner>

    <!-- <div class="slider">
        <ion-range aria-label="Custom range" [min]="options.floor - 1" [max]="options.ceil" [step]="options.step" [pin]="true"
            [(ngModel)]="value" (ionChange)="detectchange($event.detail.value)"><ion-text slot="start">0</ion-text>
            <ion-text slot="end">200</ion-text>
        </ion-range>
        <div class="text">
            <p class="range-line-text">|</p>
            <p class="range-text">{{ 'RANGE_IN_KM' | translate }}</p>
        </div>
    </div> -->
    <!--search-->
    <!-- <div class="ifilter" fxLayout="row" fxLayoutWrap>
        <div class="sliders-icon" (click)="onFilter()">
            <app-filter *ngIf="filter"></app-filter>
        </div>
        <div class="scroll-container">
            <button class="scroll-button" [ngClass]="{ active: selectedCategoryid == null }" (click)="onSelectedCategory(null)">
                <span class="sp">{{ 'ALL_CATEGORIES' | translate }}</span>
            </button>
            <button class="scroll-button" [ngClass]="{ active: selectedCategoryid == category.id }"
                *ngFor="let category of categories; let i = index" (click)="onSelectedCategory(category.id)">
                <span class="sp">{{ category | translateName }}</span>
            </button>
        </div>
    </div> -->
    
    <cdk-virtual-scroll-viewport *ngIf="tabSelected == 'products'" [itemSize]="1" class="virtual-scroll-viewport">
        <div class="row">
            <div class="col-6 p-8"
                *ngFor="let product of allproducts | Filter: searchTextt">
                <div class="product-card">
                    <mat-card [routerLink]="['/productdetail', product.id, selectedCategoryid || 0, 0]">
                        <img mat-card-image class="product-image" src="{{ imgpath + product.image }}"/>
                        <mat-card-content>
                            <div class="mat-card-title">
                                {{currentLanguage === "al" ? product.nameSQ : product.nameMK}}
                            </div>
                            <div class="card-content-wrapper">
                                <!-- <p class="card-content-category">{{ 'CATEGORY' | translate }}</p> -->
                                <p class="card-content-price">
                                    {{product.sellingPriceWithVat % 1 != 0 ? product.sellingPriceWithVat.toFixed(1) : product.sellingPriceWithVat }}{{ 'CURRENCY' | translate }}
                                </p>
                            </div>
                            <!-- <div class="d-flex justify-content-between px-2 pt-3">
                                <span class="material-icons orange-color" (click)="addToCart($event, product, i)">add_shopping_cart</span>
                            </div> -->
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
    </div>

    <!-- <div *ngIf="tabSelected == 'products'" class="fullpage"> -->
    <!--slider km-->
    <!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
        <p class="spinner-text">Loading...</p>
    </ngx-spinner>

    <div class="slider">
        <ion-range aria-label="Custom range" [min]="options.floor - 1" [max]="options.ceil" [step]="options.step" [pin]="true"
            [(ngModel)]="value" (ionChange)="detectchange($event.detail.value)"><ion-text slot="start">0</ion-text>
            <ion-text slot="end">200</ion-text>
        </ion-range>
        <div class="text">
            <p class="range-line-text">|</p>
            <p class="range-text">{{ 'RANGE_IN_KM' | translate }}</p>
        </div>
    </div> -->

    <!--search-->
    <!-- <div class="ifilter" fxLayout="row" fxLayoutWrap>
        <div class="sliders-icon" routerLink="/filter"></div>
        <div class="scroll-container">
            <button
                class="scroll-button"
                [ngClass]="{ active: selectedCategoryid == null }"
                (click)="onSelectedCompanyCategory(null)"
            >
                <span class="sp">{{ 'ALL_CATEGORIES' | translate }}</span>
            </button>
            <button
                class="scroll-button"
                [ngClass]="{ active: selectedCategoryid == category.id }"
                *ngFor="let category of categories"
                (click)="onSelectedCompanyCategory(category.id)"
            >
                <span class="sp">{{ category | translateName }}</span>
            </button>
        </div>
    </div> -->

    <cdk-virtual-scroll-viewport *ngIf="tabSelected == 'business'" [itemSize]="1" class="example-viewport">
        <div class="row" style="margin: 8px; margin-bottom: 10px">
            <div class="col-12" style="padding: 8px"
                *ngFor="let company of companies | caseInsensitiveSort: sortingBasedOnLanguage:order">
                <h4 class="header-category">{{ currentLanguage === 'sq' ? company?.company?.category[0]?.nameSQ : company?.company?.category[0]?.nameMK}}</h4>
                <!-- [routerLink]="['/companydetail', company.company.id]" -->
                <mat-card class="cardd">
                    <div class="flex-row">
                        <div fxLayoutAlign="center center" class="img-container">
                        <img class="company-image" mat-card-image src="{{ imagepath + company.company.image}}"/>
                        </div>
                        <div style="margin-left: 12px;">
                            <mat-card-title class="TITLE">
                                {{ currentLanguage === 'sq' ? company.company.nameSQ : company.company.nameMK }}
                            </mat-card-title>
                            <mat-card-content>
                                <span class="category-span category-span1">
                                    <p>{{ 'Market' | translate }}</p>
                                </span>
                            </mat-card-content>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
<!-- </div> -->
</div>

<ion-alert></ion-alert>