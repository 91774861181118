<!-- <app-header></app-header> -->
<div class="flex-row w-100">
    <!-- <div class="menu-button">
      <i class="material-icons icons" routerLink="/menu">east</i>
    </div> -->
    <div class="flex-header">
        <app-search></app-search>
    </div>
    <ion-button class="m-20-10 height-fit" color="warning" [routerLink]="'/filter'">
        <span class="btn-text">Filter</span>
        <ion-icon name="options"></ion-icon>
    </ion-button>
</div>
<ion-segment color="warning" value="products" (ionChange)="onSegmentChange($event)" class="min-h">
    <ion-segment-button value="products">
      <ion-label [ngClass]="tabSelected == 'products' ? 'selected-grey' : 'grey'">Products</ion-label>
    </ion-segment-button>
    <ion-segment-button value="business">
      <ion-label [ngClass]="tabSelected == 'business' ? 'selected-grey' : 'grey'">Business</ion-label>
    </ion-segment-button>
</ion-segment>
<div class="fullpage">
    <div>
        <!--slider km-->
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
        <p class="spinner-text">Loading...</p>
    </ngx-spinner>

        <cdk-virtual-scroll-viewport *ngIf="tabSelected == 'products'" [itemSize]="1" class="virtual-scroll-viewport">

            <div class="row">
                <div class="col-6 p-8"
                    *ngFor="let product of allproducts">
                    
                    <mat-card [routerLink]="['/productdetail', product.productId, selectedCategoryid || 0, 0]" class="product-card">
                        <div fxLayoutAlign="center center" class="product-image-wrapper">
                            <img mat-card-image class="product-image" src="{{ imgpath + product.productImage  }}"/>
                        </div>
                        <mat-card-title class="mat-card-title">
                            {{currentLanguage === 'al' ? product.productNameSQ : product.productNameMK}}
                        </mat-card-title>
                        <mat-card-content>
                            <div class="card-content-wrapper">
                                <p class="card-content-price">
                                    {{product.productDetails[0].productDetailSellingPriceWithVat % 1 != 0 
                                    ? product.productDetails[0].productDetailSellingPriceWithVat.toFixed(1) 
                                    : product.productDetails[0].productDetailSellingPriceWithVat }}
                                    {{ 'CURRENCY' | translate }}
                                </p>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>

    <cdk-virtual-scroll-viewport *ngIf="tabSelected == 'business'" [itemSize]="1" class="example-viewport">
        <div class="row" style="margin: 8px; margin-bottom: 10px">
            <div class="col-12" style="padding: 8px"
                *ngFor="let company of companies | caseInsensitiveSort: sortingBasedOnLanguage:order">
                <h4 class="header-category">{{ currentLanguage === 'sq' ? company?.company?.category?.nameSQ : company?.company?.category?.nameMK}}</h4>
                <!-- [routerLink]="['/companydetail', company.company.id]" -->
                <mat-card class="cardd">
                    <div class="flex-row">
                        <div fxLayoutAlign="center center" class="img-container">
                        <img class="company-image" mat-card-image src="{{ imagepath + company.company.image}}"/>
                        </div>
                        <div style="margin-left: 12px;">
                            <mat-card-title class="TITLE">
                                {{ currentLanguage === 'sq' ? company.company.nameSQ : company.company.nameMK }}
                            </mat-card-title>
                            <mat-card-content>
                                <span class="category-span category-span1">
                                    <p>{{ 'Market' | translate }}</p>
                                </span>
                            </mat-card-content>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
<!-- </div> -->
</div>

<ion-alert></ion-alert>