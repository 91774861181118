import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { Options } from '@angular-slider/ngx-slider';
import { SearchService } from '../../search.service';
import * as geolib from 'geolib';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyData, CompanyWithLocation } from 'src/app/interfaces/ICompany';
import { IonicModule } from '@ionic/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxSpinnerModule } from "ngx-spinner";
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from '../header/header.component';

const OPTIONS: Options = { floor:1, ceil:200, step:1 };
@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.css'],
  standalone: true,
  imports: [ IonicModule, FormsModule, NgxSpinnerModule, TranslateModule, ScrollingModule, HeaderComponent ]
})
export class CompaniesListComponent implements OnInit {

  searchTextt: String;
  selectedLanguage: String;
  category: any = [];
  companyCategories;
  subscription: Subscription;
  selectedCategoryId: number;
  company: CompanyData[] = [];
  imgpath: string = environment.API_URL + '/';
  // slider rangekm
  value = JSON.parse(localStorage.getItem('value'));
  
    constructor(
    private dataService: DataService,
    private searchService: SearchService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private activateRoute: ActivatedRoute
  ) {}

  get options(){
    return OPTIONS;
  }
  
  ngOnInit(): void {
        // get Category ID from URL
    this.activateRoute.paramMap.subscribe((obs) => {
      this.selectedCategoryId = JSON.parse(obs.get('id'));
    });
     //checks if search input has value
    this.searchService.searchTextt.subscribe((val) => {
      this.searchTextt = val;
    });
    this.searchService.selectedLanguage.subscribe((val) => {
      this.selectedLanguage = val;
    });
    //checks if hasn't language on localStorage and set a default language
    if(localStorage.getItem('selectedLanguage') === null) {
      this.selectedLanguage = 'al';
    }
    //check if has language on localStorage
    else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }
    this.spinner.show();
    this.findMe();
    this.detectchange(this.value);
  }
  /**
   * save our prefered radius
   * @param value gets value for radius from 'distanca'
   */
  detectchange(value) {
    this.findMe();
    //checks if dont have value on loclstorage and get default value
    if(localStorage.getItem('value') === null) {
      this.value = 35;
      localStorage.setItem('value', JSON.stringify(this.value))
    }
    //checks if have value on localstorage
    else {
      localStorage.setItem('value', JSON.stringify(value))
    }
  }
  /**
  * get our current location and shows companies on our radius
  */
  findMe() {
    // ask to enable locaation on mobile, if is not turn off can't show products
      navigator.geolocation.getCurrentPosition((position) => {
        // gets companies data from DB
        this.dataService.getMobileCompanyCategories().subscribe(
          (formated: CompanyData[]) => {
            //gets just companies which are on Category where we clicked
            const filteredCompany = formated.filter(x => x.mobileCompanyCategoryId === this.selectedCategoryId);
            // gets company data for companies
             this.companyCategories = filteredCompany.map(d=>d.company)
            //gets Categories of Comapnies and shows on category scroll-bar
            this.category = filteredCompany.map(d => d.company.category)[0];
             //checks if dont have company on DB
            if(filteredCompany.length === 0){
              this.spinner.hide()
              Swal.fire({
                text: 'Not Found',
                icon: 'error',
                confirmButtonText: 'Close',
              }).then((result) => {
                  if (result.isConfirmed) {
                    this.router.navigate(['/companies']);
                }
              });
            }
            // we will save dat for each company
            this.company = [];
            //get companies base on selected category and preferred radius value from current location
            this.companyCategories.forEach((formate) => {
              //gets latitude, longitude and radius of products which is saved on Company Data on DB
              const companyLatitude:number = Number(formate.lat);
              const companyLongitude: number = Number(formate.lon);
                const radius: number = this.value * 1000;
                //user latitude and longitude
                var latitude = position.coords.latitude;
                var longitude = position.coords.longitude;
                var geolibi = geolib.isPointWithinRadius(
                  { latitude: latitude, longitude: longitude },
                  { latitude: Number(companyLatitude), longitude: Number(companyLongitude)},
                  radius //meters
                );
                //checks is our location is the same with company location, return TRUE or FALSE
                if(geolibi){
                  this.company.push(formate);
                }
          });
          },
        );
      });
      this.spinner.hide();
    }

    ngOnDestroy(){
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }
}
