<ion-content>
    <div class="flex-between-center">
        <div class="flex-no-end">
            <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
            <h6 class="font-16-550">Back</h6>
        </div>
    </div>
    <ion-row class="row mt-5 px-4">
        <ion-col class="col-4 d-flex-center" *ngFor="let company of companies">
            <div class="card" [ngClass]="{'card-selected': selectedID === company.company.id}" (click)="selectCompany(company.company)">
              <img mat-card-image class="card-img" src="{{ imagepath + company.company.image }}" />
            </div>
            <ion-label class="font-16-w-550">{{ currentLanguage === 'al' ? (company.company.nameSQ | titlecase) : (company.company.nameMK | titlecase) }}</ion-label>
        </ion-col>
    </ion-row>
</ion-content>