import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { UserService } from '../user/services/user.service';
import { getPlaces } from 'src/app/interfaces/IUsers';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';
import { ChangeAddressModalComponent } from '../change-address-modal/change-address-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IonicModule, TranslateModule, MatFormFieldModule]
})
export class PaymentComponent implements OnInit {
  selectedLanguage: string = 'al';
  places:getPlaces[] = [];
  isFormSubmitted: boolean = false;
  cartList = [];
  deliveryFee = 120.00;

  paymentForm = new UntypedFormGroup({
    comment: new UntypedFormControl(''),
    address: new UntypedFormControl('', Validators.required)
  })

  constructor(private location: Location, private modalCtrl: ModalController, private userService: UserService) { }

  ngOnInit(): void {
    this.selectedLanguage = localStorage.getItem('selectedLanguage');
    const shoppingCart = JSON.parse(localStorage.getItem('cart'));
    this.cartList = shoppingCart || [];
  }

  getSubtotal() {
    return this.cartList.reduce((sum, prod) => sum + (prod.productDetails[0].productDetailSellingPriceWithVat * prod.quantity), 0).toFixed(2);
  }

  getGrandTotal() {
    const subtotal = parseFloat(this.getSubtotal());
    return (subtotal + this.deliveryFee).toFixed(2);
  }


  async changeAddress() {
    const modal = await this.modalCtrl.create({
      component: ChangeAddressModalComponent,
      cssClass: 'payment-modal',
    })

    modal.onDidDismiss().then(modalFormData => {
      if(modalFormData.data !== null) {
        if(modalFormData.data !== undefined) {
          let formData = Object.values(modalFormData?.data).join(' ') || '';
          this.paymentForm.get('address').setValue(formData);
        }
      }
    })
    
    await modal.present();
  }

  total() {
    return this.cartList.reduce((sum, prod) => sum += prod.sellingPriceWithVat * prod.quantity, 0).toFixed(1);
  }

  backClicked() {
    this.location.back();
  }

  async openPaymentModal(adressData) {
    const comment = adressData?.comment;
    const address = adressData?.address;
    const total = this.total();
    const modal = await this.modalCtrl.create({
      component: PaymentModalComponent,
      cssClass: 'payment-modal',
      componentProps: {data: {comment, address, total}}
    });
    await modal.present();
  }
}