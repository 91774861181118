import { CompanydetailsComponent } from './components/companydetails/companydetails.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { BodyComponent } from './components/body/body.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { FilterComponent } from './components/filter/filter.component';
import { MenuComponent } from './components/menu/menu.component';
import { ProductCategoriesListComponent } from './components/product-categories-list/product-categories-list.component';
import { ProductdetailsComponent } from './components/productdetails/productdetails.component';
import { ProductsComponent } from './components/products/products.component';
import { SetingsComponent } from './components/setings/setings.component';
import { CompanyCategoriesListComponent } from './components/company-categories-list/company-categories-list.component';
import { UserComponent } from './components/user/user.component';
import { SignInComponent } from './components/user/sign-in/sign-in.component';
import { SignUpComponent } from './components/user/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/user/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/user/change-password/change-password.component';
import { ProductsListComponent } from './components/products-list/products-list.component';
import { ShoppingCartComponent } from './components/shopping-cart/shopping-cart.component';
import { CompaniesListComponent } from './components/companies-list/companies-list.component';
import { OnboardComponent } from './components/onboard/onboard.component';
import { EmailVerifyComponent } from './components/email-verify/email-verify.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PaymentResultComponent } from './components/payment-result/payment-result.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileSettingsComponent } from './components/profile-settings/profile-settings.component';
import { AccountInfoComponent } from './components/account/account-info/account-info.component';
import { PasswordChangeComponent } from './components/account/password-change/password-change.component';
import { DeactivateComponent } from './components/account/deactivate/deactivate.component';
import { MyOrdersComponent } from './components/account/my-orders/my-orders.component';
import { MyOrdersDetailsComponent } from './components/account/my-orders-details/my-orders-details.component';
import { MessagesComponent } from './components/messages/messages.component';
import { AddressesComponent } from './components/maps/addresses/addresses.component';
import { AddAddressComponent } from './components/maps/add-address/add-address.component';
import { FilterByPriceComponent } from './components/filter-by-price/filter-by-price.component';
import { FilterByBrandComponent } from './components/filter-by-brand/filter-by-brand.component';
import { FilterDeliveryComponent } from './components/filter-delivery/filter-delivery.component';

const routes: Routes = [
  { path: '', redirectTo: '/products', pathMatch: 'full' },
  {
    path: '',
    component: BodyComponent,
    children: [
      { path: 'allproducts', component: ProductCategoriesListComponent },
    ],
  },
  { path: 'board', component: OnboardComponent },
  { path: 'email-verify', component: EmailVerifyComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'productcategories', component: ProductCategoriesListComponent},
  { path: 'companies', component: CompaniesComponent },
  { path: 'companycategories', component: CompanyCategoriesListComponent},
  { path: 'settings', component: SetingsComponent },
  { path: 'menu', component: MenuComponent },
  { path: 'filter', component: FilterComponent },
  { path: 'productdetail/:id/:categoryId/:companyId', component: ProductdetailsComponent },
  { path: 'companydetail/:id', component: CompanydetailsComponent },
  { path: 'favorite', component: FavoritesComponent },
  { path: 'user', component: UserComponent },
  { path: 'signin', component: SignInComponent }, //successfullySignUp is used when the new user has clicked the Verify link and the account is now active, he will be redirected here, where he will receive a message of "Account activated"
  { path: 'signin/:isAccountActivated', component: SignInComponent },
  { path: '', redirectTo:"products", pathMatch: "full" }, //If no path is mentioned, redirect to products
  { path: 'signup', component: SignUpComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path:'products-list/:id', component: ProductsListComponent },
  { path:'shopping-cart', component: ShoppingCartComponent },
  { path:'payment', component: PaymentComponent },
  { path:'payment-result', component: PaymentResultComponent },
  { path:'companies-list/:id', component: CompaniesListComponent },
  { path:'profile', component: ProfileComponent },
  { path:'profile-settings', component: ProfileSettingsComponent },
  { path:'account-info', component: AccountInfoComponent },
  { path:'password-change', component: PasswordChangeComponent },
  { path:'deactivate', component: DeactivateComponent },
  { path:'my-orders', component: MyOrdersComponent },
  { path:'my-orders-details', component: MyOrdersDetailsComponent },
  { path:'messages', component: MessagesComponent },
  { path:'addresses', component: AddressesComponent },
  { path:'add-address', component: AddAddressComponent },
  { path:'filter-price', component: FilterByPriceComponent },
  { path:'filter-brand', component: FilterByBrandComponent },
  { path:'filter-delivery', component: FilterDeliveryComponent }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
