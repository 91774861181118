import { Component, OnInit, Optional,  CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isPlatform, Platform, IonRouterOutlet, IonicModule } from '@ionic/angular';
import { LastUrlService } from './last-url.service';
import { Plugins } from '@capacitor/core';
import { App } from '@capacitor/app';
import { CommonModule } from '@angular/common';

import { register } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [ IonicModule, CommonModule ]
})

export class AppComponent implements OnInit {
  title = 'ui';
  selecteLang = localStorage.getItem('selectedLanguage');
  userRoutes = ['/board', '/email-verify', '/signin', '/signup', '/forgot-password', '/change-password'];
  showTab: boolean = false;
  selectedRoute = '';

  constructor(private translate: TranslateService, 
              private lastUrl: LastUrlService, 
              private platform: Platform, 
              private router: Router,
              private location: Location,
              @Optional() private routerOutlet?: IonRouterOutlet, 
              ) {
    if (this.selecteLang) {
      this.translate.use(this.selecteLang);
    }else{
      this.translate.use('al');
      this.translate.setDefaultLang('al');
    }

    this.platform.ready().then(async () => {
      const hasOpened = localStorage.getItem('hasOpened');
      if (!hasOpened) {
        this.router.navigate(['/board']);
      }

    })

  }

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const currentPath = event.urlAfterRedirects;
        this.showTab = this.userRoutes.includes(currentPath);

        this.selectedRoute = currentPath;
        
        // this.platform.ready().then(async () => {
        //   if (this.selectedRoute == '/products') {
        //     console.log('this.selectedRoute2', this.selectedRoute);
        //     this.platform.backButton.subscribeWithPriority(10, () => {
        //       console.log('this.selectedRoute3', this.selectedRoute);
        //       App.exitApp();
        //     })
        //   }
        // })
      });
  }

  selectTab(event: any) {
    const tabs = document.querySelectorAll('ion-tab-button');
    tabs.forEach(tab => tab.classList.remove('selected'));
    event.currentTarget.classList.add('selected');
  }
}
