<!--Header-->
<app-header [showSearch]="showSearch"></app-header>

<div class="row">
  <div class="shopping-cart-fixed" *ngIf="itemsNr > 0">
    <div class="shopping-cart">
      <span class="cart-value">{{itemsNr}}</span>
      <a [routerLink]="['/shopping-cart']">
        <i class="bi bi-cart"></i>
      </a>
    </div>
  </div>
  </div>

  <ion-content>

<div *ngFor="let companyEl of company" class="company">
  <div style="text-align: -webkit-center">
    <img src="{{ imgpath + companyEl.image }}" alt="avatar mx-auto white" class="rounded-circle img-fluid company-image" />
    <mat-card-title class="card-title">{{ companyEl | translateName }}</mat-card-title>
    <h5 class="info-title">
      {{'INFO' | translate}}
    </h5>
    <div class="info-card">
      <ul class="w3-ul w3-small uli">
        <li class="display-flex">
          <img class="material-icons" src="../../assets/images/pin.png">
          <p class="info">
            {{ companyEl | translateName : 'city' }}
          </p>
        </li>
      </ul>
      <ul class="w3-ul w3-small uli">
        <li class="display-flex">
          <img class="material-icons" src="../../assets/images/call.png">
          <p class="info">
            {{ companyEl.telephone }}
          </p>
        </li>
      </ul>
      <ul class="w3-ul w3-small uli">
        <li class="display-flex">
          <img class="material-icons" src="../../assets/images/mail.png">
          <p class="info">
            {{ companyEl.email }}
          </p>
        </li>
      </ul>
      <ul class="w3-ul w3-small uli">
        <li style="display: flex; align-items: center">
          <img class="material-icons" src="../../assets/images/time.png">
          <p class="info">
            {{companyEl.workStartTime}} - {{companyEl.workEndTime}}
          </p>
        </li>
      </ul>
      <ul class="w3-ul w3-small uli">
        <li class="display-flex">
          <img class="material-icons" src="../../assets/images/internet.png">
          <p class="info">
            www.i-solve.info
          </p>
        </li>
      </ul>
    </div>
    <h4 class="products-title">
      {{'PRODUCTS_AND_SERVICES' | translate}}
    </h4>
    <drag-scroll class="categories-button"[scrollbar-hidden]="true">
      <button mat-stroked-button [ngClass]="{'active': !isSelectedCategory}" (click)="onAllCategories()">
        <span class="sp">{{'ALL_CATEGORIES' | translate}}</span>
      </button>
      <button mat-stroked-button [ngClass]="{'active': selectedCategoryId === category.id}"
        *ngFor="let category of categories" (click)="onSelectedCategory(category.id)">
        <span class="sp">{{category | translateName }}</span>
      </button>
    </drag-scroll>
  </div>

  <div class="row products">
    <div *ngFor=" let product of allproducts" class="col-6 p-8">
      <mat-card [routerLink]="['/productdetail', product.id, selectedCategoryId||0, id||0]" class="cardd">
        <div fxLayoutAlign="center center" class="product-details">
          <img mat-card-image class="product-image" src="{{ imgpathi + product.image }}" />
        </div>
        <mat-card-title class="product-title">{{ product | translateName}}</mat-card-title>
        <mat-card-content>
          <div class="row product-content d-flex align-items-center">
            <div class="col-5">
              <p class="product-category">{{'CATEGORY' | translate}}</p>
            </div>
            <div class="col-7 d-flex justify-content-end">
              <p class="product-price">{{ product.sellingPriceWithVat }} {{'CURRENCY' | translate}}</p>
            </div>
          </div>
          <div class="row d-flex px-2 pt-3">
            <span class="material-icons orange-color shopping-cart-button" (click)="addToCart($event, product, i)">add_shopping_cart</span>
        </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
</ion-content>