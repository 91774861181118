<!-- <app-header [showSearch]="false"></app-header> -->

<ion-content>
  <div class="head-guest">
    <h6 routerLink="/products">{{"CONTINUE-GUEST"| translate}}</h6>
  </div>
<div class="user-page">
  <h1 class="font-25-w-700 mt-80">{{"SIGNUP.REGISTER" | translate}}</h1>
  <form class="user-form" [formGroup]="signUpForm" (ngSubmit)="register(signUpForm.value)">

    <!-- Name -->
    <mat-label>{{"SIGNUP.NAME" | translate}}</mat-label>
    <input matInput formControlName="Name" placeholder="{{'SIGNUP.NAME' | translate}}"
      [style.--border-color]="signUpForm.controls['Name'].invalid && (signUpForm.controls['Name'].touched || signUpForm.controls['Name'].dirty || isFormSubmitted) ? '#F85E5E' : '#97979B'"
      (input)="makeFirstLetterUpperCase('Name',$event)" />
    <span *ngIf="signUpForm.controls['Name'].invalid && (signUpForm.controls['Name'].touched || isFormSubmitted)"
      class="text-danger">{{'SIGNUP.REQUIRED_NAME' | translate}}</span>
    <!-- Surname   -->
    <!-- <input matInput formControlName="Surname" placeholder="{{'SIGNUP.SURNAME' | translate}}"
      (input)="makeFirstLetterUpperCase('Surname',$event)" />
    <span *ngIf="signUpForm.controls['Surname'].invalid && (signUpForm.controls['Surname'].touched || isFormSubmitted)"
      class="text-danger">{{'SIGNUP.REQUIRED_SURNAME' | translate}}</span> -->
    <!-- Email -->
    <mat-label>{{"EMAIL" | translate}}</mat-label>
    <input matInput formControlName="Email" placeholder="{{'SIGNUP.EMAIL' | translate}}" 
      [style.--border-color]="signUpForm.controls['Email'].invalid && (signUpForm.controls['Email'].touched || signUpForm.controls['Email'].dirty || isFormSubmitted) ? '#F85E5E' : '#97979B'"/>
    <span *ngIf="signUpForm.controls['Email'].errors?.email" class="text-danger">{{'SIGNUP.NOT_VALID_EMAIL' | translate}}</span>
    <!-- Phone   -->
    <mat-label>{{"SIGNUP.PHONE" | translate}}</mat-label>
    <input matInput formControlName="Phone" placeholder="{{'Phone Number' | translate}}"
      [style.--border-color]="signUpForm.controls['Phone'].invalid && (signUpForm.controls['Phone'].touched || signUpForm.controls['Phone'].dirty || isFormSubmitted) ? '#F85E5E' : '#97979B'"/>
    <span *ngIf="signUpForm.controls['Phone'].invalid && (signUpForm.controls['Phone'].touched || isFormSubmitted)"
      class="text-danger">{{'SIGNUP.PHONE_REQUIRED' | translate}}</span>
      
    <!-- Place   -->
    <mat-label>{{"SIGNUP.COUNTRY" | translate}}</mat-label>
    <mat-select formControlName="PlaceId" class="dropdown" style="font-size: 16px;"
    [style.--border-color]="signUpForm.controls['PlaceId'].invalid && (signUpForm.controls['PlaceId'].touched || signUpForm.controls['PlaceId'].dirty || isFormSubmitted) ? '#F85E5E' : '#97979B'">
      <mat-option *ngFor="let place of places" [value]="place.id">
        {{currentLanguage === 'al' ? place.citySQ : place.cityMK}}
      </mat-option>
    </mat-select>

    <!-- Password -->
    <mat-label>{{"SIGNUP.PASSWORD" | translate}}</mat-label>
    <div class="password">
      <input matInput [type]="viewPassword ? 'text' : 'password'" formControlName="Password"
        [style.--border-color]="signUpForm.controls['Password'].invalid && (signUpForm.controls['Password'].touched || signUpForm.controls['Password'].dirty || isFormSubmitted) ? '#F85E5E' : '#97979B'"
        placeholder="{{'SIGNUP.PASSWORD' | translate}}" />
      <img class="eye-icon" src="../../assets/images/new/icons/eye.png" height="25" *ngIf="!viewPassword" (click)="passwordShowHide()" />
      <img class="eye-off-icon" src="../../assets/images/new/icons/eye-off.png" height="25" *ngIf="viewPassword" (click)="passwordShowHide()" />
    </div>
    <div
      *ngIf="signUpForm.controls['Password'].invalid && (signUpForm.controls['Password'].touched || isFormSubmitted)">
      <span *ngIf="signUpForm.controls['Password'].errors?.required" class="text-danger">{{'SIGNUP.REQUIRED_PASSWORD' | translate}}<br /></span>
      <span *ngIf="signUpForm.controls['Password'].errors?.minlength" class="text-danger">{{'SIGNUP.ERROR_MIN_LENGTH' | translate}}<br /></span>
      <span *ngIf="signUpForm.controls['Password'].errors?.requiresDigit" class="text-danger">{{'SIGNUP.REQUIRED_NUMBER' | translate}}<br /></span>
      <span *ngIf="signUpForm.controls['Password'].errors?.requiresSpecialChars" class="text-danger">{{'SIGNUP.REQUIRED_SPECIAL_CHARACTER' | translate}}<br /></span>
      <span *ngIf="signUpForm.controls['Password'].errors?.requiresUppercase" class="text-danger">{{'SIGNUP.REQUIRED_UPPERCASE' | translate}}<br /></span>
      <span *ngIf="signUpForm.controls['Password'].errors?.requiresLowercase" class="text-danger">{{'SIGNUP.REQUIRED_LOWERCASE' | translate}}<br /></span>
    </div>
    <!-- Confirm password   -->
    <mat-label>{{"SIGNUP.PASSWORD_CONFIRM" | translate}}</mat-label>
    <div class="password">
    <input matInput [type]="viewConfirmPassword ? 'text' : 'password'" formControlName="ConfirmPassword"
      [style.--border-color]="signUpForm.controls['ConfirmPassword'].invalid && (signUpForm.controls['ConfirmPassword'].touched || signUpForm.controls['ConfirmPassword'].dirty || isFormSubmitted) ? '#F85E5E' : '#97979B'"
      placeholder="{{'SIGNUP.PASSWORD_CONFIRM' | translate}}" />
      <img class="eye-icon" src="../../assets/images/new/icons/eye.png" height="25" *ngIf="!viewConfirmPassword" (click)="confirmPasswordShowHide()" />
      <img class="eye-off-icon" src="../../assets/images/new/icons/eye-off.png" height="25" *ngIf="viewConfirmPassword" (click)="confirmPasswordShowHide()" />
      </div>
    <span
      *ngIf="(signUpForm.controls['ConfirmPassword'].touched || isFormSubmitted) && signUpForm.controls['ConfirmPassword'].value !== signUpForm.controls['ConfirmPassword'].value"
      class="text-danger">{{'SIGNUP.NOT_MATCH_PASSWORDS' | translate}}</span>
    <!-- Address  -->
    <!-- <input matInput formControlName="Address" placeholder="{{'SIGNUP.ADDRESS' | translate}}" /> -->
  
    <!-- AddressNumber     -->
    <!-- <input matInput formControlName="AddressNumber" placeholder="{{'SIGNUP.ADDRESS_NUMBER' | translate}}" />
    <span
      *ngIf="signUpForm.controls['AddressNumber'].invalid && (signUpForm.controls['AddressNumber'].touched || isFormSubmitted)"
      class="text-danger">{{'SIGNUP.REQUIRED_ADDRESS_NUMBER' | translate}}</span> -->
    <!-- BankName   -->
    <!-- <input matInput formControlName="BankName" placeholder="{{'SIGNUP.BANK_NAME' | translate}}" /> -->
    <!-- IBAN   -->
    <!-- <input matInput formControlName="IBAN" placeholder="{{'SIGNUP.IBAN' | translate}}" /> -->
    <!-- SWIFT  -->
    <!-- <input matInput formControlName="SWIFT" placeholder="{{'SIGNUP.SWIFT' | translate}}" /> -->
    <!-- BankAccount -->
    <!-- <input matInput formControlName="BankAccount" placeholder="{{'SIGNUP.BANK_ACCOUNT' | translate}}" /> -->
    <!-- Register -->
    <button mat-raised-button class="user-signup-btn" appearance="outline">{{'SIGNUP.REGISTER' | translate}}</button>
  </form>

  <span *ngIf="registerSuccess" class="text-info h2">{{'SIGNUP.SINUP_SUCCESSFUL' | translate}}</span>
  <span *ngIf="registerFail" class="text-danger h2">{{'SIGNUP.SINUP_FAILED' | translate}} : {{registerFail | translate}}</span>
  <br>
  <a class="router-link" [routerLink]="['/signin']" routerLinkActive="router-link-active">{{'SIGNUP.ALREADY_HAVE_LOGIN_CREDENTIALS' | translate}}</a>
</div>
</ion-content>
