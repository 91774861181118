import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.css'],
  standalone: true,
  imports: [ IonicModule, TranslateModule, RouterModule ]
})
export class EmailVerifyComponent implements OnInit {
  nextOrStarted: boolean = true;
  
  constructor() { }

  ngOnInit(): void {
  }

}
