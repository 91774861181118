<ion-content>
    <div class="flex-between-center">
        <div class="flex-no-end">
            <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
            <h6 class="font-16-550">Back</h6>
        </div>
    </div>
    <div class="bg-white">
        <ion-row class="row px-5">
            <ion-col class="col">
                <ion-radio-group value="deliveryone" (ionChange)="deliveryOptions($event)">
                    <div class="check-container">
                        <div class="img-text">
                            <div class="img-container"></div>
                            <label class="check-label">Delivery option 1</label>
                        </div>
                        <ion-radio value="deliveryone"></ion-radio>
                    </div>
                    <div class="check-container">
                        <div class="img-text">
                            <div class="img-container"></div>
                            <label class="check-label">Delivery option 2</label>
                        </div>
                        <ion-radio value="deliverytwo"></ion-radio>
                    </div>
                    <div class="check-container">
                        <div class="img-text">
                            <div class="img-container"></div>
                            <label class="check-label">Delivery option 3</label>
                        </div>
                        <ion-radio value="deliverythree"></ion-radio>
                    </div>
                    <div class="check-container">
                        <div class="img-text">
                            <div class="img-container"></div>
                            <label class="check-label">Delivery option 4</label>
                        </div>
                        <ion-radio value="deliveryfour"></ion-radio>
                    </div>
                    <div class="check-container">
                        <div class="img-text">
                            <div class="img-container"></div>
                            <label class="check-label">Delivery option 5</label>
                        </div>
                        <ion-radio value="deliveryfive"></ion-radio>
                    </div>
                    <div class="check-container">
                        <div class="img-text">
                            <div class="img-container"></div>
                            <label class="check-label">Delivery option 6</label>
                        </div>
                        <ion-radio value="deliverysix"></ion-radio>
                    </div>
                  </ion-radio-group>
            </ion-col>
        </ion-row>
    </div>
</ion-content>
