import { Component, ElementRef, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DataService } from '../../data.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Product } from 'src/app/models/product.model';
import { StorageMap } from '@ngx-pwa/local-storage';
import { CommonModule, Location } from '@angular/common';
import { SearchService } from 'src/app/search.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CompanyById } from 'src/app/interfaces/ICompany';
//import { error } from 'console';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from '../header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateNamePipe } from '../Pipes/translate-name.pipe';

@Component({
  selector: 'app-companydetails',
  templateUrl: './companydetails.component.html',
  styleUrls: ['./companydetails.component.css'],
  standalone: true,
  imports: [ IonicModule, HeaderComponent, CommonModule, TranslateModule, TranslateNamePipe ]
})
export class CompanydetailsComponent implements OnInit, OnDestroy {
  company: any = [];
  imgpath = `${environment.API_URL}/`;
  imgpathi = environment.IMG_PATH;

  id:number;
  allproducts: any = [];
  companies: any = [];
  companiesById: CompanyById;
  prodcom: any = [];
  selectedLanguage;
  categories: any[];
  products: any[];
  isSelectedCategory: boolean = false;
  selectedCategoryId: number;
  productCategories: any[];
  subscription: Subscription;
  allProductCategory:CompanyById[]=[];
  showSearch: boolean = false;
  itemsNr: number = 0;
  orderedProducts = [];

  constructor(
    public dataservice: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private searchService: SearchService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngOnInit() {
    this.getCompanyDetails();
    this.searchService.selectedLanguage.subscribe((val) => {
      this.selectedLanguage = val;
    });
    this.selectedLanguage = localStorage.getItem('selectedLanguage') !== null ? localStorage.getItem('selectedLanguage') : 'al';
    this.dataservice.shoppingCartSize$.subscribe(listNr => {
      this.itemsNr = listNr;
    })
    const cartItems = JSON.parse(localStorage.getItem('cart'));
    this.itemsNr = Array.isArray(cartItems) ? cartItems.length : 0;
  }

  addToCart(e, product, index) {
    e.stopPropagation();
    const productExistInCart = this.orderedProducts.find(prod => prod.id === product.id);
    if(!productExistInCart) {
      product["quantity"] = 1;
      this.orderedProducts.push(product);
     }
    localStorage.setItem('cart', JSON.stringify(this.orderedProducts));
    this.dataservice.getCartListNumber(this.orderedProducts.length);
  }


  getCompany(id) {
    return this.dataservice.getcompaniesbyID(id);
  }
 
  getCompanyDetails(){
     this.route.paramMap
      .pipe(
        switchMap((params) => {
          this.id = +params.get('id');
          return this.dataservice.getcompaniesbyID(this.id).pipe(map((data:CompanyById[])=>{
            this.company=data;
            this.companiesById=data[0];
            this.prodcom=data.map((company)=>{
              this.allProductCategory= company.productCategories.reduce((acc, category)=> acc.concat(category.products), [] )
              return {
                company:{
                  name:company.nameSQ,
                  nameMK:company.nameMK,
                  id:company.id,
                  workStartTime:company.workStartTime,
                  workEndTime:company.workEndTime
                },
                products:company.productsi,
                productCategories:company.productCategories,
              };
            })[0];
            this.checkSelectedCategory();
            this.categories=[...new Set(this.prodcom.productCategories.flatMap(category => category.categories.filter(item=> !item.id.contains)))];
            this.categories=this.categories.map(c=> JSON.stringify(c));
            this.categories=Array.from(new Set(this.categories));
            this.categories=this.categories.map(c=> JSON.parse(c));
          }),
          catchError((error)=>{
            console.log(error);
            return [];
          })
          );
        })
      ).subscribe();
  }
 
  checkSelectedCategory(){
    
    this.allproducts=this.prodcom.products.concat(this.allProductCategory);
    this.allproducts=this.allproducts.map(c=>JSON.stringify(c))
    this.allproducts=Array.from(new Set(this.allproducts));
    this.allproducts=this.allproducts.map(c=> JSON.parse(c));
    this.allproducts=this.isSelectedCategory?this.prodcom.productCategories.filter(x => x.mobileCategoryId === this.selectedCategoryId).reduce((acc, category)=> acc.concat(category.products), []):this.allproducts;
    }

  onAllCategories(){
    this.isSelectedCategory = false;
    this.selectedCategoryId = null;
    this.checkSelectedCategory();
  }

  onSelectedCategory(id:number){
    this.isSelectedCategory = true;
    this.selectedCategoryId = id;
    this.checkSelectedCategory();
  }
  
  backClicked() {
    this.location.back();
  }

  ngOnDestroy(){
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
