<!-- <app-header></app-header> -->

<ion-content>
  <div class="d-flex-mt-15">
    <a [routerLink]="['/']">
      <ion-icon class="back-icon" size="large" name="arrow-back"></ion-icon>
    </a>
    <h4 class="font-16-w-600">All categories</h4>
  </div>
<cdk-virtual-scroll-viewport [itemSize]="1" class="example-viewport">
  <div class="fullpage">
    <div class="row content">
      <ion-segment color="warning" (ionChange)="onSegmentChange($event)">
        <ion-segment-button value="{{category.nameSQ}}" *ngFor="let category of productCategories">
          <div fxLayoutAlign="center center card">
            <img mat-card-image class="card-img" src="{{ imgpath + category.product.image }}" />
          </div>
          <ion-label class="font-15-w-600">{{ currentLanguage === 'al' ? category.product.category[0].nameSQ : category.product.category[0].nameMK }}</ion-label>
        </ion-segment-button>
      </ion-segment>      

        <!-- <mat-card [routerLink]="['/products-list', category.id]">
          <div fxLayoutAlign="center center card">
            <img mat-card-image class="card-img" src="{{ imgpath + category.image }}" />
          </div>
          <mat-card-title class="card-title">{{ currentLanguage === 'al' ? category.nameSQ : category.nameMK }}</mat-card-title>
        </mat-card> -->
      <div class="flex-end">
        <h4 class="font-12-blue" (click)="openAllCategoreisModal()">View All</h4>
      </div>
    </div>
  </div>
</cdk-virtual-scroll-viewport>
</ion-content>
