import { Pipe, PipeTransform } from '@angular/core';
import { SearchService } from 'src/app/search.service';

@Pipe({
  name: 'translateName',
  standalone: true
})
export class TranslateNamePipe implements PipeTransform {

  constructor() {
    this.getSelectedLanguage()
  }

  selectedLanguage: string;

  getSelectedLanguage() {
    this.selectedLanguage = localStorage.getItem('selectedLanguage')
  }

  transform(value: {[key: string]: any, nameSQ: string, nameMK: string, citySQ: string, cityMK: string}, arg: string): string {
    
    if (this.selectedLanguage === 'al') {
      return arg !== 'city' ? value.nameSQ : value.citySQ;
    } else if (this.selectedLanguage === 'mk') {
      return arg !== 'city' ? value.nameMK : value.cityMK;
    } else {
      return arg !== 'city' ? value.nameSQ : value.citySQ;
    }
  }
}
