import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { Geolocation } from '@capacitor/geolocation';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperDirective } from '../../../lib/swiper.directive';

@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.css'],
  standalone: true,
  //encapsulation: ViewEncapsulation.None,
  imports: [ CommonModule, RouterModule, IonicModule, TranslateModule, SwiperDirective ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OnboardComponent implements OnInit {
  nextOrStarted: boolean = true;
  activeIndex: any = 0;

  @ViewChild('swiperCon', { static: true }) swiperContainer: any;

  public config: SwiperOptions = {}

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.config = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      slidesPerView: 1,
      spaceBetween: 50,
      injectStyles: [
        `
        `,
      ],
      on: { slideChange: index => { this.activeIndex = index.snapIndex; console.log('index', index) }, }
    };
    this.getPosition();
  }

  async getPosition() {
    const cordinates = await Geolocation.getCurrentPosition();
  }

  onSlideChange() {
    this.swiperContainer.nativeElement.swiper.activeIndex == 2 ? this.nextOrStarted = false : this.nextOrStarted = true;
  }

  nextSlide() {
    this.swiperContainer.nativeElement.swiper.slideNext();
  }

  goTologin() {
    localStorage.setItem('hasOpened', 'true');
    this.router.navigate(['/signin']);
  }
}