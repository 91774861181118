import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(value: any, propName: string, order : string) {
    
    if (order == "asc") {
      return value.sort((a, b) => {
        if (a[propName] < b[propName]) {
          return -1;
      } else if (a[propName] === b[propName]) {
        return 0;
      } else if (a[propName] > b[propName]) {
        return 1;
      }

    });
    
  }
  if (order == "desc") {
    return value.sort((a, b) => {
      if (a[propName] > b[propName]) {
        return -1;
      } else if (a[propName] === b[propName]) {
        return 0;
      } else if (a[propName] > b[propName]) {
        return 1;
      }
    });
  }

  }
}
