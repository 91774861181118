import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.css'],
  standalone: true,
  imports: [ IonicModule ]
})
export class AddAddressComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit(): void {
  }

  backClicked() {
    this.location.back();
  }
}
