import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { DataService } from "src/app/data.service";
import { environment } from "src/environments/environment";
import { FilterDataService } from "../user/services/filter-data.service";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { CompanyData } from "src/app/interfaces/ICompany";
import { TranslateNamePipe } from "../Pipes/translate-name.pipe";

@Component({
  selector: 'app-filter-by-brand',
  templateUrl: './filter-by-brand.component.html',
  styleUrls: ['./filter-by-brand.component.css'],
  standalone: true,
  imports: [ CommonModule, IonicModule, NgxSpinnerModule, TranslateNamePipe ]
})

export class FilterByBrandComponent implements OnInit {
  companyCategories: any[] = [];
  selectedCompany: number;
  imgpath: string = environment.IMG_PATH;
  selectedCompanies: any[] = [];


  constructor(private router: Router,
     private dataService: DataService,
     private filterDataService: FilterDataService,
     private spinner: NgxSpinnerService) {
   }

  ngOnInit(): void {
  this.fetchMobileCompanyCategories()
  this.filterDataService.filterChanged.subscribe(selectedCompanies => {
    this.selectedCompanies = selectedCompanies;
  });
  }

  toggleCompanySelection(company: any) {

    const index = this.selectedCompanies.indexOf(company);
    if (index > -1) {
        this.selectedCompanies.splice(index, 1);
    } else {
        this.selectedCompanies.push(company);
    }

    this.filterDataService.setSelectedCompanies(this.selectedCompanies);

  }

  backClicked() {
    this.router.navigate(['/filter']);
  }

  fetchMobileCompanyCategories() {
    this.spinner.show();
    this.dataService.getMobileCompanyCategories().subscribe(
      (data: CompanyData[]) => {
        this.companyCategories = data;

        this.spinner.hide();

      },
      (error) => {
        console.error('Error fetching company categories', error);
      }
    );
  }

}
