import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caseInsensitiveSort',
  standalone: true
})
export class CaseInsensitiveSortPipe implements PipeTransform {
  transform(array: any[], field: string, order: string): any[] {
    if (!array || array.length <= 1) {
      return array;
    }
    const isAscending = order === 'asc';
    
    return array.sort((a: any, b: any) => {
      const aValue = this.getPropertyValue(a, field).toLowerCase();
      const bValue = this.getPropertyValue(b, field).toLowerCase();

      if (aValue < bValue) return isAscending ? -1 : 1;
      if (aValue > bValue) return isAscending ? 1 : -1;

      return 0;
    });
  }

  private getPropertyValue(obj: any, field: string): any {
    return obj[field] || obj.company[field];
  }
}
