import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { Location } from '@angular/common';
import { SearchService } from 'src/app/search.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IonicModule, AlertController } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css'],
  standalone: true,
  imports: [ IonicModule, CommonModule, TranslateModule, RouterModule ]
})
export class FavoritesComponent {
  searchTextt;
  selectedLanguage = "";
  manage: boolean = false;
  favoriteList = [];
  checkedFavorite = [];
  allCartList = [];
  imgpath = environment.IMG_PATH;

  setResult(ev) {
    console.log(`Dismissed with role: ${ev}`);
  }

  constructor(private location: Location, private searchService: SearchService, private router: Router, private translate:TranslateService, private alert: AlertController) {}

  ionViewDidEnter() {
    this.searchService.selectedLanguage.subscribe((val) => {
      this.selectedLanguage = val;
    });
    if(localStorage.getItem('selectedLanguage') == null) {
      this.selectedLanguage = 'al';
    }else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
    }

    let localStorageFav = JSON.parse(localStorage.getItem('fav'));
    localStorageFav.map(fav => {
      fav['quantity'] = 1;
    })
    if (localStorageFav == undefined || localStorageFav == null || localStorageFav.length <= 0) {
      // Swal.fire({
      //   text: this.translate.instant("FAVORITE-PRODUCT-NOT-FOUND"),
      //   icon: 'warning',
      //   confirmButtonText: 'Ok!',
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     this.router.navigate(['/']);
      //   }
      // });
    } else {
      this.favoriteList = localStorageFav;
      this.searchService.searchTextt.subscribe((val) => {
        this.searchTextt = val;
      });
    }
  }

  getCheckedFavorites(event, favorite) {
    if(event.target.checked) {
      this.checkedFavorite.push(favorite);
    } else {
      this.checkedFavorite = this.checkedFavorite.filter((prod) => prod.id !== favorite.id);
    }
  }

  async removeFavorite(favorite) {
    const alert = await this.alert.create({
      header: `${this.translate.instant("DELETE-CONFIRMATION")} ${this.selectedLanguage == 'al' ? favorite?.nameSQ : favorite?.nameMK}`,
      buttons: [
        {
          text: this.translate.instant("NO"),
          role: 'cancel',
          handler: () => {}
        },
        {
          text: this.translate.instant("YES"),
          role: 'confirm',
          handler: () => {
            if(favorite.category){
              this.favoriteList = this.favoriteList.filter((fav) => fav.productId !== favorite.productId);
            } else {
              this.favoriteList = this.favoriteList.filter((fav) => fav.id !== favorite.id);
            }
            localStorage.setItem('fav', JSON.stringify(this.favoriteList));
            // this.dataService.getCartListNumber(this.cartList.length);
          }
        }
      ]
    })

    await alert.present();
  }

  async removeFavorites() {
    const alert = await this.alert.create({
      header: `${this.translate.instant("DELETE-CONFIRMATION-SELECTED")}`,
      buttons: [
        {
          text: this.translate.instant("NO"),
          role: 'cancel',
          handler: () => {}
        },
        {
          text: this.translate.instant("YES"),
          role: 'confirm',
          handler: () => {
            this.checkedFavorite.forEach(card => {
              this.favoriteList = this.favoriteList.filter((prod) => prod.id !== card.id);
            })
            localStorage.setItem('fav', JSON.stringify(this.favoriteList));
            // this.dataService.getCartListNumber(this.cartList.length);
          }
        }
      ]
    })

    await alert.present();
  }

  addFavoriteToCart() {
    const shoppingCart = JSON.parse(localStorage.getItem('cart'));
    this.allCartList = [...shoppingCart, ...this.checkedFavorite];

    const allDistinctCart = this.allCartList.filter((product, index, allCart) =>
      index === allCart.findIndex((productId) => productId.id === product.id))

    localStorage.setItem('cart', JSON.stringify(allDistinctCart));
    this.router.navigate(['/shopping-cart']);
  }

  backClicked() {
    this.location.back();
  }
}
