<div class="header" fxLayout="row" fxLayoutWrap>
  <img class="logoName" [routerLink]="['/home']" src="../../assets/images/logo.png" />
  <div class="row items-on-header">
    <div class="shopping-cart-fixed" *ngIf="itemsNr > 0">
      <div class="shopping-cart">
        <span class="cart-value">{{itemsNr}}</span>
        <a [routerLink]="['/shopping-cart']">
          <i class="bi bi-cart"></i>
        </a>
      </div>
    </div>
    
    <div class="user-login-button-container d-flex">
      <div *ngIf="isLogged">
        <span class="mat-card-text mr-3">{{"HELLO" | translate}} {{displayName}}.</span>
        <button class="user-login-button" color="primary" (click)="signOut()">{{"SIGN-OUT" | translate}}</button>
      </div>
      <button *ngIf="!isLogged" class="user-login-button" color="primary" routerLink="/signin">{{"SIGN-IN" | translate}}</button>
      <button *ngIf="!isLogged" class="user-signup-button" color="primary" routerLink="/signup">{{"SIGN-UP" | translate}}</button>
    </div>
    <!-- <select [ngModel]="selectedLanguage" (change)="changeLanguage($event)" class="language">
      <option value="al">AL</option>
      <option value="mk">MK</option>
    </select> -->
    <div class="language-select">

      <!-- <ion-list>
        <ion-item>
          <ion-select [ngModel]="selectedLanguage" (ionChange)="changeLanguage($event)"  aria-label="language" interface="action-sheet" >
            <ion-select-option value="al">AL</ion-select-option>
            <ion-select-option value="mk">MK</ion-select-option>
          
          </ion-select>
        </ion-item>
      </ion-list> -->

    <!-- <mat-form-field class="language-input">
      <mat-select [ngModel]="selectedLanguage" panelClass="language-panel" (selectionChange)="changeLanguage($event)">
        <mat-option value="al">AL</mat-option>
        <mat-option value="mk">MK</mat-option>
      </mat-select>
    </mat-form-field> -->
  </div>
</div>
</div>
<div class="w-100" fxLayout="row" fxLayoutWrap>
  <div class="menu-button">
    <i class="material-icons icons" routerLink="/menu">east</i>
  </div>
  <app-search class="search-input" *ngIf="showSearch"></app-search>
</div>
