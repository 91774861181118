<app-header></app-header>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p class="spinner-text">Loading...</p>
</ngx-spinner>


<!--slider km-->
<div>
  <div class="slider">
    <ion-range aria-label="Custom range" [min]="options.floor - 1" [max]="options.ceil" [step]="options.step" [pin]="true"
            [(ngModel)]="value" (ionChange)="detectchange($event.detail.value)"><ion-text slot="start">0</ion-text>
            <ion-text slot="end">200</ion-text>
        </ion-range>
    <div class="text">
      <p class="range-line-text">|</p>
      <p class="range-text">{{'RANGE_IN_KM' | translate}}</p>
    </div>
  </div>

    <!--search-->
    <div class="ifilter" fxLayout="row" fxLayoutWrap>
        <!--buttonscroll-->
        <div class="sliders-icon" routerLink="/filter"></div>
        <div class="scroll-container">
            <button
                class="scroll-button"
                [ngClass]="{ active: selectedCategoryid == null }"
                (click)="onSelectedCategory(null)"
            >
                <span class="sp">{{ 'ALL_CATEGORIES' | translate }}</span>
            </button>
            <button
                class="scroll-button"
                [ngClass]="{ active: selectedCategoryid == category.id }"
                *ngFor="let category of categories"
                (click)="onSelectedCategory(category.id)"
            >
                <span class="sp">{{ category | translateName }}</span>
            </button>
        </div>
    </div>

  <!-- Company data -->

    <cdk-virtual-scroll-viewport [itemSize]="1" class="example-viewport">
        <div class="row" style="margin: 8px; margin-bottom: 10px">
            <div class="col-6" style="padding: 8px"
                *ngFor="let company of companies | caseInsensitiveSort: sortingBasedOnLanguage:order">
                <mat-card [routerLink]="['/companydetail', company.company.id]" class="cardd">
                    <div
                        fxLayoutAlign="center center"
                        style="width: auto; height: auto; border-radius: 8px 8px 0px 0px">
                        <img class="company-image" mat-card-image src="{{ imgpath + company.company.image}}"/>
                    </div>
                    <mat-card-title class="TITLE">
                        {{ currentLanguage === 'sq' ? company.company.nameSQ : company.company.nameMK }}
                    </mat-card-title>
                    <mat-card-content>
                        <span class="category-span" style="display: flex; justify-content: space-between">
                            <p>{{ 'CATEGORY' | translate }}</p>
                        </span>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
</div>
