import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css'],
  standalone: true,
  imports: [ CommonModule, TranslateModule, IonicModule ]
})
export class MessagesComponent implements OnInit {
  isMessage: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

}
