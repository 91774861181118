import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() { 
    this.sort = "asc";
  }

  private sortSubject = new Subject<'asc' | 'desc' | null>(); 
  sortChanged$ = this.sortSubject.asObservable();

  sort: 'asc' | 'desc' | null = 'asc';
  min: number;
  max: number;
  selectedCategory: string | null = null;

  setSortValue(data: 'asc' | 'desc' | 'normal' | null) {
    this.sort = data === 'normal' ? null : data;
  }

  triggerSort() {
    this.sortSubject.next(this.sort);
  }

  setSelectedCategory(category: string | null) {
    this.selectedCategory = category;
  }

  getSelectedCategory() {
    return this.selectedCategory;
  }

  setMinValue(data:number){
    this.min = data;
  }

  setMaxValue(data:number){
    this.max = data;
  }

  getValue() {
    return this.sort;
  }

  getSortValue() {
    return this.sort;
  }

  getMinValue(){
    return this.min;
  }

  getMaxValue(){
    return this.max;
  }

  resetValues(){
    this.sort = "asc";
    this.sortSubject.next(this.sort);
    this.min = undefined;
    this.max = undefined;
    this.selectedCategory = null;
  }

}
