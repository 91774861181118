<main>
  <div class="fullpage">
    <ion-router-outlet></ion-router-outlet>
  </div>
  <ion-tabs>
    <ion-tab-bar *ngIf="!showTab" slot="bottom">
      <ion-tab-button tab="products" href="/products" (click)="selectTab($event)">
        <ion-icon name="home"></ion-icon>
        <ion-label>Home</ion-label>
        <ion-line ></ion-line>
        <!-- <hr class="custom-hr"> -->
      </ion-tab-button>
  
      <ion-tab-button tab="shopping-cart" href="/shopping-cart" (click)="selectTab($event)">
        <ion-icon name="cart"></ion-icon>
        <ion-label>Bag</ion-label>
        <ion-line></ion-line>
        <!-- <hr class="custom-hr"> -->
      </ion-tab-button>
  
      <ion-tab-button tab="favorite" href="/favorite">
        <ion-icon name="heart"></ion-icon>
        <ion-label>Favorites</ion-label>
        <!-- <hr class="custom-hr"> -->
      </ion-tab-button>
  
      <ion-tab-button tab="profile" href="/profile">
        <ion-icon name="person"></ion-icon>
        <ion-label>Profile</ion-label>
        <!-- <hr class="custom-hr"> -->
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</main>