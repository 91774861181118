import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { Location, TitleCasePipe } from '@angular/common';
import { DataService } from 'src/app/data.service';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css'],
  standalone: true,
  imports: [ CommonModule, IonicModule, TitleCasePipe ]
})
export class MyOrdersComponent implements OnInit {
  shippedList = [];
  deliveredList = [];

  cartList = [];

  constructor(private router: Router, private location: Location, public dataservice: DataService) { }

  ngOnInit(): void {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    this.dataservice.getMyOrders(userId, token).subscribe((orders: any) => {
      this.shippedList = orders?.filter((order: any) => {
        return order.orderStatus == 'Processing';
      })

      this.deliveredList = orders?.filter((order: any) => {
        return order.orderStatus == 'Success';
      })
    })
    
    const shoppingCart = JSON.parse(localStorage.getItem('cart'));
    this.cartList = shoppingCart;
  }

  goToDetails(cart) {
    this.router.navigate(['/my-orders-details'], cart);
  }

  backClicked() {
    this.location.back();
  }

}
