<ion-content>
    <ion-grid class="container">
        <ion-row>
          <ion-col>
            <div class="ion-text-center">
                <ion-img src="assets/images/new/maps-marker.png" alt="Discover"></ion-img>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
              <div class="ion-text-center mx-5">
                <h3 class="d-flex justify-content-center font-27">{{"You dont have any saved adresses." | translate}}</h3>
                <a [routerLink]="['/add-address']" class="font-16-weight-700-dark-underline">Add Address</a>
              </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>