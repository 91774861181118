import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { UserService } from '../../user/services/user.service';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css'],
  standalone: true,
  imports: [ CommonModule, RouterModule, IonicModule, TranslateModule, MatFormFieldModule, ReactiveFormsModule ]
})
export class PasswordChangeComponent implements OnInit {
  currentPassword: boolean = false;
  viewPassword: boolean = false;
  viewConfirmPassword: boolean = false;

  isFormSubmitted: boolean = false;
  changePasswordForm = new UntypedFormGroup({
    currentPassword: new UntypedFormControl('', Validators.required),
    newPassword: new UntypedFormControl('', Validators.required),
    confirmNewPassword: new UntypedFormControl('', Validators.required)
  });

  constructor(private location: Location, private userService: UserService) { }

  ngOnInit(): void {
  }

  changePassword(fields) {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const data = {...fields, token, userId};

    console.log('data10', fields);
    if(fields.currentPassword && fields.newPassword && fields.confirmNewPassword) {
      this.userService.changepassword(data).subscribe((res) => {
      });
      this.changePasswordForm.reset();
    }
  }

  currentPasswordShowHide(){
    this.currentPassword = !this.currentPassword; 
  }

  passwordShowHide(){
    this.viewPassword = !this.viewPassword; 
  }

  confirmPasswordShowHide(){
    this.viewConfirmPassword = !this.viewConfirmPassword;
  }

  backClicked() {
    this.location.back();
  }

}
