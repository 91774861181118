<!-- <app-header></app-header> -->
<ion-content>
  <div style="display: flex;margin-top: 15px;">
    <a [routerLink]="['/']">
      <ion-icon class="back-icon" size="large" name="arrow-back"></ion-icon>
    </a>
    <h4 class="font-16-w-600">All categories</h4>
  </div>
  <ion-segment color="warning" value="products" (ionChange)="onSegmentChange($event)">
    <ion-segment-button value="products">
      <ion-label style="color: #444D5E;">Products</ion-label>
    </ion-segment-button>
    <ion-segment-button value="business">
      <ion-label style="color: #B1B3B7;">Business</ion-label>
    </ion-segment-button>
</ion-segment>
<cdk-virtual-scroll-viewport [itemSize]="1" class="example-viewport">
  <div class="fullpage">
    <div class="row">
      <div
        class="col-4 p-8"
        *ngFor="let category of companyCategories"
      >
      <ion-segment color="warning" value="{{ currentLanguage === 'al' ? category.nameSQ    : category.nameMK }}" (ionChange)="onSegmentChange($event)">
        <ion-segment-button value="{{ currentLanguage === 'al' ? category.nameSQ : category.nameMK }}">
          <img mat-card-image class="category-image" src="{{ imgpath + category.image }}"/>
          <ion-label style="color: #444D5E; font-size: 15px;">{{ currentLanguage === 'al' ? category.nameSQ : category.nameMK }}</ion-label>
        </ion-segment-button>
      </ion-segment>
      
        <!-- <mat-card [routerLink]="['/companies-list', category.id]" class="category-card">
          <div
            fxLayoutAlign="center center" class="category-card-wrapper">
            <img
              mat-card-image class="category-image" src="{{ imgpath + category.image }}"/>
          </div>
          <mat-card-title class="mat-card-title">{{ currentLanguage === 'al' ? category.nameSQ : category.nameMK }}</mat-card-title>
        </mat-card> -->
      </div>
    </div>
  </div>
</cdk-virtual-scroll-viewport>
</ion-content>
