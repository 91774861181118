<ion-content>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
        <p class="spinner-text">Loading...</p>
    </ngx-spinner>
    <div class="flex-between-center">
        <div class="flex-no-end">
            <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
            <h6 class="nav-back">Back</h6>
        </div>
    </div>

    <ion-grid>
        
        <ion-row>
            <ng-container *ngIf="companyCategories.length > 0; else noCompanies">
                <ion-col size="4" *ngFor="let item of companyCategories" class="company-col"
                [class.selected]="selectedCompanies.includes(item)">
                    <div class="company-container" (click)="toggleCompanySelection(item)">
                        <div class="logo-circle">
                            <img [src]="imgpath + item.company.image" alt="{{ item.company.nameSQ }}" class="company-logo"/>
                        </div>
                        <div class="company-details">
                            <p class="company-name">{{ item.company | translateName }}</p>
                            <p class="company-category">{{ item.company.category.nameSQ }}</p>
                        </div>
                    </div>
                </ion-col>
            </ng-container>
        </ion-row>
        <ng-template #noCompanies>
            <ion-col>
                <div class="check-container">
                    <label class="check-label">No companies available</label>
                </div>
            </ion-col>
        </ng-template>
    </ion-grid>
</ion-content>


<!-- <ion-content>
    <div class="flex-between-center">
        <div class="flex-no-end">
            <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
            <h6 class="font-16-550">Back</h6>
        </div>
    </div>
    <div class="bg-white">
        <ion-row class="row px-5">
            <ion-col class="col">
                <ion-radio-group value="selectedCompany">
                    <ng-container *ngIf="companyCategories.length > 0; else noCompanies">
                        <div *ngFor="let company of companyCategories" class="check-container">
                            <div class="img-text">
                                <div class="img-container">
                                    <img mat-card-image class="product-image" src="{{ imgpath + company.image  }}"/>

                                </div>
                                <label class="check-label">{{ company | translateName }}</label>
                            </div>
                            <ion-radio [value]="company.id"></ion-radio>
                        </div>
                    </ng-container>
                    <ng-template #noCompanies>
                        <div class="check-container">
                            <label class="check-label">No companies available</label>
                        </div>
                    </ng-template>
                </ion-radio-group>
            </ion-col>
        </ion-row>
    </div>
    //A5D
</ion-content> -->
    <!-- <ion-row class="row mt-5 px-4">
        <ion-col class="col-4 d-flex-center" *ngFor="let company of companies">
            <div class="card" [ngClass]="{'card-selected': selectedID === company.company.id}" (click)="selectCompany(company.company)">
              <img mat-card-image class="card-img" src="{{ imagepath + company.company.image }}" />
            </div>
            <ion-label class="font-16-w-550">{{ currentLanguage === 'al' ? (company.company.nameSQ | titlecase) : (company.company.nameMK | titlecase) }}</ion-label>
        </ion-col>
    </ion-row>
</ion-content> -->
