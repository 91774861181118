import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat } from 'ol/proj';
import OSM from 'ol/source/OSM';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.css'],
  standalone: true,
  imports: [ IonicModule, CommonModule, FormsModule, TranslateModule ]
})
export class AddAddressComponent implements OnInit {
  address: string = '';
  details: string = '';
  map: Map;
  clientConomeId: number;
  successMessage: string = ''; 

  constructor(private location: Location, private cdr: ChangeDetectorRef, private http: HttpClient) { }

  ngOnInit(): void {
  this.clientConomeId = parseInt(localStorage.getItem('userId'), 10);

  this.map = new Map({
    target: 'map',
    layers: [
      new TileLayer({
        source: new OSM(),
      }),
    ],
    view: new View({
      center: fromLonLat([20.9715, 42.0069]),
      zoom: 12,
    }),
  });  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.map.updateSize();
      this.cdr.detectChanges();
    }, 1000);
  }


  saveAddress() {
    const addressData = {
      Address1: this.address,
      Address2: this.details
    };
  
    const clientConomeId = this.clientConomeId;
  
    this.http.post(`${environment.API_URL}/api/AddressApi/Create?clientConomeId=${clientConomeId}`, addressData)
      .subscribe(response => {
        this.successMessage = 'Address saved successfully!';

        this.address = '';
        this.details = '';

        setTimeout(() => {
          this.successMessage = '';
        }, 3000);
      }, error => {
        console.error('Error saving address', error);
      });
  }
  

  backClicked() {
    this.location.back();
  }
}
