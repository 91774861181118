import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { DataService } from 'src/app/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filter-by-brand',
  templateUrl: './filter-by-brand.component.html',
  styleUrls: ['./filter-by-brand.component.css'],
  standalone: true,
  imports: [ CommonModule, IonicModule ]
})
export class FilterByBrandComponent implements OnInit {
  imgpath: string = environment.IMG_PATH;
  imagepath: string = environment.API_URL + '/';
  companies = [];
  selectedID = 0;

  constructor(private router: Router, private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getMobileCompanyCategories().subscribe((formated: any[]) => {
      this.companies = formated;
    })
  }

  selectCompany(company) {
    if(this.selectedID == company.id) {
      this.selectedID = 0;
      localStorage.removeItem('filterBrand');
    } else {
      this.selectedID = company.id;
      localStorage.setItem('filterBrand', JSON.stringify(this.selectedID));
    }
  }

  backClicked() {
    this.router.navigate(['/filter']);
  }

}
