import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SearchService } from '../../search.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  standalone: true,
  imports: [ TranslateModule, FormsModule, IonicModule ]
})
export class SearchComponent implements OnInit {
  searchText;
  constructor(private searchService: SearchService) {}

  ngOnInit(): void {}

  changeFilter(event) {
    this.searchService.searchTextt.next(event.target.value);
  }

  cancelPresed() {
    this.searchText = '';
    this.searchService.searchTextt.next('');
  }
}
