<!--Header-->
<!-- <div class="header" fxLayout="row" fxLayoutWrap>
  <img class="logoName" [routerLink]="['/']" src="../../assets/images/logo.png">
</div> -->
<!-- <div fxLayout="row" fxLayoutWrap>
  <i class="material-icons back-icon" (click)="backClicked()">west</i>
</div> -->

  <ion-content>
    <div class="row">
      <div class="col-9 mt-3">
        <a [routerLink]="['/products']">
          <i class="bi bi-chevron-left large-icon"></i>
        </a>
      </div>
      <div class="col-auto mt-3">
        <div class="shopping-cart" *ngIf="cartNr > 0">
          <span class="cart-value">{{cartNr}}</span>
          <a [routerLink]="['/shopping-cart']">
            <i class="bi bi-cart"></i>
          </a>
        </div>
      </div>
    </div>

    <ion-row>
      <swiper-container #swiperCon class="swiper" (swiperslidechange)="onSlideChanged()">
        <swiper-slide size="12" *ngFor="let product of allproducts">
          <div class="row">
            <div class="col">
              <div class="img-bg">
                <img mat-card-image class="image" src="{{ imgpath + product.productImage }}" />
              </div>
              <div class="row mt-5">
                <div class="col-9">
                  <p class="text">{{ product | translateName:'company' }}</p>
                  <p class="product-name">{{ product | translateName }}</p>
                  <p class="price-col-20">{{ product.productDetails[0].productDetailSellingPriceWithVat }}{{'CURRENCY' | translate | uppercase}}</p>
                </div>
                <div class="col-3">
                  <input id="heart" type="checkbox" [(ngModel)]="isFavorite" (change)="AddToFav()" />
                  <label for="heart" class="heart-label"><i class="fa fa-heart heart-icon" [class.col-yellow]="isFavorite" aria-hidden="true"></i></label>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h3 class="info1">{{'About the product' | translate}}</h3>
                  <p class="info2">{{readMore && product?.notes?.length>100 ? (product?.notes | slice: 0:100)+'...' : product?.notes}}</p>
                  <p *ngIf="product?.notes?.length > 100" class="flex-end-font-15" (click)="readMore = !readMore">{{readMore ? 'Read More' : 'Read Less'}}</p>
                </div>
              </div>
              <ion-grid>
                <ion-row>
                  <ion-col size="4" class="flex-row-center">
                    <div class="radius-50 light-grey-col" (click)="removeQuantity(product)"><i class="bi bi-dash-lg font-15"></i></div>
                    <span class="font-20 mx-3">{{itemsNr}}</span>
                    <div class="radius-50 grey-col" (click)="addQuantity(product)"><i class="bi bi-plus-lg font-15"></i></div>
                  </ion-col>
                  <ion-col size="8">
                    <div class="d-flex">
                      <button class="btn-buy">
                        <ion-img class="img-size" src="assets/images/new/icons/buy.svg" alt="cart"></ion-img>
                        <span class="btn-text">Buy</span>
                      </button>
                      <button class="btn-cart" (click)="addToCart($event, product, i)">
                        <span class="btn-text">Add To Cart</span>
                      </button>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
  </ion-row>

<div class="delivery-container">
  <h6 class="delivery-options">Delivery Options</h6>
  <i class="bi bi-arrow-right large-icon-1"></i>
</div>

<h1 class="related-products-title">{{"Recommended" }}</h1>
<div class="related-products">
  <div *ngIf="relatedProducts == undefined || relatedProducts?.length == 0">
    <p class="related-products-error">{{"NO-RELATED-PRODUCTS-FOUND" | translate}}</p>
  </div>
  <div class="row">
    <div class="col-6 p-8" *ngFor="let p of relatedProducts">
      <mat-card [routerLink]="['/productdetail', p.product.productId, 0, 0]" class="cardd">
        <div fxLayoutAlign="center center" class="product-card-wrapper">
          <img mat-card-image class="product-image" src="{{ imgpath + p?.product.image }}" />
        </div>
        <mat-card-title class="mat-card-title">{{
          p?.product | translateName }}</mat-card-title>
        <mat-card-content>
          <span class="product-card-content">
            <p class="card-content-category">{{'CATEGORY' | translate}}</p>
            <p class="card-content-price">
              {{ p?.product?.sellingPriceWithVat }}{{'CURRENCY' | translate}}
            </p>
          </span>
        </mat-card-content>
      </mat-card>

    </div>
  </div>
</div>
</ion-content>