import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import {Storage} from "@ionic/storage";
import { IProduct, IProductWithLatAndLon } from './interfaces/IProduct';
import { environment } from 'src/environments/environment';
import { CategoryWithImage, IProductCategories } from './interfaces/IProductCategories';
import { CompanyById, CompanyData, CompanyWithLocation } from './interfaces/ICompany';
import { RouteEnum } from './constants/route.enum';
import { EndpointsEnum } from './constants/endpoints.enum';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private _shopppingCartSize = new BehaviorSubject<number>(0);
  private _cashCard = new BehaviorSubject<string>('card');

  shoppingCartSize$ = this._shopppingCartSize.asObservable();
  cashCard$ = this._cashCard.asObservable();

  constructor(private http: HttpClient, private storage: Storage) {}

  getCrmCompaniesByUserAddress(): Observable<CompanyWithLocation[]> {
    return this.http.get<CompanyWithLocation[]>(`${environment.API_URL}/api/${RouteEnum.companyapi}/${EndpointsEnum.index}`);
  }


  getcompaniesbyID(id): Observable<CompanyById[]> {
    return this.http.get<CompanyById[]>(
      `${environment.API_URL}/api/${RouteEnum.companyapi}/${EndpointsEnum.compdetails}`,{ params: { id } }
    );
  }

  getAllProducts(): Observable<IProductWithLatAndLon[]> {
    return this.http.get<IProductWithLatAndLon[]>(
      `${environment.API_URL}/api/${RouteEnum.companyapi}/${EndpointsEnum.allproducts}`
    );
  }

  getAllProductsFiltered(
    filterRequest: { companyIds?: number[]; searchText?: string; categoryName?: string }, 
    priceSort?: string
  ): Observable<IProductWithLatAndLon[]> {
    const modifiedFilterRequest = {
      ...filterRequest,
      sortOrder: priceSort ? priceSort.toUpperCase() : ""
    };
  
    return this.http.post<IProductWithLatAndLon[]>(
      `${environment.API_URL}/api/${RouteEnum.companyapi}/${EndpointsEnum.allProductsFiltered}`,
      modifiedFilterRequest
    );
  }

  getProductsbyID(id): Observable<IProduct[]> {
    return this.http.get<IProduct[]>(
      `${environment.API_URL}/api/${RouteEnum.companyapi}/${EndpointsEnum.details}`,{ params: { id } }
    );
  }

  getProducts(): Observable<IProduct[]> {
    return this.http.get<IProduct[]>(
      `${environment.API_URL}/api/${RouteEnum.companyapi}/${EndpointsEnum.product}`
    );
  }

  getCategories(): Observable<CategoryWithImage[]> {
    return this.http.get<CategoryWithImage[]>(`${environment.API_URL}/api/${RouteEnum.companyapi}/${EndpointsEnum.AllMobileCategories}`);
  }

  getProductCategories(): Observable<IProductCategories[]> {
    return this.http.get<IProductCategories[]>(`${environment.API_URL}/api/${RouteEnum.companyapi}/${EndpointsEnum.AllProductMobileCategories}`);
  }

  getMobileCompanyCategories(): Observable<CompanyData[]>{
    return this.http.get<CompanyData[]>(`${environment.API_URL}/api/${RouteEnum.companyapi}/${EndpointsEnum.AllCompanyMobileCategories}`);
  }

  getCartListNumber(size) {
    this._shopppingCartSize.next(size);
  }

  async setItem(key: string, value: any): Promise<void> {
    return this.storage.set(key, value);
}

  async getItem(key: string): Promise<any> {
    return await this.storage.get(key);
  }

  createOrder(order: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<any>(`${environment.API_URL}/api/OrdersApi/CreateOrder`, order, { headers })
  }

  checkOut(amount: number, orderId: number, baseUrl: string) {
    let amountStr = amount.toString();
    let orderIdStr = orderId.toString();
    return this.http.get(`${environment.API_URL}/api/OrdersApi/Checkout`, { params: { amount: amountStr, orderId: orderIdStr, baseUrlUI: baseUrl } });
  }

  getMyOrders(clientId, token): any {
    return this.http.get<any[]>(`${environment.API_URL}/api/${RouteEnum.ordersapi}/${EndpointsEnum.getClientOrders}`,{ params: { clientId, token } }
    );
  }

  getShippingForProduct(companyId: number, productDetailIds: number[], orderTotal: number):Observable<any>{
    const url = `${environment.API_URL}/api/EcommerceApi/ShippingPrice?companyId=${companyId}&orderTotal=${orderTotal}`;
    const body = { Ids: productDetailIds }
    return this.http.post(url, body);
  }
  
  paymentMethod(method: string) {
    this._cashCard.next(method);
  }
}
