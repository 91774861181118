<!-- <app-header></app-header> -->
<!-- <div class="row row-card">
  <div class="col-6" style="padding: 8px" *ngFor="let favorites of data | filter: searchTextt">
    <mat-card [routerLink]="['/productdetail', favorites.id]"  class="cardd">
      <div class="image-container" fxLayoutAlign="center center">
        <img mat-card-image class="image" src="{{ imgpath + favorites.image }}"/>
      </div>
      <mat-card-title class="product-title">
      {{currentLanguage === "al" ? favorites.nameSQ : favorites.nameMK}}</mat-card-title>
      <mat-card-content>
        <span class="items">
        <p class="category">{{'CATEGORY' | translate}}</p>
        <p class="price">{{ favorites.sellingPriceWithVat }}{{'CURRENCY' | translate}}</p>
        </span>
      </mat-card-content>
    </mat-card>
  </div>
</div> -->
<!--Produktet-->
<ion-content>
  <div *ngIf="favoriteList.length > 0">
    <div class="flex-between-center">
      <div class="flex-no-end">
          <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
          <h6 class="font-16-550">Favorites</h6>
      </div>
      <h6 *ngIf="!manage" class="title-blue" (click)="manage = true">Manage Product(s)</h6>
      <h6 *ngIf="manage" class="title-blue" (click)="manage = false">Manage Product(s)</h6>
    </div>
  
  <ion-card>
      <ion-card-content>
        <ion-list *ngIf="!manage">
          <ion-grid *ngFor="let favorite of favoriteList; let i = index">
              <ion-row>
                <ion-col size="4">
                  <div class="img-bg">
                  </div>
                </ion-col>
                <ion-col size="7">
                  <div class="flex-col-between-h-100">
                      <div>
                          <p class="font-13">{{selectedLanguage == 'al' ? favorite.nameSQ : favorite.nameMK}}</p>
                          <!-- <p class="font-9">{{selectedLanguage == 'al' ? cart.nameSQ : cart.nameMK}}</p> -->
                      </div>
                      <p class="font-15">{{favorite.sellingPriceWithVat % 1 != 0 ? favorite.sellingPriceWithVat.toFixed(1) : favorite.sellingPriceWithVat}}{{'CURRENCY' | translate | uppercase}}</p>
                  </div>
                </ion-col>
                <ion-col size="1">
                  <span class="d-block my-auto"><img src="../../assets/images/new/icons/bin.svg" (click)="removeFavorite(favorite)"></span>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12" class="flex-end">
                  <p class="font-11">Delivery: 130.00 DEN</p>
                </ion-col>
              </ion-row>
          </ion-grid>
        </ion-list>
  
        <ion-list *ngIf="manage">
          <ion-grid *ngFor="let favorite of favoriteList; let i = index">
              <ion-row>
                <ion-col size="5">
                  <div class="flex-no-center">
                      <ion-checkbox (ionChange)="getCheckedFavorites($event, favorite)"></ion-checkbox>
                      <div class="img-bg"></div>
                  </div>
                </ion-col>
                <ion-col>
                  <div class="flex-col-between-h-100-checkbox">
                      <div><p class="font-13">{{selectedLanguage == 'al' ? favorite.nameSQ : favorite.nameMK}}</p></div>
                      <p class="font-15">{{favorite.sellingPriceWithVat % 1 != 0 ? favorite.sellingPriceWithVat.toFixed(1) : favorite.sellingPriceWithVat}}{{'CURRENCY' | translate | uppercase}}</p>
                  </div>
                </ion-col>
                <ion-col size="1">
                  <span class="d-block my-auto"><img src="../../assets/images/new/icons/bin.svg" (click)="removeFavorite(favorite)"></span>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="12" class="flex-end">
                  <p class="font-11">Delivery: 130.00 DEN</p>
                </ion-col>
              </ion-row>
          </ion-grid>
        </ion-list>
      </ion-card-content>
    </ion-card>
    <ion-row *ngIf="manage" class="mx-4">
      <ion-col>
        <ion-button size="large" expand="full" shape="round" color="dark" fill="outline" (click)="removeFavorites()">
          <span class="btn-text">Delete</span>
        </ion-button>  
      </ion-col>
      <ion-col>
        <ion-button size="large" expand="full" shape="round" color="warning" (click)="addFavoriteToCart()">
          <span class="btn-text">Add to Cart</span>
        </ion-button>  
      </ion-col>
    </ion-row>
  </div>

  <ion-grid *ngIf="favoriteList.length == 0" class="container">
    <ion-row>
      <ion-col>
        <div class="ion-text-center">
            <ion-img class="img-size" src="assets/images/new/no-favorite.png" alt="Discover"></ion-img>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
          <div class="ion-text-center mx-5">
            <h3 class="d-flex justify-content-center font-27">{{"You dont have any Favorites." | translate}}</h3>
            <a [routerLink]="['/products']" class="font-16-weight-700-dark-underline">Continue Shopping</a>
          </div>
        </ion-col>
    </ion-row>
  </ion-grid>  
</ion-content>

<ion-alert></ion-alert>