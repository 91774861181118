<ion-content>
  <div class="flex-between-center" *ngIf="addresses.length > 0">
    <div class="flex-no-end">
        <a><i class="bi bi-chevron-left large-icon px-3" (click)="backClicked()"></i></a>
        <h6>Back</h6>
    </div>
    <div class="flex-no-end" style="margin-left: auto;">
      <a [routerLink]="['/add-address']" class="title-blue">Add New Address</a>
    </div>
</div>
<ion-grid class="container" [ngClass]="{'justify-content-flex-start': addresses.length > 0, 'justify-content-center': addresses.length === 0}">
    <ion-row *ngIf="addresses.length === 0; else showAddresses">
      <ion-col>
        <div class="ion-text-center img-container">
          <ion-img class="img-place" src="assets/images/you dont have saved loc@2x.png" alt="Discover"></ion-img>
        </div>
        <div class="ion-text-center mx-5">
          <h3 class="d-flex justify-content-center font-27">{{ "ADDRESS-PAGE.NO-SAVED-ADDRESS" | translate }}</h3>
          <a [routerLink]="['/add-address']" class="font-16-weight-700-dark-underline">{{ "ADDRESS-PAGE.ADD-ADDRESS" | translate }}</a>
        </div>
      </ion-col>
    </ion-row>

    <ng-template #showAddresses>
      <ion-row *ngFor="let address of addresses" class="full-width">
        <ion-col>
          <div class="address-item" style="display: flex; justify-content: space-between; align-items: center; padding: 10px; border-bottom: 1px solid #E6E6E6;">
            <div style="display: flex; align-items: center;">
              <ion-img src="assets/images/add-address.svg" alt="Add Address" style="width: 24px; height: 24px; margin-right: 10px;"></ion-img>
              <div>
                <h5 class="font-16" >{{ address.Address1 }}</h5>
                <p class="font-15">{{ address.Address2 }}</p>
              </div>
            </div>
            <!-- <ion-icon name="chevron-forward" slot="end" style="font-size: 24px; color: #444D5E;"></ion-icon> -->
          </div>
        </ion-col>
      </ion-row>
    </ng-template>
  </ion-grid>
</ion-content>
