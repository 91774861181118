

<!-- <app-header [showSearch]="false"></app-header> -->


<ion-content>
<div class="user-page">
  <form class="user-form" [formGroup]="changePassword">
    <a [routerLink]="['/signin']">
      <ion-icon size="large" name="arrow-back"></ion-icon>
    </a>
    <h1 class="font-25-w-700 mt-80">Enter New Password</h1>
    <p class="change-password-text">Your new password must be different from previous passwords.</p> 
    <mat-label>{{"NEW-PASSWORD" | translate}}</mat-label>  
  <mat-form-field appearance="outline" class="user-form-field">
    <input matInput id="password" [type]="viewPassword ? 'text' : 'password'" placeholder="{{'NEW-PASSWORD' | translate}}" value="" formControlName="NewPassword">
    <!-- <img class="eye-icon" src="../../assets/images/eye.ico" height="25" *ngIf="!viewPassword"
        (click)="passwordShowHide()" />
      <img class="eye-icon" src="../../assets/images/eye-off.ico" height="25" *ngIf="viewPassword"
        (click)="passwordShowHide()"> -->
   
  </mat-form-field>

  <mat-label>{{"CONFIRM-NEW-PASSWORD" | translate}}</mat-label>
  <mat-form-field class="user-form-field" appearance="outline">
    <input type="password" matInput [type]="viewConfirmPassword ? 'text' : 'password'" placeholder="{{'CONFIRM-NEW-PASSWORD' | translate}}" value="" formControlName="ConfirmNewPassword">
    <!-- <img class="eye-icon" src="../../assets/images/eye.ico" height="25" *ngIf="!viewConfirmPassword"
        (click)="confirmPasswordShowHide()" />
      <img class="eye-icon" src="../../assets/images/eye-off.ico" height="25" *ngIf="viewConfirmPassword"
        (click)="confirmPasswordShowHide()" /> -->
  </mat-form-field>
  
</form>
<br>

<button class="change-password-button" appearance="outline" (click)="changepassword(changePassword.value)">{{"SUBMIT" | translate}}</button>
  <br>

<!-- <br>

<a [routerLink]="['/signup']" >{{"DONT-HAVE-AN-ACCOUNT-YET" | translate}}</a> -->

</div>
</ion-content>