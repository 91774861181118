<ion-toolbar>
    <ion-buttons slot="end">
      <ion-button class="close-position" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-content>
    <ion-row class="row px-5">
        <ion-col class="col">
            <ion-radio-group value="card" (ionChange)="cashOrCard($event)">
                <div class="check-container">
                  <div class="d-flex">
                    <ion-img class="img-size" src="assets/images/new/icons/cash.svg" alt="cash"></ion-img>
                    <label class="check-label">Cash on Delivery</label>
                  </div>
                  <ion-radio value="cash"></ion-radio>
                </div>
                <div class="check-container">
                  <div class="d-flex">
                    <ion-img class="img-size" src="assets/images/new/icons/delivery.svg" alt="cash"></ion-img>
                    <ion-img class="img-size card-check" src="assets/images/new/icons/card-check.svg" alt="cash"></ion-img>
                    <label class="check-label-card">Debit / Credit Card</label>
                  </div>
                  <ion-radio value="card"></ion-radio>
                </div>
              </ion-radio-group>
        </ion-col>
    </ion-row>
    <ion-row class="m-5">
      <ion-col size="12">
        <ion-button size="large" expand="full" shape="round" color="warning" (click)="paymentMethod()">
          <span class="btn-text">Confirm</span>
        </ion-button>  
      </ion-col>
    </ion-row>
</ion-content>
