import { Pipe, PipeTransform } from '@angular/core';
import { SearchService } from 'src/app/search.service';

@Pipe({
  name: 'translateName',
  standalone: true
})
export class TranslateNamePipe implements PipeTransform {

  constructor() {
    this.getSelectedLanguage()
  }

  selectedLanguage: string;

  getSelectedLanguage() {
    this.selectedLanguage = localStorage.getItem('selectedLanguage')
  }

  transform(value: {
    [key: string]: any,
    nameSQ: string, 
    nameMK: string, 
    citySQ: string, 
    cityMK: string,
    companyNameSQ?: string,
    companyNameMK?: string},
     arg: string): string {
      
    let name = '';
    if (this.selectedLanguage === 'al') {
      name = arg === 'city' ? value.citySQ : (arg === 'company' ? value.companyNameSQ : value.nameSQ);
    } else if (this.selectedLanguage === 'mk') {
      name = arg === 'city' ? value.cityMK : (arg === 'company' ? value.companyNameMK : value.nameMK);
    } else {
      name = arg === 'city' ? value.citySQ : (arg === 'company' ? value.companyNameSQ : value.nameSQ);
    }

    return name;
  }
  
}
