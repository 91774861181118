import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor() { 
    this.sort = "asc";
  }

  sort: 'asc' | 'desc';
  min: number;
  max: number;
  typeOfCategory: string;

  setValue(data: 'asc' | 'desc') {
    this.sort = data;
  }

  setMinValue(data:number){
    this.min = data;
  }

  setMaxValue(data:number){
    this.max = data;
  }

  getValue() {
    return this.sort;
  }

  getMinValue(){
    return this.min;
  }

  getMaxValue(){
    return this.max;
  }

  resetValues(){
    this.sort = "asc";
    this.min = undefined;
    this.max = undefined;
  }

}
