import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { FilterService } from 'src/app/filter.service';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-filter-by-price',
  templateUrl: './filter-by-price.component.html',
  styleUrls: ['./filter-by-price.component.css'],
  standalone: true,
  imports: [ IonicModule ]
})
export class FilterByPriceComponent implements OnInit {

  filterPrice: string = 'normal';

  constructor(
    private router: Router,
    private filterService: FilterService,
  ) { }


  ngOnInit(): void {
    const storedPrice = localStorage.getItem('filterPrice');
    if (storedPrice) {
      this.filterPrice = storedPrice;
    }
  }
  
  lowestOrHighest(event) {
    this.filterPrice = event.detail.value;
    localStorage.setItem('filterPrice', this.filterPrice);

    if (this.filterPrice === 'asc') {
      this.filterService.setSortValue('asc');
    } else if (this.filterPrice === 'desc') {
      this.filterService.setSortValue('desc');
    } else {
      this.filterService.setSortValue(null);
    }
  }

  backClicked() {
    this.router.navigate(['/filter']);
  }
}
