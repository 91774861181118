<div class="flex-w-90 p-1 searchi rounded rounded-pill mb-4">
  <div class="input-icons inner-addon left-addon" style="margin-bottom: 0px">
    <!-- <i class="fa fa-search btn btn-link"></i> -->
    <div style="display: flex;">
      <ion-icon name="search-outline" class="search-size"></ion-icon>
      <input
        type="text"
        name="search"
        [(ngModel)]="searchText"
        (keyup)="changeFilter($event)"
        autocomplete="off"
        placeholder="{{'SEARCH_PLACEHOLDER' | translate}}"
        aria-describedby="button-addon2"
        class="form-control input-field"
      />
    </div>
  </div>
  <p class="cancel" (click)="cancelPresed()">Cancel</p>
</div>
